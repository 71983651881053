import React, {useContext, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import CustomModal from "../../custom/CustomModal";
import {CircularProgress} from "@mui/material";

const TrainingEdit = () => {

    const { providerId } = useParams();
    const [provider, setProvider] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: registerContact, handleSubmit: handleSubmitContact, formState: { errors: contactErrors } } = useForm();
    const [modalShow, setModalShow] = useState(false);
    const [newContact, setNewContact] = useState({ type: 'provider', firstName: '', lastName: '', email: '', phone: '' });
    const [editedContact, setEditedContact] = useState({ type: 'provider', firstName: '', lastName: '', email: '', phone: '' });
    const [editedContactIndex, setEditedContactIndex] = useState(0);
    const [editModalShow, setEditModalShow] = useState(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'providers/' + providerId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    if(response.data.status === "archived") {
                        navigate(-1);
                    } else {
                        setProvider(response.data);
                        setIsLoaded(true);
                    }
                } else {
                    toast.error('Utilisateur inconnu', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, providerId]);

    React.useEffect(() => {
        console.log(provider);
    }, [provider]);

    const handleEditModalShow = (isShow) => {
        if(!isShow) {
            setEditedContact({ type: 'provider', firstName: '', lastName: '', email: '', phone: '' });
            setEditModalShow(false);
        }
    }

    const handleShowUpdateContact = (index) => {
        setEditedContact(provider.contacts[index]);
        setEditedContactIndex(index);
        setEditModalShow(true);
    }

    const handleEditContact = () => {
        setProvider((provider) => {
            let tab = [...provider.contacts];
            tab[editedContactIndex] = editedContact;
            return {...provider, contacts: tab }
        });
        setEditModalShow(false);
    }

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'providers/' + providerId, { provider }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le fournisseur a bien été modifié", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setButtonLoading(false);
            });
    }

    const handleAddPhone = () => {
        setProvider((provider) => {
            let tab = provider.phones;
            tab = [...tab, { phone: ''}];
            return {...provider, phones: tab }
        })
    }

    const handleDeletePhone = (index) => {
        setProvider((provider) => {
            const tab = [...provider.phones];
            tab.splice(index, 1);
            return {...provider, phones: tab}
        })
    }

    const handleAddAddress = () => {
        setProvider((provider) => {
            let tab = provider.addresses;
            tab = [...tab, { address: '', type: 'provider'}];
            return {...provider, addresses: tab }
        })
    }

    const handleDeleteAddress = (index) => {
        setProvider((provider) => {
            const tab = [...provider.addresses];
            tab.splice(index, 1);
            return {...provider, addresses: tab}
        })
    }

    const handleDeleteContact = (index) => {
        setProvider((provider) => {
            const tab = [...provider.contacts];
            tab.splice(index, 1);
            return {...provider, contacts: tab}
        })
    }

    const handleAddContact = () => {
        setProvider((provider) => {
            let tab = provider.contacts;
            tab = [...tab, newContact];
            return {...provider, contacts: tab }
        });
        setNewContact({ firstName: '', lastName: '', email: '', phone: ''});
        setModalShow(false);
    }

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='header-input'>
                    <input {...register("providerName", { required: true })} className={(errors.providerName ? 'name-input-required' : '')} type='text' placeholder='Nom du client' value={provider.name} onChange={(e) => {setProvider((provider) => { return {...provider, name:e.target.value}})}} />
                </div>
                <div className='view-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleSubmit(handleUpdate)} disabled={buttonLoading}>{ buttonLoading ? <CircularProgress size={20} className="white-loader" /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-row-container align-columns'>
                <span className="input-label location">Adresses <span className='required'>*</span></span>
                { provider.addresses.map((address, index) => (
                    <div className='input-container margin-bottom-15' key={index}>
                        <input value={address.address} className={'search-input '} placeholder='Entrez une adresse' onChange={(e) => {
                            setProvider((provider) => {
                                const tab = provider.addresses
                                tab[index].address = e.target.value
                                return {...provider, addresses: tab}
                            })
                        }} />
                        { index === 0 ? null : <button className='delete-icon-button' onClick={() => handleDeleteAddress(index)} /> }
                    </div>
                ))}
                <button className='main-button small-button blue-button' onClick={handleAddAddress}>Ajouter une adresse</button>
            </div>
            <div className='input-row-container align-columns'>
                <span className="input-label location">Téléphones <span className='required'>*</span></span>
                { provider.phones.map((phone, index) => (
                    <div className='input-container margin-bottom-15' key={index}>
                        <input value={phone.phone} className={'search-input '} placeholder='Entrez un numéro de téléphone' onChange={(e) => {
                            setProvider((provider) => {
                                const tab = provider.phones
                                tab[index].phone = e.target.value
                                return {...provider, phones: tab}
                            })
                        }} />
                        { index === 0 ? null : <button className='delete-icon-button' onClick={() => handleDeletePhone(index)} /> }
                    </div>
                ))}
                <button className='main-button small-button blue-button' onClick={handleAddPhone}>Ajouter un numéro</button>
            </div>
            <div className='input-row-container align-columns'>
                <span className="input-label user">Contacts <span className='required'>*</span></span>
                { provider.contacts.map((contact, index) => (
                    <div className='contact-container' key={index}>
                        <div className='contact-info'>
                            <span>{contact.firstName}</span>
                            <span>{contact.lastName}</span>
                            <span>{contact.email}</span>
                            <span>{contact.phone}</span>
                        </div>
                        <div className='contact-update'>
                            <button className='update-icon-button' onClick={() => handleShowUpdateContact(index)} />
                        </div>
                        <div className='contact-delete'>
                            <button className='delete-icon-button' onClick={() => handleDeleteContact(index)} />
                        </div>
                    </div>
                ))}
                <div className='small-button-container'>
                    <button className='main-button small-button blue-button' onClick={() => setModalShow(true)}>Ajouter un contact</button>
                </div>
            </div>
            <CustomModal title={'Nouveau contact'} setShow={setModalShow} show={modalShow}>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input {...registerContact("firstName", { required: true })} value={newContact.firstName} className={'classic-input ' + (contactErrors.firstName ? 'input-required' : '')} placeholder='Entrez un prénom' onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.lastName} className={'classic-input ' + (contactErrors.lastName ? 'input-required' : '')} placeholder='Entrez un nom' {...registerContact("lastName", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.email} className={'classic-input ' + (contactErrors.email ? 'input-required' : '')} placeholder='Entrez un email' {...registerContact("email", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Téléphone <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={newContact.phone} className={'classic-input ' + (contactErrors.phone ? 'input-required' : '')} placeholder='Entrez un téléphone' {...registerContact("phone", { required: true })} onChange={(e) => {
                            setNewContact((contact) => { return { ...contact, phone:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='submit-right-container'>
                    <button className="main-button blue-button plus" onClick={handleSubmitContact(handleAddContact)}>Ajouter le contact</button>
                </div>
            </CustomModal>
            <CustomModal title={'Éditer le contact'} setShow={handleEditModalShow} show={editModalShow}>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.firstName} className='classic-input' placeholder='Entrez un prénom' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.lastName} className='classic-input' placeholder='Entrez un nom' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.email} className='classic-input' placeholder='Entrez un email' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Téléphone <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={editedContact.phone} className='classic-input' placeholder='Entrez un téléphone' onChange={(e) => {
                            setEditedContact((contact) => { return { ...contact, phone:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='submit-right-container'>
                    <button className="main-button blue-button validate" onClick={handleEditContact}>Modifier le contact</button>
                </div>
            </CustomModal>
        </div>

    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default TrainingEdit;