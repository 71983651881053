import {Link, useSearchParams} from "react-router-dom";
import success_logo from '../../../assets/images/validation.png';
import React, {useContext} from "react";
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import button from "bootstrap/js/src/button";
import {Grid} from "@mui/material";
import AuthContext from "../../../contexts/AuthContext";

const CustomerSubscriptions = ({ customer }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const { club } = useContext(AuthContext);
    const subscriptions = customer.profiles
        .flatMap(profile => (profile.subscriptions.map(s => ({...s, profile }))))
        .sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));

    return (
        <div className='customer-subscriptions-container'>
            { searchParams.get('result') && searchParams.get('result') === 'success' ? (
                <div className="success-subscription">
                    <img src={success_logo} width={80} alt='logo'/>
                    <h1>Votre demande a bien été prise en compte</h1>
                    <span>Un récapitulatif vient de vous être envoyé par email. Votre demande sera étudiée sous peu par un membre du bureau.</span>
                </div>
            ) : null}
            { searchParams.get('result') && searchParams.get('result') === 'change-success' ? (
                <div className="success-subscription">
                    <img src={success_logo} width={80} alt='logo'/>
                    <h1>Votre modification a bien été prise en compte</h1>
                    <span>Votre nouvelle demande sera étudiée sous peu par un membre du bureau.</span>
                </div>
            ) : null}
            <div className='view-subtitle ticket'>
                <h2>Vos demandes d'adhésion pour : {club.season.name}</h2>
            </div>
            <div className='public-subscription-list'>
                { subscriptions.filter(s => s.group.seasonId === club.season.id).length > 0 ? (
                    <Grid container spacing={2}>
                        {subscriptions.length > 0 && subscriptions.filter(s => s.group.seasonId === club.season.id).map((subscription, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Link to={subscription.id}>
                                    <div className='public-subscription-block' key={subscription.id}>
                                        <div className='subscription-line user'>
                                            <span className='subscription-line-title'>Profil </span>
                                            <CustomTag
                                                value={`${subscription.profile.firstName} ${subscription.profile.lastName}`}/>
                                        </div>
                                        <div className='subscription-line'>
                                            <span className='subscription-line-title'>Statut </span>
                                            <CustomTag value={subscription.status}/>
                                        </div>
                                        <div className='subscription-line group'>
                                            <span className='subscription-line-title'>Groupe </span>
                                            <CustomTag value={subscription.group.name}/>
                                        </div>
                                        <button className='main-button blue-button submit-subcription'>Voir la demande
                                        </button>
                                    </div>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <CustomTag value="Vous n'avez aucune demande d'adhésion pour le moment" />
                )}
            </div>
            <div className='view-subtitle ticket'>
                <h2>Vos demandes d'adhésion pour les saisons précédentes :</h2>
            </div>
            <div className='public-subscription-list'>
                { subscriptions.filter(s => s.group.seasonId !== club.season.id).length > 0 ? (
                    <Grid container spacing={2}>
                        { subscriptions.length > 0 && subscriptions.filter(s => s.group.seasonId !== club.season.id).map((subscription, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Link to={subscription.id}>
                                    <div className='public-subscription-block' key={subscription.id}>
                                        <div className='subscription-line user'>
                                            <span className='subscription-line-title'>Profil </span>
                                            <CustomTag
                                                value={`${subscription.profile.firstName} ${subscription.profile.lastName}`}/>
                                        </div>
                                        <div className='subscription-line'>
                                            <span className='subscription-line-title'>Statut </span>
                                            <CustomTag value={subscription.status}/>
                                        </div>
                                        <div className='subscription-line group'>
                                            <span className='subscription-line-title'>Groupe </span>
                                            <CustomTag value={subscription.group.name}/>
                                        </div>
                                        <button className='main-button submit-subcription'>Voir la demande
                                        </button>
                                    </div>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <CustomTag value="Vous n'avez aucune demande d'adhésion pour le moment" />
                )}
            </div>
        </div>
    )
}

export default CustomerSubscriptions;