import {Route, Routes} from "react-router-dom";
import SubscriptionView from "./SubscriptionView";
import SubscriptionList from "./SubscriptionList";
import SubscriptionGroupUpdate from "./SubscriptionGroupUpdate";

const Subscriptions = ({ groups, appRefresh, setAppRefresh }) => {
    return (
        <Routes>
            <Route path=":subscriptionId/group-update" element={<SubscriptionGroupUpdate groups={groups} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path=":subscriptionId" element={<SubscriptionView groups={groups} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path="*" element={<SubscriptionList />} />
        </Routes>
    )
}

export default Subscriptions;