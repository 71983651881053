import {Document, Page} from "react-pdf";
import {CircularProgress} from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import CustomTag from "../../custom/CustomTag";
import AuthContext from "../../../contexts/AuthContext";

const SubscriptionLicence = ({ subscription, tab, user, setNewLicenceSign }) => {

    const containerRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [containerWidth, setContainerWidth] = useState(400);
    const [loadError, setLoadError] = useState(false);
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    const { user: loggedUser } = useContext(AuthContext);

    const updateWidth = () => {
        if (containerRef.current) setContainerWidth((containerRef.current.offsetWidth - 40) > 761 ? 761 : (containerRef.current.offsetWidth - 40));
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    React.useEffect(() => updateWidth, [containerRef.current, tab]);

    return (
        <div className='admin-licence-container' ref={containerRef}>
            { !loadError ? (
                <>
                    <div className='admin-licence-buttons'>
                        { user && user.id === loggedUser.id ? (
                            <button className='main-button sign margin-right' onClick={() => setNewLicenceSign(true)}>Signer à nouveau</button>
                        ) : null }
                        <a href={ user ? `${process.env.REACT_APP_FILES_API_URL}users/${subscription.userId}/${subscription.seasonId}.pdf` : `${process.env.REACT_APP_FILES_API_URL}subscriptions/${subscription.id}/licence.pdf`}
                           target="_blank" download>
                            <button className='main-button download'>Télécharger</button>
                        </a>
                    </div>
                    <div className="licence-sign-pdf-container">
                        <div className='licence-file-container'>
                            <Document
                                file={ user ? `${process.env.REACT_APP_FILES_API_URL}users/${subscription.userId}/${subscription.seasonId}.pdf` : `${process.env.REACT_APP_FILES_API_URL}subscriptions/${subscription.id}/licence.pdf`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={() => setLoadError(true)}
                                loading={
                                    <div className='pdf-loader-container'>
                                        <CircularProgress size={50}/>
                                    </div>
                                }
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        width={containerWidth}
                                        loading={
                                            <div className='pdf-loader-container'>
                                                <CircularProgress size={50}/>
                                            </div>
                                        }
                                    />
                                ))}
                            </Document>
                        </div>
                    </div>
                </>
            ) : (
                <div className='view-container'>
                    <CustomTag value="Aucune licence disponible" />
                </div>
            )}
        </div>
    );
}

export default SubscriptionLicence;