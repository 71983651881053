import CustomTag from "./CustomTag";
import React from "react";
import {Grid} from "@mui/material";

const GroupChooser = ({groups, selectedGroups, setSelectedGroups}) => {

    return groups.length > 0 ? (
        <div className='groups-chooser training-selector-container'>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div className='training-selector-block'>
                        <label className="checkbox-container">
                            <input
                                type="checkbox"
                                checked={selectedGroups.length === groups.length}
                                onClick={(event) => {
                                    if(event.target.checked) {
                                        setSelectedGroups([...groups]);
                                    } else {
                                        setSelectedGroups([]);
                                    }
                                }}
                            />
                            <span className="checkmark"/>
                        </label>
                        <CustomTag value={selectedGroups.length === groups.length ? 'Tout désélectionner' : 'Tout sélectionner'} className='active' />
                    </div>
                </Grid>
                { groups.map((group, index) => (
                    <Grid item xs={6}>
                        <div key={index} className='training-selector-block'>
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    checked={selectedGroups.some(g => g.id === group.id)}
                                    onClick={(event) => {
                                        if(event.target.checked) {
                                            setSelectedGroups([...selectedGroups, group]);
                                        } else {
                                            setSelectedGroups([...selectedGroups].filter(g => g.id !== group.id));
                                        }
                                    }}
                                />
                                <span className="checkmark"/>
                            </label>
                            <CustomTag value={group.name} />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    ) : null ;
}

export default GroupChooser;