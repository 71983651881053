import {Routes, Route} from "react-router-dom";
import AuthContext from "./contexts/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import './assets/styles/App.css';
import axios from "axios";
import AdminPanel from "./components/admin/AdminPanel";
import AdminRoute from "./components/public/login/AdminRoute";
import locale_fr from 'dayjs/locale/fr';
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';
import {CircularProgress} from "@mui/material";
import PublicPanel from "./components/public/PublicPanel";

const App = () => {

    dayjs.locale(locale_fr);
    dayjs.extend(isToday);

    const [token, setToken] = useState(localStorage.getItem('session_token'));
    const [user, setUser] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [club, setClub] = useState(null);
    const [groups, setGroups] = useState(null);
    const [counts, setCounts] = useState(null);

    const [appRefresh, setAppRefresh] = useState(0);
    const [userLoaded, setUserLoaded] = useState(false);

    const contextValue = {
        token: token,
        user: user,
        customer: customer,
        club: club,
        setToken: setToken,
        setUser:setUser,
        setCustomer: setCustomer,
        setClub: setClub
    }

    const subdomain = window.location.host.split('.')[0];

    React.useEffect( () => {
        setUserLoaded(false);
        axios.get(process.env.REACT_APP_PUBLIC_API_URL + 'clubs/' + subdomain).then((response) => {
            if(response.data) {
                setClub(response.data);
                document.title = `${response.data.name} - ${response.data.season.name}`;
                if(localStorage.getItem('session_token')) {
                    axios.get(process.env.REACT_APP_DATA_API_URL, {
                        headers:{ "Authorization": "Bearer " + localStorage.getItem('session_token') }
                    }).then((response) => {
                        if(response.data.user) {
                            setUser({...response.data.user, season: response.data.season });
                            setGroups(response.data.groups.map(group => ({...group, value: group.id, label: group.name})));
                            setCounts(response.data.counts);
                        } else {
                            setCustomer(response.data.customer);
                        }
                        setUserLoaded(true);
                    }).catch(() => {
                        setUserLoaded(true);
                    });
                } else {
                    setUserLoaded(true);
                }
            } else {
                window.location.href = process.env.REACT_APP_WEBSITE_URL;
            }
        }).catch(() => {
            window.location.href = process.env.REACT_APP_WEBSITE_URL;
        });
    }, [token, appRefresh]);

    return userLoaded ? (
        <AuthContext.Provider value={contextValue}>
            <Routes>
                <Route path='admin/*' element={
                    <AdminRoute>
                        <AdminPanel
                            counts={counts}
                            groups={groups}
                            setGroups={setGroups}
                            appRefresh={appRefresh}
                            setAppRefresh={setAppRefresh}
                        />
                    </AdminRoute>
                } />
                <Route path="*" element={<PublicPanel appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            </Routes>
            { process.env.REACT_APP_MODE !== "PRODUCTION" ? (
                <span className='application-mode-info'>{process.env.REACT_APP_MODE}</span>
            ) : null }
        </AuthContext.Provider>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default App;
