import React, {useContext} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";
import FullCalendar from "@fullcalendar/react";
import {CircularProgress} from "@mui/material";
import GroupDetails from "./GroupDetails";
import dayjs from "dayjs";

const PlanningView = () => {

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [planning, setPlanning] = React.useState([]);
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [activeGroup, setActiveGroup] = React.useState(null);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [key, setKey] = React.useState(0);
    const [nextEvent, setNextEvent] = React.useState(dayjs().format('YYYY-MM-DD'));
    const { club } = useContext(AuthContext);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setKey(prevKey => prevKey + 1);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth <= 768;

    React.useEffect(() => {
        setIsLoaded(false);
        axios.post(process.env.REACT_APP_PUBLIC_API_URL + 'planning', { seasonId: club.season.id })
            .then((response) => {
                if(response.data) {
                    const events = response.data.map(e => ({...e, title: e.training.group.name, color: e.training.group.color, active: true }));
                    setPlanning(events);
                    if (events.length > 0) {
                        const sortedEvents = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
                        const nextEvent = sortedEvents.find(event => dayjs(event.start).isAfter(dayjs()));
                        if (nextEvent) setNextEvent(dayjs(nextEvent.start).format('YYYY-MM-DD'));
                    }
                    setIsLoaded(true);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const handleEventClick = (info) => {
        setDetailsOpen(true);
        setActiveGroup(info.event.extendedProps.training.group);
    }

    return isLoaded ? (
        <div className='public-planning-container'>
            <FullCalendar
                key={key}
                plugins={[timeGridPlugin, dayGridPlugin]}
                initialView={ isMobile ? 'timeGridDay' : 'timeGridWeek' }
                allDaySlot={false}
                height={'100%'}
                events={planning}
                nowIndicator
                locale={frLocale}
                initialDate={nextEvent}
                headerToolbar={ !isMobile ? {
                    left: 'prev,today,next',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth'
                } : {
                    left: 'today prev,next',
                    center: 'title',
                    right: ''
                }}
                eventClick={handleEventClick}
                selectable
            />
            <GroupDetails
                open={detailsOpen}
                setOpen={setDetailsOpen}
                groupId={activeGroup ? activeGroup.id : null}
            />
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default PlanningView;