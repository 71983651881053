import React from "react";

const NoOptionMessage = ({ selectProps }) => {

    return selectProps.inputValue.length > 3 ? (
        <div className="no-option-message">Aucun élément trouvé</div>
    ) : (
        <div className="no-option-message">3 caractères minimum</div>
    );
}

export default NoOptionMessage;