import React from "react";
import {Route, Routes} from "react-router-dom";
import GroupList from "./GroupList";
import GroupAdd from "./GroupAdd";
import GroupView from "./GroupView";
import GroupEdit from "./GroupEdit";

const Groups = ({groups, appRefresh, setAppRefresh}) => {
    return (
        <Routes>
            <Route path='new' element={<GroupAdd appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path=':groupId/edit' element={<GroupEdit appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path=':groupId' element={<GroupView groups={groups} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path='*' element={<GroupList />} />
        </Routes>
    );
}

export default Groups;