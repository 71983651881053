import React, {useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import toastOptions from '../../../assets/utils/toast';
import axios from "axios";
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import GroupSettings from "./GroupSettings";
import {CircularProgress} from "@mui/material";

const GroupEdit = ({groups, appRefresh, setAppRefresh}) => {

    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [trainings, setTrainings] = useState(null);
    const [error, setError] = useState();
    const [rates, setRates] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'groups/' + groupId + '/edit', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data && response.data.seasonId === user.activeSeason) {
                    setGroup(response.data);
                    setTrainings(response.data.trainings);
                    setRates(response.data.rates);
                    setIsLoaded(true);
                } else {
                    navigate(groups.length > 0 ? '/admin/groups/' + groups[0].id : '/admin');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, groupId]);

    const handleUpdate = () => {
        setButtonLoading(true);
        setError(null);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'groups/' + groupId, {...group, trainings, rates}, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setAppRefresh(appRefresh + 1);
                toast.success("Le groupe a bien été modifié", toastOptions);
                navigate('/admin/groups/' + groupId);
            })
            .catch((error) => {
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div className='group-edit'>
            <div className='view-fixed-title'>
                <h1>Modifier le groupe</h1>
                <div className='view-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button margin-right'>Annuler</button>
                    <button className='main-button blue-button validate' disabled={buttonLoading} onClick={handleUpdate}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier' }</button>
                </div>
            </div>
            <div className='view-container scrollable'>
                { error ? (
                    <div className='error-message'>
                        <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                        { error.details.map((detail, index) => (
                            <span key={index}>- {detail}</span>
                        ))}
                    </div>
                ) : null }
                <GroupSettings
                    group={group}
                    setGroup={setGroup}
                    trainings={trainings}
                    setTrainings={setTrainings}
                    rates={rates}
                    setRates={setRates}
                />
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default GroupEdit;