import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import ProfileView from "./ProfileView";
import ProfileEdit from "./ProfileEdit";

const Profiles = ({appRefresh, setAppRefresh}) => {
    return (
        <Routes>
            <Route path=':profileId' element={<ProfileView appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path=':profileId/edit' element={<ProfileEdit />} />
            <Route  path='*' element={<Navigate to='/admin' />} />
        </Routes>
    );
}

export default Profiles;