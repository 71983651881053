import update from 'immutability-helper';
import {useCallback, useEffect, useState} from 'react';
import { SortedCard } from './SortedCard';

export const SortingContainer = ({ cards, onCardDrop }) => {

    const [cardsState, setCardsState] = useState(cards.map((card, index) => ({ ...card, position: index })));

    useEffect(() => {
        setCardsState(cards.map((card, index) => ({ ...card, position: index })));
    }, [cards]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCardsState((prevCards) => {
            const card = prevCards[dragIndex];
            const updatedCards = update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, card],
                ],
            });
            return updatedCards.map((card, index) => ({ ...card, position: index }));
        });
    }, []);

    const handleCardDrop = useCallback(() => {
        if (onCardDrop && typeof onCardDrop === 'function') {
            onCardDrop(cardsState);
        }
    }, [cardsState]);

    const renderCard = useCallback(
        (card, index) => (
            <SortedCard key={card.id} index={index} id={card.id} moveCard={moveCard} onDrop={handleCardDrop}>
                {card.content}
            </SortedCard>
        ),
        [moveCard, handleCardDrop]
    );

    return <div>{cardsState.map((card, i) => renderCard(card, i))}</div>;
};
