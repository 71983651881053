import React, {useContext, useState} from 'react';
import axios from 'axios';
import "../../../assets/styles/Login.css";
import AuthContext from "../../../contexts/AuthContext";
import logo from "../../../assets/images/rounder-logo-white.svg";
import {Link, Navigate, useLocation} from "react-router-dom";
import {CircularProgress, Grid} from "@mui/material";
import dayjs from "dayjs";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const {state} = useLocation();
    const {user, customer, setToken} = useContext(AuthContext);

    if (redirectToReferrer === true || user || customer) {
        if(customer) {
            return <Navigate to={ state?.from || '/my-account'} />
        } else {
            return <Navigate to={ state?.from || '/admin'} />
        }
    }

    const handleSubmit = (event) => {
        if(event.type === 'click' || event.key === 'Enter') {
            setError(null);
            setLoader(true);
            axios.post(process.env.REACT_APP_PUBLIC_API_URL + "login", {
                email: email,
                password: password
            })
                .then((response) => {
                    localStorage.setItem('session_token', response.data.token);
                    setToken(response.data.token);
                    setRedirectToReferrer(true);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.response.data.message);
                    setLoader(false);
                });
        }
    }

    return (
        <div className="main-login" onKeyDown={handleSubmit}>
            <div className='login-container'>
                <div className='login-slider-container'>
                    <div className='login-background-container'>
                        <div className='login-logo-container'>
                            <img src={logo} alt='logo' width={200} />
                        </div>
                        <h1>Libérez le potentiel<br/> de votre club de natation.</h1>
                    </div>
                </div>
                <div className='login-right-form'>
                    <div className='form-container'>
                        <h1>Content de vous revoir !</h1>
                        <h2>Saisissez vos identifiants ci-dessous</h2>
                        <span className={'login-message ' + (error ? 'error' : 'hidden')}>{error}</span>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <input className='input' type='email' id='email' placeholder='Votre email' onChange={e => setEmail(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <input className='input' type='password' id='password' placeholder='Votre mot de passe' onChange={e => setPassword(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Link to='/forgot-password' className="forgot-password">
                            <small>Mot de passe oublié ?</small>
                        </Link>
                        <button className='main-button blue-button' disabled={loader} onClick={handleSubmit}>{!loader ? 'Connexion' : <CircularProgress size={20} />}</button>
                        <div className='login-divider' />
                        <div className='login-sign-up'>
                            <span>Pas encore de compte ?</span>
                            <Link to='/sign-up'>S'inscrire</Link>
                        </div>
                    </div>
                    <div className='rights-container'>
                        <span>{dayjs().format('YYYY')} Swimcoach - Tous droits réservés</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
