import CustomRichText from "../../custom/CustomRichText";
import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress} from "@mui/material";
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";

const SubscriptionChat = ({ subscription, isPublic = false }) => {

    const formatMessages = (messages) => {
        const groupedMessages = messages.reduce((acc, message) => {
            const messageDate = dayjs(message.createdAt).format('YYYY-MM-DD');
            acc[messageDate] = acc[messageDate] || [];
            acc[messageDate].push(message);
            return acc;
        }, {});
        return Object.entries(groupedMessages).map(([date, messages]) => ({date, messages}));
    }

    const [messages, setMessages] = useState(subscription.messages);
    const [formattedMessages, setFormattedMessages] = useState(formatMessages(subscription.messages));
    const [newMessage, setNewMessage] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const { token } = useContext(AuthContext);

    useEffect(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }));

    const handleSendMessage = () => {
        if(newMessage) {
            setButtonLoading(true);
            axios.post((isPublic ? process.env.REACT_APP_PUBLIC_API_URL : process.env.REACT_APP_ADMIN_API_URL) + 'subscriptions/' + subscription.id + '/send-message', { message: newMessage },{ headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    setMessages([...messages, { sender: isPublic ? 'customer' : 'user', content: newMessage, createdAt: dayjs()}]);
                    setFormattedMessages(formatMessages([...messages, { sender: isPublic ? 'customer' : 'user', content: newMessage, createdAt: dayjs()}]));
                    setNewMessage(null);
                    setButtonLoading(false);
                })
                .catch(() => {
                    toast.error('Une erreur est survenue', toastOptions);
                    setNewMessage(null);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <div className='subscription-chat-container'>
            <div className='subscription-chat-messages'>
                { formattedMessages.length > 0 ? formattedMessages.map((day, index) => (
                    <div key={index}>
                        <div className='chat-date-separator'>
                            <span>{dayjs(day.date).isToday() ? "Aujourd'hui" : dayjs(day.date).format('DD MMMM YYYY')}</span>
                        </div>
                        { day.messages && day.messages.map((message, index) => (
                            <div className={`message-container ${message.sender}`} key={index}>
                                <div className='message-content-time'>
                                    <span className='hour-sent'>{dayjs(message.createdAt).format('HH:mm')}</span>
                                    <CustomRichText
                                        className={'chat-message'}
                                        value={message.content}
                                        readMode={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )) : (
                    <div className='empty-messages'>
                        <CustomTag value="Aucun message pour le moment"/>
                    </div>
                )}
                <div ref={messagesEndRef}/>
            </div>
            <div className='chat-message-sender'>
                <CustomRichText
                    value={newMessage}
                    setValue={(newValue) => setNewMessage(newValue)}
                    placeholder='Votre message'
                    hideToolbar={isPublic}
                />
                <div className={`chat-message-button ${buttonLoading ? 'loading' : null}`}>
                    <button onClick={handleSendMessage}>{ buttonLoading ? <CircularProgress size={20} /> : null}</button>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionChat;