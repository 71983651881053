import React from "react";

const AuthContext = React.createContext({
    token:null,
    user: null,
    customer: null,
    club: null,
    setUser: () => {},
    setCustomer: () => {},
    setToken: () => {},
    setClub: () => {}
});

export default AuthContext;
