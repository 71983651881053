import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import CustomTag from "../../custom/CustomTag";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import CustomRichText from "../../custom/CustomRichText";

const OptionSettings = () => {

    const [options, setOptions] = useState([]);
    const [newOption, setNewOption] = useState({ isActive: true });
    const [editedOption, setEditedOption] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'options', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setOptions(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaded(true);
            });
    }, [refresh]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'options', newOption, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalAddOpen(false);
                setRefresh(refresh + 1);
                toast.success("La réduction a bien été ajoutée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'options/' + editedOption.id, editedOption, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalEditOpen(false);
                setRefresh(refresh + 1);
                toast.success("La réduction a bien été modifiée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleDelete = (optionId) => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'options/' + optionId, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                toast.success("La réduction a bien été supprimée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div>
            <div className='view-subtitle options'>
                <h2>Configuration des options</h2>
            </div>
            { options.length > 0 ? (
                <div className='entity-add-container'>
                    { options.map((option, index) => (
                        <div className='training-edit-block' key={index} >
                            <div className='entity-with-description'>
                                <div className='flex-view'>
                                    <div className='view-subtitle options margin-right'>
                                        <h2>{option.name}</h2>
                                    </div>
                                    <CustomTag value={`${option.value} €`} />
                                </div>
                            </div>
                            <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                <React.Fragment>
                                    <div className='tooltip-container'>
                                        <span className='edit' onClick={() => {
                                            setEditedOption(option);
                                            setModalEditOpen(true);
                                        }}>Modifier</span>
                                        <span className='delete' onClick={() => handleDelete(option.id)}>Supprimer</span>
                                    </div>
                                </React.Fragment>
                            }>
                                <IconButton aria-label="delete" size="small">
                                    <MoreVertRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ))}
                    <div className='training-edit-block'>
                        <button className='add-training-button' onClick={() => setModalAddOpen(true)}>Ajouter une option</button>
                    </div>
                </div>
            ) : (
                <div className='training-edit-block'>
                    <button className='add-training-button' onClick={() => setModalAddOpen(true)}>Ajouter une option</button>
                </div>
            )}
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter une option'>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            size="small"
                            label="Nom de l'option"
                            variant="outlined"
                            onChange={(e) => setNewOption({...newOption, name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            size="small"
                            type="number"
                            label="Prix en €"
                            variant="outlined"
                            onChange={(e) => setNewOption({...newOption, value: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomRichText
                            value={newOption.description}
                            setValue={(newValue) => setNewOption({...newOption, description: newValue})}
                            placeholder='Entrez une description'
                        />
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button' onClick={handleCreate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Ajouter'}</button>
                </div>
            </CustomModal>
            <CustomModal show={modalEditOpen} setShow={setModalEditOpen} title='Modifier une option'>
                { editedOption ? (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    label="Nom de l'option"
                                    variant="outlined"
                                    value={editedOption.name}
                                    onChange={(e) => setEditedOption({...editedOption, name: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    type="number"
                                    label="Valeur en €"
                                    variant="outlined"
                                    value={editedOption.value}
                                    onChange={(e) => setEditedOption({...editedOption, value: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomRichText
                                    value={editedOption.description}
                                    setValue={(newValue) => setEditedOption({...editedOption, description: newValue})}
                                    placeholder='Entrez une description'
                                />
                            </Grid>
                        </Grid>
                        <div className='modal-bottom-button'>
                            <button className='main-button blue-button edit' onClick={handleUpdate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier'}</button>
                        </div>
                    </div>
                ) : null }
            </CustomModal>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default OptionSettings;