import React, {useContext, useState} from 'react';
import axios from 'axios';
import "../../../assets/styles/Login.css";
import AuthContext from "../../../contexts/AuthContext";
import logo from "../../../assets/images/rounder-logo-white.svg";
import {Link, Navigate} from "react-router-dom";
import {CircularProgress, Grid} from "@mui/material";
import dayjs from "dayjs";

const SignUp = () => {

    const [newCustomer, setNewCustomer] = useState({});
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const {user, customer, club, setToken} = useContext(AuthContext);

    if (user || customer) {
        if(customer) {
            return <Navigate to={'/my-account'} />
        } else {
            return <Navigate to={'/admin'} />
        }
    }

    const handleSubmit = (event) => {
        if(event.type === 'click' || event.key === 'Enter') {
            setError(null);
            setLoader(true);
            axios.post(process.env.REACT_APP_PUBLIC_API_URL + "sign-up", {...newCustomer, clubId: club.id })
                .then((response) => {
                    localStorage.setItem('session_token', response.data.token);
                    setToken(response.data.token);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.response.data);
                    setLoader(false);
                });
        }
    }

    return (
        <div className="main-login" onKeyDown={handleSubmit}>
            <div className='login-container'>
                <div className='login-slider-container'>
                    <div className='login-background-container'>
                        <div className='login-logo-container'>
                            <img src={logo} alt='logo' width={200} />
                        </div>
                        <h1>Libérez le potentiel<br/> de votre club de natation.</h1>
                    </div>
                </div>
                <div className='login-right-form sign-up'>
                    <div className='form-container'>
                        <h1>Bienvenue !</h1>
                        <h2>Saisissez vos informations pour créer votre compte</h2>
                        { error ? (
                            <div className='error-message column'>
                                <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                                { error.details && error.details.map((detail, index) => (
                                    <span key={index}>- {detail}</span>
                                ))}
                            </div>
                        ) : null }
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <input className='input' type='text' name='firstName' id='firstName' placeholder='Prénom' onChange={e => setNewCustomer({...newCustomer, firstName: e.target.value})} />
                            </Grid>
                            <Grid item xs={6}>
                                <input className='input' type='text' name='lastName' id='lastName' placeholder='Nom' onChange={e => setNewCustomer({...newCustomer, lastName: e.target.value})} />
                            </Grid>
                            <Grid item xs={12}>
                                <input className='input' type='email' name='email' id='email' placeholder='Email' onChange={e => setNewCustomer({...newCustomer, email: e.target.value})} />
                            </Grid>
                            <Grid item xs={12}>
                                <input className='input' type='password' name='password' id='password' placeholder='Mot de passe' onChange={e => setNewCustomer({...newCustomer, password: e.target.value})} />
                            </Grid>
                        </Grid>
                        <button className='main-button blue-button' disabled={loader} onClick={handleSubmit}>{!loader ? "Inscription" : <CircularProgress size={20} />}</button>
                        <div className='login-divider' />
                        <div className='login-sign-up'>
                            <span>Déjà un compte ?</span>
                            <Link to='/login'>Se connecter</Link>
                        </div>
                    </div>
                    <div className='rights-container'>
                        <span>{dayjs().format('YYYY')} Swimcoach - Tous droits réservés</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
