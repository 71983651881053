import React, {useContext} from 'react';
import CustomSearchInput from "../../custom/CustomSearchInput";
import {FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import CustomRichText from "../../custom/CustomRichText";
import AuthContext from "../../../contexts/AuthContext";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import constants from "../../../assets/utils/constants";
import HelpIcon from "@mui/icons-material/Help";

const GroupSettings = ({group, setGroup, rates, setRates, trainings, setTrainings }) => {

    const { club } = useContext(AuthContext);

    return (
        <div className='group-settings'>
            <div className='view-subtitle'>
                <h2>Informations générales</h2>
            </div>
            <div className='input-row-container'>
                <input type='text'
                       className='input'
                       value={group.name}
                       placeholder='Nom du groupe'
                       onChange={(e) => setGroup({...group, name: e.target.value})}
                />
            </div>
            <div className='input-row-container'>
                <CustomSearchInput
                    placeholder='Ajoutez un ou plusieurs coach(s)'
                    entity='users'
                    labelType='user'
                    value={group.coaches && group.coaches.map(coach => ({
                        ...coach,
                        value: coach.id,
                        label: `${coach.firstName} ${coach.lastName}`
                    }))}
                    isMultiSearch={true}
                    onChange={(users => setGroup({...group, coaches: users}))}
                />
            </div>
            <div className='input-textarea'>
                <CustomRichText
                    value={group.description}
                    setValue={(newValue) => setGroup({...group, description: newValue})}
                    placeholder='Entrez votre description'
                />
            </div>
            <div className='view-subtitle categories flex-start'>
                <h2 className='margin-right'>Type de groupe</h2>
                <Tooltip enterTouchDelay={0} placement='right' id='options-tooltip' title={
                    <React.Fragment>
                        <CustomRichText
                            value="Cette information sera utilisée pour remplir automatiquement l'encart 'Type de licence' du formulaire de licence FFN rempli par l'adhérent."
                            readMode
                            className="padding"
                        />
                    </React.Fragment>
                }>
                    <IconButton size="small">
                        <HelpIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className='entity-add-container'>
                <RadioGroup
                    aria-label="groupType"
                    name="groupType"
                    value={group.licenceType}
                    onChange={(event) => setGroup({...group, licenceType: event.target.value})}
                >
                    <Grid container>
                        { constants.groupTypes.map((groupType) => (
                            <Grid item xs={12} md={4} key={groupType.value}>
                                <div className='view-subtitle group'>
                                    <h2>{groupType.label}</h2>
                                </div>
                                { groupType.groups.map((group) => (
                                    <FormControlLabel
                                        className='licence-type-radio'
                                        key={group.value}
                                        value={group.value}
                                        control={<Radio size='small'/>}
                                        label={group.label}
                                    />
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                </RadioGroup>
            </div>
            <div className='view-subtitle calendar'>
                <h2>Séances</h2>
            </div>
            <div className='trainings-mandatory-chooser'>
                <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                    <FormControlLabel
                        checked={group.trainingsType === 'optional'}
                        control={<Radio size="small"/>}
                        label="Les séances sont au choix"
                        onClick={() => {
                            setGroup({...group, trainingsType: 'optional'});
                            setRates([{groupId: group.id, allowedNb: 1, price: 200}]);
                        }}
                    />
                    <FormControlLabel
                        checked={group.trainingsType === 'mandatory'}
                        control={<Radio size="small"/>}
                        label="Toutes les séances sont obligatoires"
                        onClick={() => {
                            setGroup({...group, places: group.places ? group.places : 10, trainingsType: 'mandatory'});
                            setRates([{groupId: group.id, allowedNb: 1, price: 200}]);
                        }}
                    />
                </RadioGroup>
            </div>
            {group.trainingsType === 'mandatory' ? (
                <div className='full-group-places'>
                    <span className='margin-right'>Nombre de places</span>
                    <input
                        type='number'
                        className='input number'
                        min={1}
                        step={1}
                        value={group.places}
                        onChange={(e) => setGroup({...group, places: e.target.value ? parseInt(e.target.value) : null})}
                    />
                </div>
            ) : null}
            <div className='entity-add-container'>
                {trainings.map((training, index) => (
                    <div className='training-edit-block' key={index}>
                        <div className='flex-view'>
                            <select className='classic-select' value={training.weekDay} onChange={(event) => {
                                const updatedTraining = {...training, weekDay: event.target.value};
                                const updatedTrainings = [...trainings];
                                updatedTrainings[index] = updatedTraining;
                                setTrainings(updatedTrainings);
                            }}>
                                <option value='monday'>Lundi</option>
                                <option value='tuesday'>Mardi</option>
                                <option value='wednesday'>Mercredi</option>
                                <option value='thursday'>Jeudi</option>
                                <option value='friday'>Vendredi</option>
                                <option value='saturday'>Samedi</option>
                                <option value='sunday'>Dimanche</option>
                            </select>
                            <span className='margin-left margin-right'>de</span>
                            <div className='input-date-container margin-right'>
                                <TimePicker
                                    label="Début"
                                    ampm={false}
                                    className='hour-picker'
                                    slotProps={{textField: {size: 'small'}}}
                                    views={['hours', 'minutes']}
                                    value={dayjs(training.startHour, 'HH:mm:ss')}
                                    onChange={(date) => {
                                        const updatedTraining = {
                                            ...training,
                                            startHour: dayjs(date).format('HH:mm:ss')
                                        };
                                        const updatedTrainings = [...trainings];
                                        updatedTrainings[index] = updatedTraining;
                                        setTrainings(updatedTrainings);
                                    }}
                                />
                            </div>
                            <span className='margin-right'>à</span>
                            <div className='input-date-container'>
                                <TimePicker
                                    label="Fin"
                                    ampm={false}
                                    className='hour-picker'
                                    slotProps={{textField: {size: 'small'}}}
                                    views={['hours', 'minutes']}
                                    value={dayjs(training.stopHour, 'HH:mm:ss')}
                                    onChange={(date) => {
                                        const updatedTraining = {...training, stopHour: dayjs(date).format('HH:mm:ss')};
                                        const updatedTrainings = [...trainings];
                                        updatedTrainings[index] = updatedTraining;
                                        setTrainings(updatedTrainings);
                                    }}
                                />
                            </div>
                        </div>
                        <CustomSearchInput
                            placeholder='Choisissez une piscine'
                            entity={club.pools}
                            labelType='pool'
                            className='margin-right margin-left'
                            value={club.pools.find(p => p.id === training.poolId)}
                            onChange={(pool) => {
                                const updatedTraining = {...training, poolId: pool.id};
                                const updatedTrainings = [...trainings];
                                updatedTrainings[index] = updatedTraining;
                                setTrainings(updatedTrainings);
                            }}
                        />
                        {group.trainingsType === 'optional' ? (
                            <>
                                <TextField
                                    size="small"
                                    label="Places"
                                    variant="outlined"
                                    className='small-width'
                                    type='number'
                                    value={training.places}
                                    onChange={(e) => {
                                        const updatedTraining = {...training, places: parseInt(e.target.value)};
                                        const updatedTrainings = [...trainings];
                                        updatedTrainings[index] = updatedTraining;
                                        setTrainings(updatedTrainings);
                                    }}
                                />
                            </>
                        ) : null}
                        {trainings.length > 1 ? (
                            <IconButton aria-label="delete" size="small" onClick={() => {
                                const updatedTrainings = [...trainings];
                                updatedTrainings.splice(index, 1);
                                setTrainings(updatedTrainings);
                            }}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        ) : null}
                    </div>
                ))}
                <div className='training-edit-block'>
                    <button className='add-training-button' onClick={() => setTrainings([...trainings, {
                        groupId: group.id,
                        places: 10,
                        weekDay: 'monday',
                        startHour: '12:00:00',
                        stopHour: '14:00:00',
                    }])}>Ajouter une séance
                    </button>
                </div>
            </div>
            <div className='view-subtitle price'>
                <h2>Tarifs</h2>
            </div>
            <div className='entity-add-container'>
                <div>
                    {group.trainingsType === 'mandatory' ? (
                        <div className='training-edit-block'>
                            <div className='flex-view'>
                                <span>Tarif du groupe : </span>
                                <div className='euro-input margin-left'>
                                    <input
                                        className='input'
                                        type='number'
                                        min={0}
                                        step={0.5}
                                        value={rates[0].price}
                                        onChange={e => setRates([{
                                            groupId: group.id,
                                            allowedNb: 1,
                                            price: e.target.value
                                        }])}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {rates.map((rate, index) => (
                                <div className='training-edit-block' key={index}>
                                    <div className='flex-view'>
                                        <span>Tarif de</span>
                                        <div className='euro-input margin-left'>
                                            <input
                                                className='input'
                                                type='number'
                                                min={0}
                                                step={0.5}
                                                value={rate.price}
                                                onChange={e => {
                                                    const updatedRate = {...rate, price: e.target.value};
                                                    const updatedRates = [...rates];
                                                    updatedRates[index] = updatedRate;
                                                    setRates(updatedRates);
                                                }}
                                            />
                                        </div>
                                        <span className='margin-left margin-right'>pour</span>
                                        <input
                                            type='number'
                                            className='input number'
                                            min={1}
                                            step={1}
                                            value={rate.allowedNb}
                                            max={trainings.length}
                                            onChange={e => {
                                                const updatedRate = {...rate, allowedNb: e.target.value};
                                                const updatedRates = [...rates];
                                                updatedRates[index] = updatedRate;
                                                setRates(updatedRates);
                                            }}
                                        />
                                        <span className='margin-left margin-right'>séance(s)</span>
                                    </div>
                                    {rates.length > 1 ? (
                                        <IconButton aria-label="delete" size="small" onClick={() => {
                                            const updatedRates = [...rates];
                                            updatedRates.splice(index, 1);
                                            setRates(updatedRates);
                                        }}>
                                            <CloseIcon fontSize="small"/>
                                        </IconButton>
                                    ) : null}
                                </div>
                            ))}
                            <div className='training-edit-block'>
                                <button className='add-training-button' onClick={() => setRates([...rates, {
                                    groupId: group.id,
                                    allowedNb: 1,
                                    price: 100
                                }])}>Ajouter un tarif
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GroupSettings;