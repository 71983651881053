import PaymentRecap from "./PaymentRecap";
import React, {useContext} from "react";
import {CircularProgress, Grid, TextField} from "@mui/material";
import CustomSearchInput from "../../custom/CustomSearchInput";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import constants from "../../../assets/utils/constants";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";

const SubscriptionPayment = ({ subscription, group, refresh, setRefresh }) => {

    const [modalAddOpen, setModalAddOpen] = React.useState(false);
    const [error, setError] = React.useState();
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const { token } = useContext(AuthContext);
    const [newPayment, setNewPayment] = React.useState({
        date: dayjs().format('YYYY-MM-DD'),
        amount: subscription.totalAmount,
        subscriptionId: subscription.id
    });

    const handleCreate = () => {
        setButtonLoading(true);
        setError(null);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/payments', newPayment, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                setModalAddOpen(false);
                setButtonLoading(false);
                toast.success("Le paiement a bien été ajouté", toastOptions);
            })
            .catch((error) => {
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    return (
        <div className='view-container scrollable'>
            <div className='view-subtitle euro'>
                <h2>Paiements</h2>
                <button className='main-button add' onClick={() => setModalAddOpen(true)}>Ajouter un paiement</button>
            </div>
            <div className='form-info-container'>
                <PaymentRecap
                    isPublic={false}
                    subscription={subscription}
                    refreshSubscription={refresh}
                    setRefreshSubscription={setRefresh}
                    group={group}
                />
            </div>
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter un paiement'
                         className="no-padding">
                <div className='view-subtitle flex-start'>
                    <h2 className='margin-right'>Informations du paiement</h2>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Libellé du paiement *"
                            type="text"
                            error={error && error.missingFields && error.missingFields.includes('label')}
                            value={newPayment.label}
                            onChange={(e) => setNewPayment({...newPayment, label: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomSearchInput
                            placeholder="Statut *"
                            entity={constants.paymentStatus}
                            className={'public ' + (error && error.missingFields && error.missingFields.includes('status') ? 'error' : '')}
                            value={constants.paymentStatus.find(s => s.value === newPayment.status)}
                            onChange={(value) => setNewPayment({...newPayment, status: value.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label='Date de paiement *'
                            format='DD/MM/YYYY'
                            value={dayjs(newPayment.date)}
                            onChange={(date) => setNewPayment({
                                ...newPayment,
                                date: dayjs(date).format('YYYY-MM-DD')
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomSearchInput
                            placeholder="Moyen de paiement *"
                            labelType="group"
                            entity="payment-modes"
                            className={'public ' + (error && error.missingFields && error.missingFields.includes('paymentModeId') ? 'error' : '')}
                            value={newPayment.paymentModeId}
                            valueIsId
                            onChange={(value) => setNewPayment({...newPayment, paymentModeId: value ? value.id : null})}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Montant *"
                            type="number"
                            error={error && error.missingFields && error.missingFields.includes('amount')}
                            value={newPayment.amount}
                            onChange={(e) => setNewPayment({...newPayment, amount: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {error ? (
                            <div className='error-message flex-column align-items-start justify-content-center'>
                                {error.details && error.details.map((detail, index) => (
                                    <span key={index}>- {detail}</span>
                                ))}
                            </div>
                        ) : null}
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button without-icon margin-right'
                            onClick={() => setModalAddOpen(false)}>Annuler
                    </button>
                    <button className='main-button blue-button launch' onClick={handleCreate}
                            disabled={buttonLoading}>{buttonLoading ?
                        <CircularProgress className='white-loader' size={20}/> : 'Ajouter le paiement'}</button>
                </div>
            </CustomModal>
        </div>
    );
}

export default SubscriptionPayment;