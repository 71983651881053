import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import CustomTag from "../../custom/CustomTag";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import constants from "../../../assets/utils/constants";
import CustomSearchInput from "../../custom/CustomSearchInput";

const DiscountSettings = () => {

    const [discounts, setDiscounts] = useState([]);
    const [newDiscount, setNewDiscount] = useState({ isActive: true });
    const [editedDiscount, setEditedDiscount] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'discounts', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setDiscounts(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaded(true);
            });
    }, [refresh]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'discounts', newDiscount, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalAddOpen(false);
                setRefresh(refresh + 1);
                toast.success("La réduction a bien été ajoutée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'discounts/' + editedDiscount.id, editedDiscount, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalEditOpen(false);
                setRefresh(refresh + 1);
                toast.success("Le moyen de paiement a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleDelete = (discountId) => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'discounts/' + discountId, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                toast.success("Le moyen de paiement a bien été supprimé", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div>
            <div className='view-subtitle discount'>
                <h2>Configuration des réductions</h2>
            </div>
            { discounts.length > 0 ? (
                <div className='entity-add-container'>
                    { discounts.map((discount, index) => (
                        <div className='training-edit-block' key={index} >
                            <div className='entity-with-description'>
                                <div className='flex-view'>
                                    <div className='view-subtitle discount margin-right'>
                                        <h2>{discount.name}</h2>
                                    </div>
                                    <CustomTag value={`- ${discount.value} ${discount.type === 'amount' ? '€' : '%'}`} />
                                    { discount.isFunded ? <CustomTag value='Subventionnée' className='active margin-left' /> : null }
                                </div>
                            </div>
                            <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                <React.Fragment>
                                    <div className='tooltip-container'>
                                        <span className='edit' onClick={() => {
                                            setEditedDiscount(discount);
                                            setModalEditOpen(true);
                                        }}>Modifier</span>
                                        <span className='delete' onClick={() => handleDelete(discount.id)}>Supprimer</span>
                                    </div>
                                </React.Fragment>
                            }>
                                <IconButton aria-label="delete" size="small">
                                    <MoreVertRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ))}
                    <div className='training-edit-block'>
                        <button className='add-training-button' onClick={() => setModalAddOpen(true)}>Ajouter un moyen de paiement</button>
                    </div>
                </div>
            ) : (
                <div className='training-edit-block'>
                    <button className='add-training-button' onClick={() => setModalAddOpen(true)}>Ajouter une réduction</button>
                </div>
            )}
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter une réduction'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            label="Nom de la réduction"
                            variant="outlined"
                            onChange={(e) => setNewDiscount({...newDiscount, name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSearchInput
                            placeholder='Type de réduction'
                            entity={constants.discountTypes}
                            onChange={newValue => setNewDiscount({...newDiscount, type: newValue.value})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            type="number"
                            label="Valeur"
                            variant="outlined"
                            onChange={(e) => setNewDiscount({...newDiscount, value: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className='training-selector-block no-margin-bottom flex-view'>
                            <label className="checkbox-container">
                                <input type="checkbox" checked={newDiscount.isFunded} onClick={(e) => setNewDiscount({...newDiscount, isFunded: e.target.checked})}/>
                                <span className="checkmark"/>
                            </label>
                            <span className='small-note'>Cette réduction est subventionnée</span>
                        </div>
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button' onClick={handleCreate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Ajouter'}</button>
                </div>
            </CustomModal>
            <CustomModal show={modalEditOpen} setShow={setModalEditOpen} title='Modifier une réduction'>
                { editedDiscount ? (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    label="Nom de la réduction"
                                    variant="outlined"
                                    value={editedDiscount.name}
                                    onChange={(e) => setEditedDiscount({...editedDiscount, name: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSearchInput
                                    placeholder='Type de réduction'
                                    entity={constants.discountTypes}
                                    value={constants.discountTypes.find(d => d.value === editedDiscount.type)}
                                    onChange={newValue => setEditedDiscount({...editedDiscount, type: newValue.value})}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    type="number"
                                    label="Valeur"
                                    variant="outlined"
                                    value={editedDiscount.value}
                                    onChange={(e) => setEditedDiscount({...editedDiscount, value: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <div className='training-selector-block no-margin-bottom flex-view'>
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={editedDiscount.isFunded} onClick={(e) => setEditedDiscount({...editedDiscount, isFunded: e.target.checked})}/>
                                        <span className="checkmark"/>
                                    </label>
                                    <span className='small-note'>Cette réduction est subventionnée</span>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='modal-bottom-button'>
                            <button className='main-button blue-button edit' onClick={handleUpdate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier'}</button>
                        </div>
                    </div>
                ) : null }
            </CustomModal>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default DiscountSettings;