import React from "react";
import {Grid, TextField} from "@mui/material";

const IntegrityCheck = ({ licence, setLicence, error }) => {

    return (
        <div>
            <Grid container spacing={2} className="margin-bottom-15">
                <Grid item xs={6}>
                    <TextField
                        label="Nom d'usage"
                        variant="outlined"
                        size="small"
                        error={error && error.missingFields && error.missingFields.includes('commonLastName')}
                        defaultValue={licence.integrityCheck.commonLastName}
                        onChange={(e) => setLicence({
                            ...licence,
                            integrityCheck: {...licence.integrityCheck, commonLastName: e.target.value}
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Prénom d'usage"
                        variant="outlined"
                        size="small"
                        error={error && error.missingFields && error.missingFields.includes('commonFirstName')}
                        defaultValue={licence.integrityCheck.commonFirstName}
                        onChange={(e) => setLicence({
                            ...licence,
                            integrityCheck: {...licence.integrityCheck, commonFirstName: e.target.value}
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nom de naissance"
                        variant="outlined"
                        size="small"
                        error={error && error.missingFields && error.missingFields.includes('birthLastName')}
                        value={licence.integrityCheck.birthLastName}
                        onChange={(e) => setLicence({
                            ...licence,
                            integrityCheck: {...licence.integrityCheck, birthLastName: e.target.value}
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Prénom de naissance"
                        variant="outlined"
                        size="small"
                        error={error && error.missingFields && error.missingFields.includes('birthFirstName')}
                        value={licence.integrityCheck.birthFirstName}
                        onChange={(e) => setLicence({
                            ...licence,
                            integrityCheck: {...licence.integrityCheck, birthFirstName: e.target.value}
                        })}
                    />
                </Grid>
            </Grid>
            <div className="training-selector-block margin-top">
                <label className="checkbox-container">
                    <input type="checkbox" onClick={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, bornAbroad: e.target.checked }})}/>
                    <span className="checkmark"/>
                </label>
                <span className='small-note'>Je suis né(e) à l'étranger</span>
            </div>
            <Grid container spacing={2} className="margin-bottom-15">
                { licence.integrityCheck.bornAbroad ? (
                    <>
                        <Grid item xs={6} key='birthCountry'>
                            <TextField
                                label="Pays de naissance"
                                variant="outlined"
                                size="small"
                                error={error && error.missingFields && error.missingFields.includes('birthCountry')}
                                value={licence.integrityCheck.birthCountry}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, birthCountry: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Commune de naissance"
                                variant="outlined"
                                size="small"
                                value={licence.integrityCheck.birthCity}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, birthCity: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={6} key='motherLastName'>
                            <TextField
                                label="Nom de la mère (facultatif)"
                                variant="outlined"
                                size="small"
                                value={licence.integrityCheck.motherLastName}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, motherLastName: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Prénom de la mère (facultatif)"
                                variant="outlined"
                                size="small"
                                value={licence.integrityCheck.motherFirstName}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, motherFirstName: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Nom du père (facultatif)"
                                variant="outlined"
                                size="small"
                                value={licence.integrityCheck.fatherLastName}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, fatherLastName: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Prénom du père (facultatif)"
                                variant="outlined"
                                size="small"
                                value={licence.integrityCheck.fatherFirstName}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, fatherFirstName: e.target.value}})}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="Département de naissance"
                                variant="outlined"
                                size="small"
                                error={error && error.missingFields && error.missingFields.includes('birthDepartment')}
                                value={licence.integrityCheck.birthDepartment}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, birthDepartment: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Commune de naissance"
                                variant="outlined"
                                size="small"
                                error={error && error.missingFields && error.missingFields.includes('birthCity')}
                                value={licence.integrityCheck.birthCity}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, birthCity: e.target.value}})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Arrondissement (si besoin)"
                                variant="outlined"
                                size="small"
                                value={licence.integrityCheck.birthDistrict}
                                onChange={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, birthDistrict: e.target.value}})}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <div className="training-selector-block margin-top no-margin-bottom">
                <label className="checkbox-container">
                    <input type="checkbox" onClick={(e) => setLicence({...licence, integrityCheck: {...licence.integrityCheck, agreed: e.target.checked }})}/>
                    <span className="checkmark"/>
                </label>
                <span className={`small-note ${error && error.missingFields && error.missingFields.includes('agreed') ? 'error' : ''}`}>J'ai compris l'objet de ce contrôle</span>
            </div>
        </div>
    );
}

export default IntegrityCheck;