import React from 'react';

const CustomModal = ({title, children, show, setShow, minHeight, className}) => {

    return (
        <div className={`custom-modal ${className} ${(show ? '' : 'hidden')}` }>
            <div className='custom-modal-container'>
                <div className='custom-modal-header'>
                    <h1>{title ? title : 'Modal'}</h1>
                    <button className='modal-close' onClick={() => setShow(false)}>+</button>
                </div>
                <div className='custom-modal-content' style={{ minHeight }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default CustomModal;