import {CircularProgress, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import sortIcon from "../../../assets/icons/grip-dots--gray.svg";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import CustomSearchInput from "../../custom/CustomSearchInput";
import Switch from "@mui/material/Switch";
import CustomTag from "../../custom/CustomTag";
import constants from "../../../assets/utils/constants";
import CustomRichText from "../../custom/CustomRichText";
import GroupChooser from "../../custom/GroupChooser";
import CustomWarningModal from "../../custom/CustomWarningModal";
import DeleteIcon from '@mui/icons-material/Delete';
import {SortingContainer} from "../../custom/SortingContainer";

const FieldSettings = ({groups}) => {

    const [fields, setFields] = useState([]);
    const [newField, setNewField] = useState({ options: [] });
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalWarningOpen, setModalWarningOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editedField, setEditedField] = useState();
    const [selectedField, setSelectedField] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const [newDropdownItem, setNewDropdownItem] = useState('');

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'fields', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setFields(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaded(true);
            });
    }, [refresh]);

    const handleCreate = () => {
        setButtonLoading(true);
        setModalAddOpen(false);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'fields', {...newField, options: newField.options.map(o => ({value: o.value}) )}, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setNewField({ options: [] });
                setRefresh(refresh + 1);
                toast.success("Le champ a bien été ajouté", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleUpdate = () => {
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'fields/' + editedField.id, {...editedField, options: editedField.options.map(o => ({ id: o.id, value: o.value}) )}, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalEditOpen(false);
                setRefresh(refresh + 1);
                toast.success("Le champ a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleDeleteWarning = fieldId => {
        setSelectedField(fieldId);
        setModalWarningOpen(true);
    }

    const handleDelete = (fieldId) => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'fields/' + fieldId, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                toast.success("Le champ a bien été supprimé", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleAddOption = (event, actionType) => {
        if(event.type === 'click' || event.key === 'Enter') {
            if (newDropdownItem !== '' && newDropdownItem !== null) {
                if(actionType === 'new') {
                    setNewField({...newField, options: [...newField.options, {id: Math.random().toString(36).substr(2, 10), value: newDropdownItem, position: 0}]});
                } else {
                    setEditedField({...editedField, options: [...editedField.options, {id: Math.random().toString(36).substr(2, 10), value: newDropdownItem, position: 0}]});
                }
                setNewDropdownItem('');
            }
        }
    }

    return isLoaded ? (
        <div>
            <div className='entity-add-container'>
                { fields.map((field, index) => (
                    <div key={index}>
                        <div className='training-edit-block'>
                            <div className='entity-with-description'>
                                <div className='entity-list-title'>
                                    <div className='view-subtitle field margin-right'>
                                        <h2>{field.label}</h2>
                                    </div>
                                    <CustomTag value={field.required ? 'Obligatoire' : 'Non obligatoire'} className={field.required ? 'waiting-validation' : 'active'} />
                                    <CustomTag value={field.isPublic ? 'Public' : 'Privé'} className={'margin-left ' + (field.isPublic ? 'active' : 'inactive')} />
                                    <CustomTag value={`${field.groups.length} groupe(s)`} className={'margin-left entity-info ' + (field.groups.length === 0 ? 'inactive' : '')} />
                                </div>
                            </div>
                            <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                <React.Fragment>
                                    <div className='tooltip-container'>
                                        <span className='edit' onClick={() => {
                                            setEditedField(field);
                                            setModalEditOpen(true);
                                        }}>Modifier</span>
                                        <span className='delete' onClick={() => handleDeleteWarning(field.id)}>Supprimer</span>
                                    </div>
                                </React.Fragment>
                            }>
                                <IconButton aria-label="delete" size="small">
                                    <MoreVertRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <CustomWarningModal
                            content={`Toutes les valeurs saisies par les adhérents pour le champ "${fields.find(f => f.id === selectedField)?.label}" seront perdues. Souhaitez-vous continuer ?`}
                            modalOpen={modalWarningOpen}
                            setModalOpen={setModalWarningOpen}
                            confirm={() => {
                                handleDelete(selectedField);
                                setModalWarningOpen(false);
                            }}
                        />
                    </div>
                ))}
                <div className='training-edit-block'>
                    <button className='add-training-button' onClick={() => setModalAddOpen(true)}>Ajouter un champ spécifique</button>
                </div>
            </div>
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter un champ'>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            label="Nom du champ"
                            variant="outlined"
                            value={newField.label}
                            onChange={(e) => setNewField({...newField, label: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSearchInput
                            placeholder='Type de champ'
                            entity={constants.fields}
                            onChange={newValue => setNewField({...newField, type: newValue.value})}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div className='flex-view justify-content-end'>
                            <span>Public</span>
                            <Switch color="primary" checked={newField.isPublic} onChange={(e) => setNewField({...newField, isPublic: e.target.checked })} />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className='flex-view justify-content-end'>
                            <span>Obligatoire</span>
                            <Switch color="primary" checked={newField.required} onChange={(e) => setNewField({...newField, required: e.target.checked })} />
                        </div>
                    </Grid>
                    { newField.type && newField.type === 'select' ? (
                        <>
                            <Grid item xs={5}>
                                <TextField
                                    onKeyDown={(event) => handleAddOption(event, 'new')}
                                    size="small"
                                    label="Ajoutez des éléments"
                                    variant="outlined"
                                    value={newDropdownItem}
                                    onChange={(e) => setNewDropdownItem(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <button className='main-button blue-button' onClick={(event) => handleAddOption(event, 'new')}>Ajouter</button>
                            </Grid>
                            { newField.options.length > 0 ? (
                                <Grid item xs={12}>
                                    <div className='options-sorting-container'>
                                        <SortingContainer onCardDrop={(cards) => setNewField({...newField, options: cards })} cards={
                                            newField.options.map((item, index) => ({...item, content: (
                                                    <div key={index} className='options-item'>
                                                        <img src={sortIcon} width={14} alt='sort' />
                                                        <CustomTag value={item.value} />
                                                        <IconButton aria-label="Supprimer" size="small" onClick={() => {
                                                            const updatedItems = [...newField.options];
                                                            updatedItems.splice(index, 1);
                                                            setNewField({...newField, options: updatedItems});
                                                        }}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                )
                                            }))
                                        } />
                                    </div>
                                </Grid>
                            ) : null }
                        </>
                    ) : null }
                    <Grid item xs={12}>
                        <CustomRichText
                            value={newField.description}
                            setValue={(newValue) => setNewField({...newField, description: newValue})}
                            placeholder='Entrez une description (facultatif)'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GroupChooser
                            groups={groups}
                            selectedGroups={newField.groups ? newField.groups : []}
                            setSelectedGroups={newGroups => setNewField({...newField, groups: newGroups })}
                        />
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button' onClick={handleCreate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Ajouter'}</button>
                </div>
            </CustomModal>
            <CustomModal show={modalEditOpen} setShow={setModalEditOpen} title='Modifier un champ'>
                { editedField ? (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    label="Nom du champ"
                                    variant="outlined"
                                    value={editedField.label}
                                    onChange={(e) => setEditedField({...editedField, label: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomSearchInput
                                    placeholder='Type de champ'
                                    entity={constants.fields}
                                    value={constants.fields.find(field => editedField.type === field.value )}
                                    onChange={newValue => setEditedField({...editedField, type: newValue.value})}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className='flex-view justify-content-end'>
                                    <span>Public</span>
                                    <Switch color="primary" checked={editedField.isPublic} onChange={(e) => setEditedField({...editedField, isPublic: e.target.checked })} />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className='flex-view justify-content-end'>
                                    <span>Obligatoire</span>
                                    <Switch color="primary" checked={editedField.required} onChange={(e) => setEditedField({...editedField, required: e.target.checked })} />
                                </div>
                            </Grid>
                            { editedField.type && editedField.type === 'select' ? (
                                <>
                                    <Grid item xs={5}>
                                        <TextField
                                            onKeyDown={(event) => handleAddOption(event, 'edit')}
                                            size="small"
                                            label="Ajoutez des éléments"
                                            variant="outlined"
                                            value={newDropdownItem}
                                            onChange={(e) => setNewDropdownItem(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <button className='main-button blue-button' onClick={(event) => handleAddOption(event, 'edit')}>Ajouter</button>
                                    </Grid>
                                    { editedField.options.length > 0 ? (
                                        <Grid item xs={12}>
                                            <div className='options-sorting-container'>
                                                <SortingContainer onCardDrop={(cards) => setEditedField({...editedField, options: cards })} cards={
                                                    editedField.options.map((item, index) => ({...item, content: (
                                                            <div key={index} className='options-item'>
                                                                <img src={sortIcon} width={14} alt='sort' />
                                                                <CustomTag value={item.value} />
                                                                <IconButton aria-label="Supprimer" size="small" onClick={() => {
                                                                    const updatedItems = [...editedField.options];
                                                                    updatedItems.splice(index, 1);
                                                                    setEditedField({...editedField, options: updatedItems});
                                                                }}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </div>
                                                        )}))
                                                } />
                                            </div>
                                        </Grid>
                                    ) : null }
                                </>
                            ) : null }
                            <Grid item xs={12}>
                                <CustomRichText
                                    value={editedField.description}
                                    setValue={(newValue) => setEditedField({...editedField, description: newValue})}
                                    placeholder='Entrez une description (facultatif)'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GroupChooser
                                    groups={groups}
                                    selectedGroups={editedField.groups ? editedField.groups : []}
                                    setSelectedGroups={newGroups => setEditedField({...editedField, groups: newGroups })}
                                />
                            </Grid>
                        </Grid>
                        <div className='modal-bottom-button'>
                            <button className='main-button blue-button edit' onClick={handleUpdate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier'}</button>
                        </div>
                    </div>
                ) : null }
            </CustomModal>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default FieldSettings;