import CustomTag from "../../components/custom/CustomTag";
import dayjs from "dayjs";
import React from "react";

const columns = {

    groupWaitingSubscriptions: [
        {
            Header: 'Prénom',
            accessor: 'profile.firstName',
            icon: 'user',
        },
        {
            Header: 'Nom',
            accessor: 'profile.lastName',
            icon: 'user',
        },
        {
            Header: 'Statut',
            accessor: 'status',
            icon: 'status',
            Cell: ({cell: {value}}) => <CustomTag value={value}/>
        },
        {
            Header: 'Dépôt',
            accessor: 'createdAt',
            icon: 'date',
            Cell: ({cell: {value}}) => <span>{dayjs(value).format('DD MMMM YYYY à HH:mm')}</span>
        }
    ],

    waitingSubscriptions: [
        {
            Header: 'Prénom',
            accessor: 'profile.firstName',
            icon: 'user',
        },
        {
            Header: 'Nom',
            accessor: 'profile.lastName',
            icon: 'user',
        },
        {
            Header: 'Groupe',
            accessor: 'group.name',
            icon: 'group'
        },
        {
            Header: 'Statut',
            accessor: 'status',
            icon: 'status',
            Cell: ({cell: {value}}) => <CustomTag value={value}/>
        },
        {
            Header: 'Dépôt',
            accessor: 'createdAt',
            icon: 'date',
            Cell: ({cell: {value}}) => <span>{dayjs(value).format('DD MMMM YYYY à HH:mm')}</span>
        }
    ]

}

export default columns;