import button from "bootstrap/js/src/button";
import {Link} from "react-router-dom";
import React from "react";

const CustomerInfo = ({customer}) => {

    return (
        <div className='customer-info-container'>
            <div className='form-info-container'>
                <h1>Bienvenue, {customer.firstName}</h1>
                <span>Retrouvez ici toutes vos informations, vos profils et vos adhésions.</span>
            </div>
            <Link to='/groups'>
                <button className='main-button blue-button submit-subcription'>M'inscrire à un groupe</button>
            </Link>
        </div>
    )
}

export default CustomerInfo;