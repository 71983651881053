import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import Select from "react-select";
import DefaultCustomOption from "./CustomOption";
import CustomSingleValue from "./CustomSingleValue";
import constants from "../../assets/utils/constants";
import dayjs from "dayjs";

const CustomSearchInput = (
    {
        placeholder,
        entity,
        isMultiSearch,
        labelType,
        onChange,
        className,
        value,
        CustomOption,
        isSearchable = true,
        valueIsId = false,
        disabled = false,
        isClearable = false
    }) => {

    const [items, setItems] = useState([]);
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        if(typeof entity === "string") {
            axios.get(process.env.REACT_APP_ADMIN_API_URL + entity, { headers: { Authorization: 'Bearer ' + token }})
                .then((response) => {
                    setItems(formatItems(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            // In this case, entity MUST be an array
            setItems(formatItems(entity));
        }
    }, [entity, token]);

    const formatItems = (items) => {
        return items.map((item) => {
            switch (labelType) {
                case 'user':
                    item.value = item.id ? item.id : item.lastName;
                    item.label = item.firstName + ' ' + item.lastName;
                    break;
                case 'address':
                    item.label = item.address;
                    item.value = item.id;
                    break;
                case 'group':
                    item.label = item.name;
                    break;
                case 'discount':
                    item.label = `${item.name} (- ${item.value} ${item.type === 'amount' ? '€' : '%'})`;
                    break;
                case 'option-discount':
                    item.label = `${item.name} (${item.dataType === 'option' ? '+' : '-'} ${item.value} ${item.dataType === 'discount' ? (item.type === 'amount' ? '€' : '%') : '€'})`;
                    break;
                case 'pool':
                    item.label = item.name;
                    break;
                case 'training':
                    item.label = `${constants.weekDays.find(c => c.value === item.weekDay).label} de ${dayjs(item.startHour, 'HH:mm:ss').format('HH:mm')} à ${dayjs(item.stopHour, 'HH:mm:ss').format('HH:mm')}`;
                    break;
                case 'custom-field':
                    item.label = item.value;
                    break;
                case 'paymentMode':
                    item.label = item.value;
                    break;
                case 'season':
                    item.label = item.name;
                    item.value = item.id;
                    item.isSeason = true;
                    break;
                default:
                    break;
            }
            return item;
        });
    }

    return (
        <Select
            isDisabled={disabled}
            isMulti={isMultiSearch}
            isSearchable={isSearchable}
            name="colors"
            isRequired={true}
            options={items}
            isClearable={isClearable}
            onChange={onChange}
            isOptionSelected={(option, selectValue) => selectValue.find(selectOption => selectOption.value === option.value || selectOption.id === option.id)}
            value={valueIsId ? (value ? items.find(i => i.id === value) : null) : value}
            // menuIsOpen={true}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 99999999 }) }}
            className={ (isMultiSearch ? "basic-multi-select multi-search-input " : "basic-single single-search-input ") + className}
            classNamePrefix="select"
            placeholder={placeholder}
            components={{ Option: CustomOption ? CustomOption : DefaultCustomOption, SingleValue: CustomSingleValue }}
        />
    )

}

export default CustomSearchInput;