import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const CustomRichText = ({value, setValue, placeholder, readMode = false, className, hideToolbar = false}) => {

    const modules = {
        toolbar: !hideToolbar ? [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'},
                {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ] : null
    };

    const formats = ['bold', 'font', 'italic', 'link', 'size', 'strike', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'formula'];

    return (
        <div className={`rich-text-container ${readMode ? 'toolbar-hidden' : ''}`}>
            <ReactQuill
                theme={readMode ? 'bubble' : 'snow'}
                value={value}
                className={className}
                preserveWhitespace
                onChange={(content) => {
                    if(!readMode) {
                        if (content === '<p><br></p>') {
                            setValue(null);
                        } else {
                            setValue(content);
                        }
                    }
                }}
                formats={formats}
                modules={modules}
                readOnly={readMode}
                placeholder={placeholder ? placeholder : 'Entrez votre texte'}
            />
        </div>
    );
}

export default CustomRichText;