import {CircularProgress, Grid, TextField} from "@mui/material";
import React, {useContext, useState} from "react";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import CustomSearchInput from "../../custom/CustomSearchInput";
import CustomTag from "../../custom/CustomTag";
import constants from "../../../assets/utils/constants";
import CustomList from "../../custom/CustomList";

const UserSettings = () => {

    const [newUser, setNewUser] = useState({});
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Prénom',
                accessor: 'firstName',
                icon: 'user'
            }, {
                Header: 'Nom',
                accessor: 'lastName',
                icon: 'user'
            }, {
                Header: 'Email',
                accessor: 'email',
                icon: 'email'
            }, {
                Header: 'Statut',
                accessor: 'status',
                icon: 'status',
                Cell: ({cell: {value}}) => <CustomTag value={value} />
            }, {
                Header: 'Rôle',
                accessor: 'role',
                icon: 'status',
                Cell: ({cell: {value}}) => <CustomTag value={value} />
            }
        ],
        []
    );

    const handleCreate = () => {
        setButtonLoading(true);
        setModalAddOpen(false);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'users', newUser, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setNewUser({});
                setRefresh(refresh + 1);
                toast.success("L'utilisateur a bien été ajouté", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className='view-subtitle user'>
                <h2>Liste des membres du bureau</h2>
                <button className='main-button add margin-right' onClick={() => setModalAddOpen(true)}>Ajouter un utilisateur</button>
            </div>
            <CustomList
                entity='users'
                columns={columns}
                isSelectable={false}
                intermediatePath='../users'
            />
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter un utilisateur'>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Prénom"
                            variant="outlined"
                            onChange={(e) => setNewUser({...newUser, firstName: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Nom"
                            variant="outlined"
                            onChange={(e) => setNewUser({...newUser, lastName: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSearchInput
                            className='public'
                            entity={constants.roles}
                            placeholder='Rôle'
                            onChange={newRole => setNewUser({...newUser, role: newRole.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="password"
                            label="Mot de passe"
                            variant="outlined"
                            onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                        />
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button' onClick={handleCreate}
                            disabled={buttonLoading}>{buttonLoading ?
                        <CircularProgress className='white-loader' size={20}/> : 'Ajouter'}</button>
                </div>
            </CustomModal>
        </div>
    );
}

export default UserSettings;