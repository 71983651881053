import React, {useContext, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import CustomSearchInput from "../../custom/CustomSearchInput";
import CloseIcon from '@mui/icons-material/Close';
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import CustomWarningModal from "../../custom/CustomWarningModal";
import constants from "../../../assets/utils/constants";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";

const PaymentRecap = ({ subscription, refreshSubscription, setRefreshSubscription, group, isPublic = false }) => {

    const { token } = useContext(AuthContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [newOptionOrDiscount, setNewOptionOrDiscount] = useState();
    const [selectedPayment, setSelectedPayment] = useState();
    const [modalWarningOpen, setModalWarningOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editedPayment, setEditedPayment] = useState();
    const [error, setError] = useState();

    const formatAmount = (amount) => {
        return Number(amount).toLocaleString('fr-FR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    const handleDeleteWarning = paymentId => {
        setSelectedPayment(paymentId);
        setModalWarningOpen(true);
    }

    const addOptionOrDiscount = () => {
        if(newOptionOrDiscount) {
            setButtonLoading(true);
            if(newOptionOrDiscount.dataType === 'option') {
                axios.post(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/options',{ value: newOptionOrDiscount.value, optionId: newOptionOrDiscount.id, subscriptionId: subscription.id }, { headers: { Authorization: 'Bearer ' + token }})
                    .then(() => {
                        setButtonLoading(false);
                        setRefreshSubscription(refreshSubscription + 1);
                        toast.success("L'option a bien été ajoutée", toastOptions);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error("Une erreur est survenue", toastOptions);
                        setButtonLoading(false);
                    });
            } else {
                axios.post(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/discounts',{ value: newOptionOrDiscount.value, type: newOptionOrDiscount.type, discountId: newOptionOrDiscount.id, subscriptionId: subscription.id }, { headers: { Authorization: 'Bearer ' + token }})
                    .then(() => {
                        setButtonLoading(false);
                        setRefreshSubscription(refreshSubscription + 1);
                        toast.success("La réduction a bien été ajoutée", toastOptions);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error("Une erreur est survenue", toastOptions);
                        setButtonLoading(false);
                    });
            }
        }
    }

    const deleteDiscount = discountId => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + `subscriptions/discounts/${discountId}`, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefreshSubscription(refreshSubscription + 1);
                toast.success("La réduction a bien été retirée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    const deleteOption = optionId => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + `subscriptions/options/${optionId}`, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefreshSubscription(refreshSubscription + 1);
                toast.success("L'option a bien été retirée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    const deletePayment = (paymentId) => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/payments/' + paymentId, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefreshSubscription(refreshSubscription + 1);
                toast.success("Le paiement a bien été supprimé", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const updatePayment = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/payments/' + editedPayment.id, editedPayment, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalEditOpen(false);
                setRefreshSubscription(refreshSubscription + 1);
                toast.success("Le paiement a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    return (
        <div className='subscription-payment-recap'>
            <div className='subscription-payment-details'>
                <div className='subscription-payment-title'>
                    { group.trainingsType === 'optional' ? (
                        <>
                            <span className='title'>{subscription.group.name} : {subscription.trainings.length} séance(s)</span>
                            <span className='price'>{formatAmount(group.rates.find(rate => rate.allowedNb === subscription.trainings.length).price)} €</span>
                        </>

                    ) : (
                        <>
                            <span className='title'>{subscription.group.name} : {group.trainings.length} séance(s)</span>
                            <span className='price'>{formatAmount(group.rates[0].price)} €</span>
                        </>
                    )}
                </div>
                <div className='subscription-payment-lines'>
                    { subscription.options.length > 0 && subscription.options.map(option => (
                        <div className="subscription-payment-line">
                            <div className='discount-line'>
                                <span className='title margin-right'>Option : {option.option.name}</span>
                                {!isPublic ? (
                                    <IconButton aria-label="delete" size="small"
                                                onClick={() => deleteOption(option.id)}>
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                ) : null}
                            </div>
                            <span className='price'>+ {formatAmount(option.value)} €</span>
                        </div>
                    ))}
                    { subscription.discounts.filter(d => d.type === 'amount').length > 0 && subscription.discounts.filter(d => d.type === 'amount').map(discount => (
                        <div className="subscription-payment-line">
                            <div className='discount-line'>
                                <span className='title margin-right'>Réduction : {discount.discount.name}</span>
                                {!isPublic ? (
                                    <IconButton aria-label="delete" size="small"
                                                onClick={() => deleteDiscount(discount.id)}>
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                ) : null}
                            </div>
                            <span className='price'>- {formatAmount(discount.value)} {discount.discount.type === 'amount' ? '€' : '%'}</span>
                        </div>
                    ))}
                    { subscription.discounts.filter(d => d.type === 'percentage').length > 0 && subscription.discounts.filter(d => d.type === 'percentage').map(discount => (
                        <div className="subscription-payment-line">
                            <div className='discount-line'>
                                <span className='title margin-right'>Réduction : {discount.discount.name}</span>
                                {!isPublic ? (
                                    <IconButton aria-label="delete" size="small"
                                                onClick={() => deleteDiscount(discount.id)}>
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                ) : null}
                            </div>
                            <span className='price'>- {formatAmount(discount.value)} {discount.discount.type === 'amount' ? '€' : '%'}</span>
                        </div>
                    ))}
                    { !isPublic ? (
                        <div className='discount-add-container'>
                            <CustomSearchInput
                                entity={`seasons/${group.seasonId}/options-discounts`}
                                placeholder='Ajouter une option ou une réduction'
                                labelType='option-discount'
                                onChange={(newValue) => setNewOptionOrDiscount(newValue)}
                            />
                            <button className='main-button add' onClick={addOptionOrDiscount} disabled={buttonLoading}>{buttonLoading ? <CircularProgress size={10}/> : 'Ajouter'}</button>
                        </div>
                    ) : null }
                </div>
                <div className='subscription-payment-total'>
                    <span className='title total'>Total de l'adhésion</span>
                    <span className='price total'>{formatAmount(subscription.totalAmount)} €</span>
                </div>
                { !isPublic ? (
                    <>
                        <div className='subscription-payment-lines'>
                            <div className="subscription-payment-line">
                                <span className='title'>Méthode de paiement choisie</span>
                                <span className='price'>{subscription.paymentMode.name}</span>
                            </div>
                            { subscription.payments.length > 0 ? subscription.payments.map((payment, index) => (
                                <div className="subscription-payment-registered" key={index}>
                                    <div className='flex-view justify-content-between'>
                                        <div className='flex-view gap-2'>
                                            <span className='title'>{payment.label}</span>
                                            <CustomTag value={payment.status}/>
                                            <div>
                                                <Tooltip leaveDelay={800} placement="bottom" id='options-tooltip' title={
                                                    <React.Fragment>
                                                        <div className='tooltip-container'>
                                                                <span className='edit' onClick={() => {
                                                                    setEditedPayment(payment);
                                                                    setModalEditOpen(true);
                                                                }}>Modifier</span>
                                                            <span className='delete' onClick={() => handleDeleteWarning(payment.id)}>Supprimer</span>
                                                        </div>
                                                    </React.Fragment>
                                                }>
                                                    <IconButton aria-label="delete" size="small">
                                                        <MoreVertRoundedIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <span className='price'>{formatAmount(payment.amount)} €</span>
                                    </div>
                                    <div className='details flex-view justify-content-between'>
                                        <span className='title'>Mode de paiement : {payment.paymentMode.name}</span>
                                        <span className='title'>{dayjs(payment.date).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                            )) : null }
                        </div>
                        <div className='subscription-payment-total paid'>
                            <span className='title total'>Reste à payer</span>
                            <span className='price total'>{formatAmount(subscription.totalAmount - subscription.amountPaid)} €</span>
                        </div>
                        { editedPayment ? (
                            <CustomModal show={modalEditOpen} setShow={setModalEditOpen} title='Modifier le paiement' className="no-padding">
                                <div className='view-subtitle flex-start'>
                                    <h2 className='margin-right'>Informations du paiement</h2>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Libellé du paiement *"
                                            type="text"
                                            error={error && error.missingFields && error.missingFields.includes('label')}
                                            value={editedPayment.label}
                                            onChange={(e) => setEditedPayment({...editedPayment, label: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <CustomSearchInput
                                            placeholder="Statut *"
                                            entity={constants.paymentStatus}
                                            className={'public ' + (error && error.missingFields && error.missingFields.includes('status') ? 'error' : '')}
                                            value={constants.paymentStatus.find(s => s.value === editedPayment.status)}
                                            onChange={(value) => setEditedPayment({...editedPayment, status: value.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DatePicker
                                            label='Date de paiement *'
                                            format='DD/MM/YYYY'
                                            value={dayjs(editedPayment.date)}
                                            onChange={(date) => setEditedPayment({
                                                ...editedPayment,
                                                date: dayjs(date).format('YYYY-MM-DD')
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <CustomSearchInput
                                            placeholder="Moyen de paiement *"
                                            labelType="group"
                                            entity="payment-modes"
                                            className={'public ' + (error && error.missingFields && error.missingFields.includes('paymentModeId') ? 'error' : '')}
                                            value={editedPayment.paymentModeId}
                                            valueIsId
                                            onChange={(value) => setEditedPayment({...editedPayment, paymentModeId: value ? value.id : null})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Montant *"
                                            type="number"
                                            error={error && error.missingFields && error.missingFields.includes('amount')}
                                            value={editedPayment.amount}
                                            onChange={(e) => setEditedPayment({...editedPayment, amount: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {error ? (
                                            <div className='error-message flex-column align-items-start justify-content-center'>
                                                {error.details && error.details.map((detail, index) => (
                                                    <span key={index}>- {detail}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                <div className='modal-bottom-button'>
                                    <button className='main-button without-icon margin-right' onClick={() => setModalEditOpen(false)}>Annuler</button>
                                    <button className='main-button blue-button launch' onClick={updatePayment} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20}/> : 'Modifier le paiement'}</button>
                                </div>
                            </CustomModal>
                        ) : null }
                        <CustomWarningModal
                            content="Souhaitez vous vraiment supprimer ce paiement ? Cette action est irréversible."
                            modalOpen={modalWarningOpen}
                            setModalOpen={setModalWarningOpen}
                            confirm={() => {
                                deletePayment(selectedPayment);
                                setModalWarningOpen(false);
                            }}
                        />
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default PaymentRecap;