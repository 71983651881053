import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TextField} from "@mui/material";
import dayjs from "dayjs";
import CustomSearchInput from "./CustomSearchInput";

const CustomField = ({ field, setField, error = false, size = 'normal', readOnly = false, value }) => {

    switch (field.type) {
        case 'date':
            return (
                <DatePicker
                    format='DD/MM/YYYY'
                    slotProps={{
                        textField: { error, size }
                    }}
                    value={value ? dayjs(value) : null}
                    readOnly={readOnly}
                    label={field.label + (field.required ? ' *' : '')}
                    onChange={(newDate) => setField(field, (newDate && newDate.isValid()) ? newDate.format('YYYY-MM-DD') : null)}
                />
            );

        case 'number':
            return (
                <TextField
                    type='number'
                    size={size}
                    required={field.required}
                    label={field.label}
                    error={error}
                    value={value}
                    InputProps={{ min: 0, readOnly: readOnly }}
                    onChange={(e) => setField(field, !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null )}
                />
            );

        case 'text':
            return (
                <TextField
                    required={field.required}
                    label={field.label}
                    error={error}
                    size={size}
                    value={value}
                    InputProps={{ readOnly }}
                    onChange={(e) => setField(field, e.target.value)}
                />
            );

        case 'select':
            return readOnly ? (
                <TextField
                    required={field.required}
                    label={field.label}
                    size='small'
                    value={field.options.find(o => o.id === value) ? field.options.find(o => o.id === value).value : null}
                    InputProps={{ readOnly }}
                />
            ) : (
                <CustomSearchInput
                    className='public'
                    labelType='custom-field'
                    entity={field.options}
                    onChange={(newValue) => setField(field, newValue.id)}
                    value={field.options.find(o => o.id === value) ? field.options.find(o => o.id === value) : null}
                    placeholder={field.label}
                />
            );
    }
}

export default CustomField;