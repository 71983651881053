import SubscriptionValidation from "./SubscriptionValidation";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress, IconButton, Tab, Tabs} from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CustomTag from "../../custom/CustomTag";
import SubscriptionChat from "./SubscriptionChat";
import SubscriptionPayment from "./SubscriptionPayment";
import SubscriptionLicence from "./SubscriptionLicence";

const SubscriptionView = ({ groups, appRefresh, setAppRefresh }) => {

    const { subscriptionId } = useParams();
    const [subscription, setSubscription] = useState();
    const [refreshSubscription, setRefreshSubscription] = useState(0);
    const [group, setGroup] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => setTab(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0), [location]);

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscriptionId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                setSubscription(response.data);
                setGroup(response.data.group);
                setIsLoaded(true);
            })
            .catch((error) => console.log(error));
    }, [token, subscriptionId, refreshSubscription]);

    return isLoaded ? (
        <div className='subscription-view'>
            <div className='view-header subscriptions'>
                <div className='view-title'>
                    <h1>Inscription de {subscription.profile.firstName} {subscription.profile.lastName}</h1>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                        <ArrowBackRoundedIcon fontSize="small"/>
                    </IconButton>
                </div>
            </div>
            <div>
                <Tabs value={tab} onChange={(e, newValue) => {
                    setTab(newValue);
                    setSearchParams({tab: newValue})
                }}>
                    <Tab value={0} label="Informations"/>
                    <Tab value={1} label="Licence"/>
                    <Tab value={2} label="Paiement"/>
                    <Tab value={3} label="Fil de discussion"/>
                    <Tab value={4} label="Historique"/>
                </Tabs>
            </div>
            <div role="tabpanel" hidden={tab !== 0}>
                <div className="view-container subscriptions scrollable">
                    <SubscriptionValidation
                        subscription={subscription}
                        group={group}
                        groups={groups}
                        appRefresh={appRefresh}
                        setAppRefresh={setAppRefresh}
                    />
                </div>
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <SubscriptionLicence
                    subscription={subscription}
                    tab={tab}
                />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <SubscriptionPayment
                    group={group}
                    subscription={subscription}
                    refresh={refreshSubscription}
                    setRefresh={setRefreshSubscription}
                />
            </div>
            <div role="tabpanel" hidden={tab !== 3}>
                <SubscriptionChat subscription={subscription} refresh={refreshSubscription}
                                  setRefresh={setRefreshSubscription}/>
            </div>
            <div role="tabpanel" hidden={tab !== 4}>
                <div className="view-container subscriptions scrollable">
                    <CustomTag value="En cours de développement"/>
                </div>
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default SubscriptionView;