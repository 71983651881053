import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import GroupList from "./GroupList";
import GroupView from "./GroupView";

const Groups = ({appRefresh, setAppRefresh}) => {
    return (
        <Routes>
            <Route path=':groupId' element={<GroupView appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path='/' element={<GroupList />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    );
}

export default Groups;