import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import UserView from "./UserView";
import UserEdit from "./UserEdit";

const Groups = ({ appRefresh, setAppRefresh }) => {
    return (
        <Routes>
            <Route path=':userId' element={<UserView appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path=':userId/edit' element={<UserEdit appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
            <Route path='*' element={<Navigate to={`/admin/settings/?tab=2`} />} />
        </Routes>
    );
}

export default Groups;