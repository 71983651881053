import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";
import AuthContext from "../../../contexts/AuthContext";
import CustomRichText from "../../custom/CustomRichText";
import HelpIcon from "@mui/icons-material/Help";

const GroupList = () => {

    const { club } = useContext(AuthContext);
    const [filters, setFilters] = useState([]);

    return (
        <div className='club-view-container'>
            <div className='club-info-container'>
                <div className='club-view-filters'>
                    <div className='view-subtitle season'>
                        <h2>{club.season.name}</h2>
                    </div>
                    <div className='view-subtitle categories'>
                        <h2>Catégories</h2>
                    </div>
                    <div className='categories-container'>
                        {club.season.categories.map((category, index) => (
                            <div className='category-button' key={index}>
                                <label className="checkbox-container categories" onClick={(event) => {
                                    if (event.target.checked) {
                                        setFilters([...filters, category.id]);
                                    } else {
                                        setFilters([...filters].filter(cat => cat !== category.id));
                                    }
                                }}>
                                    <input type="checkbox"/>
                                    <span className="checkmark"/>
                                    <span>{category.name}</span>
                                </label>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='club-view-categories'>
                {club.groups.filter(g => g.categories.some(c => filters.includes(c.id)) || filters.length === 0).map((group, index) => (
                    <div className='group-container' key={index}>
                        <h6>{group.name}</h6>
                        <div className='group-details'>
                            { group.description ? (
                                <CustomRichText
                                    value={group.description}
                                    setValue={() => {}}
                                    readMode
                                />
                            ) : null }
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <div className='view-subtitle calendar'>
                                        <h2>Séances</h2>
                                    </div>
                                    <div className='group-trainings'>
                                        <TableContainer>
                                            <Table size="small" aria-label="Entrainements">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Jour</TableCell>
                                                        <TableCell>Horaires</TableCell>
                                                        <TableCell className='hide-mobile'>Piscine</TableCell>
                                                        <TableCell>Places</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {group.trainings.map((training, index) => (
                                                        <TableRow key={training.name}>
                                                            <TableCell component="th" scope="row">{constants.weekDays.find(constant => constant.value === training.weekDay).label}</TableCell>
                                                            <TableCell>{dayjs(dayjs().format('YYYY-MM-DD ' + training.startHour)).format('HH:mm')} à {dayjs(dayjs().format('YYYY-MM-DD ' + training.stopHour)).format('HH:mm')}</TableCell>
                                                            <TableCell className="hide-mobile">{training.pool.name}</TableCell>
                                                            { group.trainingsType === 'mandatory' ? index === 0 ? (
                                                                <TableCell rowSpan={group.trainings.length} className='border-left'>
                                                                    <div className='flex-view'>{group.places}</div>
                                                                </TableCell>
                                                            ) : null : <TableCell>{training.places}</TableCell> }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div className='view-subtitle flex-start price'>
                                        <h2 className='margin-right'>Tarifs</h2>
                                        <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                            <React.Fragment>
                                                <CustomRichText
                                                    value="Tarif incluant 72€ de licence + adhésion"
                                                    readMode className="padding"/>
                                            </React.Fragment>
                                        }>
                                            <IconButton aria-label="delete" size="small">
                                                <HelpIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <TableContainer>
                                        <Table size="small" aria-label="Tarifs">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Séance(s)</TableCell>
                                                    <TableCell>Tarif</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            { group.trainingsType === 'optional' ? (
                                                <TableBody>
                                                    {group.rates.map((rate) => (
                                                        <TableRow key={rate.id}>
                                                            <TableCell component="th" scope="row">{rate.allowedNb} séance(s)</TableCell>
                                                            <TableCell>{rate.price}€</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {group.rates.map((rate) => (
                                                        <TableRow key={rate.id}>
                                                            <TableCell component="th" scope="row">{group.trainings.length} séance(s)</TableCell>
                                                            <TableCell>{rate.price}€</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Link to={group.id}>
                                <button className='main-button blue-button'>M'inscrire à ce groupe</button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default GroupList;