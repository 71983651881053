import React, {useState} from "react";
import {Tab, Tabs} from "@mui/material";
import PaymentSettings from "./PaymentSettings";
import FieldSettings from "./FieldSettings";
import FileSettings from "./FileSettings";
import {useSearchParams} from "react-router-dom";
import DiscountSettings from "./DiscountSettings";
import OptionSettings from "./OptionSettings";
import UserSettings from "./UserSettings";
import PlanningSettings from "./PlanningSettings";
import SeasonSettings from "./SeasonSettings";

const Settings = ({ groups, refresh, setRefresh }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0);

    return (
        <div className="settings-view">
            <div className='view-header settings'>
                <div className='view-title'>
                    <h1>Paramètres</h1>
                </div>
            </div>
            <div className='view-header-tabs'>
                <Tabs value={tab} onChange={(e, newValue) => {
                    setTab(newValue);
                    setSearchParams({tab: newValue})
                }}>
                    <Tab value={0} label="Paiements" />
                    <Tab value={1} label="Inscriptions" />
                    <Tab value={2} label="Équipe" />
                    <Tab value={3} label="Planning" />
                    <Tab value={4} label="Saisons" />
                </Tabs>
            </div>
            <div className='view-container scrollable'>
                <div role="tabpanel" hidden={tab !== 0}>
                    <PaymentSettings/>
                    <OptionSettings/>
                    <DiscountSettings/>
                </div>
                <div role="tabpanel" hidden={tab !== 1}>
                    <div className='view-subtitle field'>
                        <h2>Configuration des champs spécifiques</h2>
                    </div>
                    <FieldSettings groups={groups}/>
                    <div className='view-subtitle file'>
                        <h2>Configuration des fichiers</h2>
                    </div>
                    <FileSettings groups={groups}/>
                </div>
                <div role="tabpanel" hidden={tab !== 2}>
                    <UserSettings/>
                </div>
                <div role="tabpanel" hidden={tab !== 3}>
                    <PlanningSettings/>
                </div>
                <div role="tabpanel" hidden={tab !== 4}>
                    <SeasonSettings appRefresh={refresh} setAppRefresh={setRefresh} />
                </div>
            </div>
        </div>
    );
}

export default Settings;
