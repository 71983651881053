import React, {useContext, useMemo, useState} from 'react';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import emptyIcon from '../../assets/images/empty-list.png';
import AuthContext from "../../contexts/AuthContext";
import axios from "axios";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {CircularProgress} from "@mui/material";
import button from "bootstrap/js/src/button";

const CustomList = (
    {
        entity,
        columns,
        setSelectedColumns,
        setSelectedIds,
        refreshData,
        intermediatePath,
        isSelectable = true,
        isPaginable = true,
        emptyText,
        buttonLink
    }
) => {

    const { token } = useContext(AuthContext);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const data = useMemo(() => items, [items]);


    React.useEffect(() => {
        setIsLoaded(false);
        if(typeof entity === 'string') {
            axios.get(process.env.REACT_APP_ADMIN_API_URL + entity, { headers: { Authorization: 'Bearer ' + token }})
                .then((response) => {
                    console.log(response.data);
                    setItems(response.data);
                    setIsLoaded(true);
                })
                .catch((error) => {
                    toast.error(error.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(true);
            setItems(entity);
        }
    }, [entity, refreshData, token]);

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds }} = useTable({ columns, data, initialState: { pageSize: 20 }}, useSortBy, usePagination, useRowSelect,
        hooks => {
            isSelectable && hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        });

    React.useEffect(() => {
        if(typeof setSelectedColumns === 'function' && typeof setSelectedIds === 'function') {
            setSelectedColumns(Object.keys(selectedRowIds).length);
            setSelectedIds(selectedFlatRows.map((row) => { return row.original.id }));
            setPageSize(20);
        }

    }, [selectedRowIds, setSelectedColumns, setPageSize, selectedFlatRows, setSelectedIds]);

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <label className="checkbox-container">
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                    <span className="checkmark"/>
                </label>
            )
        }
    )

    return isLoaded ? (
        <div className={ isSelectable ? 'list-container' : 'list-container non-selectable' }>
            <table {...getTableProps()}>
                <thead>
                { headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        { headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.icon ? 'column-icon ' + column.icon : '' + (column.id === 'selection' ? 'checkbox-fixed-size' : '')}>
                                {column.render('Header')}
                                {column.isSorted ? column.isSortedDesc ? <span className='sorted' /> : <span className='sorted-desc' /> : ''}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                { items.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                    { page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{ cell.column.id === 'selection' ? cell.render('Cell') : <Link to={(intermediatePath ? (intermediatePath + '/') : '') + cell.row.original.id}>{cell.render('Cell')}</Link> }</td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                ) : (
                    <tbody>
                    <tr>
                        <td colSpan={7}>
                            <div className='empty-list-container'>
                                <img src={emptyIcon} alt='icon' width={120} />
                                <span>{emptyText ? emptyText : "Il n'y a encore aucun élément dans cette liste !"}</span>
                                { buttonLink ? (
                                    <Link to={buttonLink}>
                                        <button className="main-button blue-button plus margin-left">Ajouter</button>
                                    </Link>
                                ) : null }
                            </div>
                        </td>
                    </tr>
                    </tbody>
                )}
            </table>
            { isPaginable && items.length > 0 ? (
                <div className='pagination-container'>
                    <div className="current-page-info">
                        <span>Page <strong>{pageIndex + 1} sur {pageOptions.length}</strong></span>
                    </div>
                    <div className='page-switcher'>
                        <button className='pagination-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                        <button className='pagination-button' onClick={previousPage} disabled={!canPreviousPage}>{'<'}</button>
                        <button className='pagination-button' onClick={nextPage} disabled={!canNextPage}>{'>'}</button>
                        <button className='pagination-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                    </div>
                    <div className='rows-by-page'>
                        <span className='padding-right'>Lignes par page</span>
                        <select value={pageSize}
                                className='pagination-button select'
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}> {[20, 50, 100, 200].map(pageSize => (
                            <option key={pageSize} value={pageSize}>{pageSize}</option>
                        ))}
                        </select>
                    </div>
                </div>
            ) : null }

        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>

}

export default CustomList;