import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import AuthContext from "../../../contexts/AuthContext";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Link} from "react-router-dom";
import GroupIcon from "../groups/GroupIcon";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomWarningModal from "../../custom/CustomWarningModal";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import EventEdit from "./EventEdit";

const EventDetails = ({ eventData, open, setOpen, planning, setPlanning, groups }) => {

    const [event, setEvent] = useState(null);
    const [modalWarningOpen, setModalWarningOpen] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { club, token } = useContext(AuthContext);

    useEffect(() => {
        if(eventData) {
            axios.get(process.env.REACT_APP_ADMIN_API_URL + 'trainings/events/' + eventData.id, { headers: { Authorization: 'Bearer ' + token }})
                .then((response) => {
                    setEvent(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [eventData, token]);

    const handleDelete = () => {
        if(eventData) {
            setModalButtonLoading(true);
            setIsEditing(false);
            axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'trainings/events/' + event.id, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    setModalButtonLoading(false);
                    setOpen(false);
                    setModalWarningOpen(false);
                    setPlanning(planning.filter(e => e.id !== event.id));
                    toast.success("L'évènement a bien été supprimé", toastOptions);
                })
                .catch((error) => {
                    console.log(error);
                    setModalButtonLoading(false);
                });
        } else {
            setModalWarningOpen(false);
        }
        console.log('delete');
    }

    return (
        <div className={`training-details-container ${open ? 'open' : 'closed'}`}>
            { event ? (
                <div>
                    { !isEditing ? (
                        <div>
                            <div className='training-details-buttons'>
                                <IconButton aria-label="delete" size="small" onClick={() => setOpen(false)}>
                                    <ArrowForwardRoundedIcon fontSize="small"/>
                                </IconButton>
                                <div>
                                    <IconButton aria-label="delete" size="small" onClick={() => setIsEditing(true)}>
                                        <ModeEditOutlineRoundedIcon fontSize="small"/>
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" onClick={() => setModalWarningOpen(true)}>
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </div>
                            </div>
                            <div className='training-details-content'>
                                <div className='training-details-header'>
                                    <GroupIcon width={20} color={event.training.group.color}/>
                                    <h1>{event.training.group.name}</h1>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            InputProps={{readOnly: true}}
                                            label="Début"
                                            value={dayjs(event.start).format('DD/MM/YYYY')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            InputProps={{readOnly: true}}
                                            label="Heure"
                                            value={dayjs(event.start).format('HH:mm')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            InputProps={{readOnly: true}}
                                            label="Fin"
                                            value={dayjs(event.end).format('DD/MM/YYYY')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            InputProps={{readOnly: true}}
                                            label="Heure"
                                            value={dayjs(event.end).format('HH:mm')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size='small'
                                            InputProps={{readOnly: true}}
                                            label="Lieu"
                                            value={club.pools.find(p => p.id === event.poolId).name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {event.training.group.trainingsType === 'mandatory' ? (
                                            <>
                                                <div className='accordion-container'>
                                                    <Accordion disableGutters elevation={0}>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                                          aria-controls="panel1a-content"
                                                                          id="panel1a-header">
                                                            <div className="view-subtitle user">
                                                                <h2>{event.training.group.subscriptions.length} adhérents
                                                                    sur cette
                                                                    séance</h2></div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={0}>
                                                                {event.training.group.subscriptions.map((subscription, index) => (
                                                                    <Grid item xs={6} key={index}>
                                                                        <div className="view-subtitle user">
                                                                            <Link
                                                                                to={`/admin/subscriptions/${subscription.id}`}>
                                                                                <h2>{`${subscription.profile.firstName.toLowerCase()} ${subscription.profile.lastName.toUpperCase().charAt(0)}.`}</h2>
                                                                            </Link>
                                                                        </div>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <a href={`mailto:${event.training.group.subscriptions.map(s => s.profile.customer.email).join(',')}`}
                                                   className='main-button full-width without-icon'>
                                                    Contacter tous les adhérents
                                                </a>
                                            </>

                                        ) : (
                                            <>
                                                <div className='accordion-container'>
                                                    <Accordion disableGutters elevation={0}>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                                          aria-controls="panel1a-content"
                                                                          id="panel1a-header">
                                                            <div className="view-subtitle user">
                                                                <h2>{event.training.group.subscriptions.filter(s => s.trainings.some(t => t.trainingId === event.training.id)).length} adhérents
                                                                    sur cette séance</h2>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={0}>
                                                                {event.training.group.subscriptions.filter(s => s.trainings.some(t => t.trainingId === event.training.id)).map((subscription, index) => (
                                                                    <Grid item xs={6} key={index}>
                                                                        <div className="view-subtitle user">
                                                                            <Link
                                                                                to={`/admin/subscriptions/${subscription.id}`}>
                                                                                <h2>{`${subscription.profile.firstName.toLowerCase()} ${subscription.profile.lastName.toUpperCase().charAt(0)}.`}</h2>
                                                                            </Link>
                                                                        </div>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <a href={`mailto:${event.training.group.subscriptions.filter(s => s.trainings.some(t => t.trainingId === event.training.id)).map(s => s.profile.customer.email).join(',')}`}
                                                   className='main-button full-width without-icon'>
                                                    Contacter tous les adhérents
                                                </a>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    ) : (
                        <EventEdit
                            event={event}
                            setOpen={setOpen}
                            setModalWarningOpen={setModalWarningOpen}
                            setIsEditing={setIsEditing}
                            planning={planning}
                            setPlanning={setPlanning}
                            groups={groups}
                        />
                    )}
                    <CustomWarningModal
                        content={`Souhaitez-vous vraiment supprimer cet entrainement ?`}
                        modalOpen={modalWarningOpen}
                        setModalOpen={setModalWarningOpen}
                        buttonLoading={modalButtonLoading}
                        confirm={handleDelete}
                    />
                </div>
            ) : (
                <CircularProgress size={30}/>
            )}
        </div>
    );
}

export default EventDetails;