import React, {useContext, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import {CircularProgress, Grid, IconButton, TextField} from "@mui/material";
import toastOptions from "../../../assets/utils/toast";
import dayjs from "dayjs";
import CustomTag from "../../custom/CustomTag";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const CustomerView = () => {

    const { customerId } = useParams();
    const [customer, setCustomer] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'customers/' + customerId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setCustomer(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Client inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, customerId]);

    return isLoaded ? (
        <div className='account-view'>
            <div className='view-header customer'>
                <div className='view-title'>
                    <h1>{customer.firstName} {customer.lastName}</h1>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                        <ArrowBackRoundedIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            <div className='view-container scrollable'>
                <div className='view-subtitle'>
                    <h2>Informations générales</h2>
                </div>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Email" variant="outlined" value={customer.email} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Date de création" variant="outlined" value={dayjs(customer.createdAt).format('DD MMMM YYYY à HH:mm')} />
                        </Grid>
                    </Grid>
                </div>
                <div className='view-subtitle user'>
                    <h2>Profils</h2>
                </div>
                { customer.profiles.length > 0 ? customer.profiles.map((profile, index) => (
                    <div className='subscription-profile-recap' key={index}>
                        <div className='subscription-profile-recap-lines'>
                            <span className='user'>{profile.firstName} {profile.lastName} {profile.federalId ? `(${profile.federalId})` : null }</span>
                            <span className='birth'>{dayjs(profile.birthDate).format('DD MMMM YYYY')} ({dayjs().diff(profile.birthDate, 'year')} ans)</span>
                            <span className='address'>{profile.address} {profile.postalCode} {profile.city}</span>
                            { profile.parentLastName ? (
                                <span className='info'>Représentant légal : {profile.parentFirstName} {profile.parentLastName}</span>
                            ) : null }
                            <a className='phone' href={`tel:${profile.phone}`}>{profile.phone}</a>
                        </div>
                        <div className='customer-profile-subscriptions'>
                            { profile.subscriptions.length > 0 ? profile.subscriptions.map((subscription, index) => (
                                <div className='subscription-profile-recap' key={index}>
                                    <div className='subscription-profile-recap-lines subscription'>
                                        <span className='status'><CustomTag value={subscription.status} /></span>
                                        <Link to={`/admin/groups/${subscription.group.id}`} className='group'><CustomTag value={subscription.group.name} /></Link>
                                        <span className='date'>{dayjs(subscription.createdAt).format('DD MMMM YYYY à HH:mm')}</span>
                                    </div>
                                    <div className='right-top-buttons'>
                                        <Link to={`/admin/subscriptions/${subscription.id}`}>
                                            <IconButton aria-label="delete" size="small">
                                                <VisibilityRoundedIcon fontSize="small" />
                                            </IconButton>
                                        </Link>
                                    </div>
                                </div>
                            )) : (
                                <div className='profile-subscription-line'>
                                    <div className='profile-subscription-title'>
                                        <span>Aucune adhésion enregistrée pour cette saison.</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='right-top-buttons'>
                            <Link to={`/admin/profiles/${profile.id}`}>
                                <IconButton aria-label="delete" size="small">
                                    <VisibilityRoundedIcon fontSize="small" />
                                </IconButton>
                            </Link>
                            <Link to={`/admin/profiles/${profile.id}/edit`}>
                                <IconButton aria-label="delete" size="small">
                                    <ModeEditOutlineRoundedIcon fontSize="small" />
                                </IconButton>
                            </Link>
                        </div>
                    </div>
                )) : (
                    <div className='profile-subscription-line'>
                        <div className='profile-subscription-title'>
                            <span>Aucun profil enregistré pour ce compte.</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default CustomerView;