import emptyIcon from "../../../assets/images/empty-list.png";
import button from "bootstrap/js/src/button";
import {Grid} from "@mui/material";
import add_logo from "../../../assets/icons/round-add--blue.svg";
import ProfileAdd from "./ProfileAdd";
import ProfileEdit from "./ProfileEdit";
import React, {useState} from "react";
import {Link} from "react-router-dom";

const CustomerProfiles = ({ customer, appRefresh, setAppRefresh }) => {

    const [modalAddOpen, setModalAddOpen] = useState(false);

    return (
        <div className='customer-profiles'>
            <div className='form-info-container'>
                <div className='view-subtitle user'>
                    <h2>Vos profils</h2>
                </div>
                { customer.profiles.length > 0 ? (
                    <>
                        <Grid container spacing={2}>
                            { customer.profiles.length > 0 && customer.profiles.map((customerProfile, index) => (
                                <Grid item xs={12} md={6} key={index}>
                                    <ProfileEdit
                                        customerProfile={customerProfile}
                                        appRefresh={appRefresh}
                                        setAppRefresh={setAppRefresh}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs>
                                <div className='profile-create-chooser'>
                                    <div className='profile-create-block' onClick={() => setModalAddOpen(true)}>
                                        <img src={add_logo} alt='me' width={20} />
                                        <span>Ajouter un profil</span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <div className='empty-profiles'>
                        <div className='empty-list-container out-list'>
                            <img src={emptyIcon} alt='icon' width={120} />
                            <span>Vous ne possédez aucun profil pour l'instant. Un profil sera créé automatiquement lors de votre première adhésion à un club. Vous pouvez également en créer un tout de suite en appuyant sur le bouton ci-dessous</span>
                            <button className="main-button blue-button plus" onClick={() => setModalAddOpen(true)}>Ajouter un profil</button>
                        </div>
                    </div>
                )}
            </div>
            <div className='form-info-container'>
                <div className='view-subtitle'>
                    <h2>Prêt à démarrer ?</h2>
                </div>
                <Link to='/groups'>
                    <button className='main-button blue-button submit-subcription'>M'inscrire à un groupe</button>
                </Link>
            </div>
            <ProfileAdd
                modalOpen={modalAddOpen}
                setModalOpen={setModalAddOpen}
                appRefresh={appRefresh}
                setAppRefresh={setAppRefresh}
            />
        </div>
    )
}

export default CustomerProfiles;