import {Navigate, useLocation} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../../../contexts/AuthContext";

const CustomerRoute = ({ children }) => {

    const { user, customer } = useContext(AuthContext);
    const location = useLocation();

    if(user) return <Navigate to="/admin" state={{from: location}} replace />
    if(!customer) return <Navigate to="/login" state={{from: location}} replace />

    return children;
}

export default CustomerRoute;