import CustomModal from "../../custom/CustomModal";
import React, {useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";

const HealthForm = ({ licence, setLicence }) => {

    const questions = dayjs().diff(dayjs(licence.profile.birthDate), 'year') < 18 ? constants.healthQuestions.find(q => q.value === 'minor') : constants.healthQuestions.find(q => q.value === 'major');
    const [open, setOpen] = useState(false);
    const [answers, setAnswers] = useState({});
    const [error, setError] = useState(null);

    const submitForm = () => {
        for (let category of questions.categories) {
            for (let question of category.questions) {
                if (answers[question.id] === undefined) {
                    setError(true);
                    return;
                }
            }
        }
        setError(false);
        setLicence({...licence, healthForm: answers});
        setOpen(false);
    }

    return (
        <div>
            <button className='main-button full-width without-icon blue-button' onClick={() => setOpen(true)}>Répondre au formulaire QS Sport</button>
            <CustomModal
                title={`Formulaire QS Sport - ${dayjs().diff(dayjs(licence.profile.birthDate), 'year') < 18 ? 'Mineurs' : 'Majeurs'}`}
                show={open}
                setShow={setOpen}
                className='health-form-modal'
            >
                <div>
                    { dayjs().diff(dayjs(licence.profile.birthDate), 'year') < 18 ? (
                        <span className='licence-sign-note warning margin-bottom-15'>Avertissement à destination des parents ou de la personne ayant l'autorité parentale : il est préférable que ce questionnaire soit complété par votre enfant, c'est à vous d'estimer à quel âge il est capable de le faire. Il est de votre responsabilité de vous assurer que le questionnaire est correctement complété et de suivre les instructions en fonction des réponses données.</span>
                    ) : (
                        <span className='licence-sign-note margin-bottom-15'>Répondez aux questions suivantes par OUI ou par NON. Les réponses formulées relèvent de la seule responsabilité du licencié.</span>
                    )}
                    <TableContainer>
                        <Table size="small" aria-label="Entrainements">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Oui</TableCell>
                                    <TableCell>Non</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { questions.categories.map((category, index) => (
                                    <>
                                        <TableRow className='blue-row' key={index}>
                                            <TableCell colSpan={3}>{category.label}</TableCell>
                                        </TableRow>
                                        { category.questions.map((question, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">{question.value}</TableCell>
                                                <TableCell>
                                                    <label className="checkbox-container" onClick={() => setAnswers({...answers, [question.id]: true})}>
                                                        <input type="checkbox" checked={answers[question.id] === true}/>
                                                        <span className="checkmark"/>
                                                    </label>
                                                </TableCell>
                                                <TableCell>
                                                    <label className="checkbox-container" onClick={() => setAnswers({...answers, [question.id]: false})}>
                                                        <input type="checkbox" checked={answers[question.id] === false}/>
                                                        <span className="checkmark"/>
                                                    </label>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { error && (
                        <div className='error-message margin-top'>
                            <span>Merci de répondre à toutes les questions par oui ou par non.</span>
                        </div>
                    )}
                    <div className='modal-bottom-button'>
                        <button className='main-button full-width margin-right without-icon' onClick={() => setOpen(false)}>Annuler</button>
                        <button className='main-button full-width without-icon blue-button' onClick={submitForm}>Valider</button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
}

export default HealthForm;