import React from "react";
import {Route, Routes} from "react-router-dom";
import CustomerList from "./CustomerList";
import CustomerAdd from "./CustomerAdd";
import CustomerView from "./CustomerView";
import CustomerEdit from "./CustomerEdit";

const Customers = () => {
    return (
        <Routes>
            <Route path='new' element={<CustomerAdd />} />
            <Route path=':customerId/edit' element={<CustomerEdit />} />
            <Route path=':customerId' element={<CustomerView />} />
            <Route  path='*' element={<CustomerList />} />
        </Routes>
    );
}

export default Customers;
