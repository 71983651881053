import {CircularProgress, Grid} from "@mui/material";
import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import CustomRichText from "../../custom/CustomRichText";
import ProfileEdit from "./ProfileEdit";
import CustomField from "../../custom/CustomField";
import CustomFile from "../../custom/CustomFile";
import {useNavigate} from "react-router-dom";
import button from "bootstrap/js/src/button";
import CustomTag from "../../custom/CustomTag";
import TrainingName from "../../admin/trainings/TrainingName";

const ChangeSubscription = ({ group, subscriptionId, appRefresh, setAppRefresh }) => {

    const [subscription, setSubscription] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token, club } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_PUBLIC_API_URL + 'subscriptions/' + subscriptionId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                setSubscription({...response.data, clubId: club.id, groupId: group.id, newFiles: []});
                setIsLoaded(true);
            })
            .catch((error) => console.log(error));
    }, [token, subscriptionId]);

    const handleChangeFieldValue = (field, newValue) => {
        const newFields = [...subscription.fields].filter(f => f.fieldId !== field.id);
        setSubscription({...subscription, fields: [...newFields, {fieldId: field.id, value: newValue }]})
    }

    const handleChangeFile = (file, newFile) => {
        const newFiles = [...subscription.newFiles].filter(f => f.id !== file.id);
        setSubscription({...subscription, newFiles: [...newFiles, {id: file.id, content: newFile }]})
    }

    const submitUpdate = () => {

        setButtonLoading(true);

        const formData = new FormData();
        formData.append('subscription', JSON.stringify(subscription));

        for(const file of  subscription.newFiles) {
            const renamedFile = new File([file.content], `${file.id}.${file.content.name.split('.').pop()}`, { type: file.content.type });
            formData.append('files', renamedFile);
        }

        axios.put(process.env.REACT_APP_PUBLIC_API_URL + 'subscriptions', formData, { headers : { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token }})
            .then(() => {
                navigate('/my-account/subscriptions?result=change-success');
                setAppRefresh(appRefresh + 1);
                setButtonLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div className='change-subscription-container'>
            <div className='customer-message change-needed'>
                <span>Votre demande est en attente de changements pour le motif suivant :</span>
                <CustomRichText
                    readMode
                    value={subscription.messages[0].content}
                />
                <span>Vous pouvez effectuer les modifications nécessaires ci-dessous, puis soumettre de nouveau votre demande.</span>
            </div>
            <div className='public-subscription-block'>
                <div className='subscription-line'>
                    <span className='subscription-line-title'>Statut </span>
                    <CustomTag value={subscription.status} />
                </div>
                <div className='subscription-line group'>
                    <span className='subscription-line-title'>Groupe </span>
                    <CustomTag value={subscription.group.name} />
                </div>
            </div>
            { group.trainingsType === "optional" ? (
                <div>
                    <div className='view-subtitle calendar'>
                        <h2>Séance(s) choisie(s)</h2>
                    </div>
                    <div className='subscription-trainings'>
                        { subscription.trainings.map((training, index) => (
                            <TrainingName key={index} training={group.trainings.find(groupTraining => training.trainingId === groupTraining.id)} />
                        ))}
                    </div>
                </div>
            ) : null }
            <div className='view-subtitle user'>
                <h2>Modifier le profil</h2>
            </div>
            <div className='form-info-container'>
                <ProfileEdit
                    noRefresh
                    customerProfile={subscription.profile}
                    appRefresh={appRefresh}
                    setAppRefresh={setAppRefresh}
                />
            </div>
            { group.fields.filter(f => f.isPublic).length > 0 ? (
                <div className='form-info-container'>
                    <div className='view-subtitle'>
                        <h2>Informations complémentaires</h2>
                    </div>
                    <Grid container spacing={2}>
                        { group.fields.filter(f => f.isPublic).map((field, index) => (
                            <Grid item xs={12} md={6}>
                                <CustomField
                                    value={subscription.fields.some(f => f.fieldId === field.id) ? subscription.fields.find(f => f.fieldId === field.id).value : null}
                                    field={field}
                                    key={index}
                                    setField={handleChangeFieldValue}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ) : null }
            { group.files.length > 0 ? (
                <div className='form-info-container'>
                    { group.files.map((file, index) => (
                        <div>
                            <div className={'view-subtitle file ' + (file.description ? 'with-description' : '')}>
                                <h2>{file.label} {file.required ? '*' : ''}</h2>
                            </div>
                            <CustomRichText value={file.description} readMode />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomFile
                                        templateLink={file.hasTemplate ? (process.env.REACT_APP_FILES_API_URL + 'templates/' + file.id + file.templateExtension) : null}
                                        fileInfo={file}
                                        key={index}
                                        selectedFile={subscription.newFiles.find(f => f.id === file.id)}
                                        changeFile={handleChangeFile}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </div>
            ) : null }
            <button className='main-button blue-button submit-subcription' disabled={buttonLoading} onClick={submitUpdate}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Confirmer la modification'}</button>
            
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default ChangeSubscription;