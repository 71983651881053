import React, {useContext, useState} from "react";
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import PlanningFilter from "./PlanningFilter";
import EventDetails from "./EventDetails";
import EventAdd from "./EventAdd";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import dayjs from "dayjs";

const PlanningView = ({ groups, groupId }) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [planning, setPlanning] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [newEvent, setNewEvent] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [nextEvent, setNextEvent] = useState(dayjs().format('YYYY-MM-DD'));
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + `planning${groupId ? ('/groups/' + groupId) : ''}`, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    const events = response.data.map(e => ({...e, title: e.training.group.name, color: e.training.group.color, active: true }));
                    setPlanning(events);
                    if (events.length > 0) {
                        const sortedEvents = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
                        const nextEvent = sortedEvents.find(event => dayjs(event.start).isAfter(dayjs()));
                        if (nextEvent) setNextEvent(dayjs(nextEvent.start).format('YYYY-MM-DD'));
                    }
                    setIsLoaded(true);
                }
            })
            .catch((error) => console.log(error));
    }, [token]);

    const handleEventUpdated = (info) => {
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'planning', {
            id: info.event.id,
            start: dayjs(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
            end: dayjs(info.event.end).format('YYYY-MM-DD HH:mm:ss')
        }, { headers: { Authorization: 'Bearer ' + token }})
            .catch(() => {
                toast.error("Une erreur est survenue", toastOptions);
            });
    };

    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
        setOpenDetails(true);
        setOpenAdd(false);
    };

    const handleDateSelect = (selectInfo) => {
        setNewEvent(selectInfo);
        setOpenAdd(true);
        setOpenDetails(false);
    };

    return isLoaded ? (
        <div className='planning-view'>
            { !groupId ? (
                <div className='view-header calendar'>
                    <div className='view-title'>
                        <h1>Planning de la saison</h1>
                    </div>
                    <div className='view-buttons'>
                        <PlanningFilter activeGroups={groups} setEvents={setPlanning} events={planning} />
                    </div>
                </div>
            ) : null }
            <div className='view-container'>
                <FullCalendar
                    plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
                    initialView='timeGridWeek'
                    allDaySlot={false}
                    height={'100%'}
                    editable
                    events={planning.filter(e => e.active)}
                    nowIndicator
                    locale={frLocale}
                    initialDate={nextEvent}
                    headerToolbar={{
                        left: 'prev,today,next',
                        center: 'title',
                        right: 'timeGridDay,timeGridWeek,dayGridMonth',
                    }}
                    eventClick={handleEventClick}
                    selectable
                    select={handleDateSelect}
                    eventDrop={handleEventUpdated}
                    eventResize={handleEventUpdated}
                />
            </div>
            <EventDetails
                eventData={ selectedEvent && { start: selectedEvent.start, end: selectedEvent.end, id: selectedEvent.id }}
                open={openDetails}
                setOpen={setOpenDetails}
                planning={planning}
                setPlanning={setPlanning}
                groups={groups}
            />
            <EventAdd
                eventData={newEvent}
                open={openAdd}
                setOpen={setOpenAdd}
                planning={planning}
                setPlanning={setPlanning}
                groups={groups}
            />
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default PlanningView;
