import React, {useContext, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";

import Tippy from "@tippyjs/react";
import dayjs from "dayjs";
import CustomTag from "../../custom/CustomTag";
import constants from "../../../assets/utils/constants";
import {CircularProgress} from "@mui/material";

const TrainingView = () => {

    const { trainingId } = useParams();
    const [training, setTraining] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'trainings/' + trainingId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setTraining(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Client inconnu', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, trainingId]);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce fournisseur seront perdues.")) {
            toast.promise(
                axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'trainings/' + trainingId, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Fournisseur supprimé',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                navigate(-1);
            });
        }
    }

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{training.group.name}</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button back-button margin-right' onClick={() => navigate(-1)} />
                    { training.status === "archived" ? null :
                        <Link to= 'edit' >
                            <button className='main-button blue-button edit'>Modifier</button>
                        </Link>
                    }
                    <Tippy content={popover}
                           allowHTML={true}
                           placement={'bottom-end'}
                           offset={[0,5]}
                           visible={popoverVisible}
                           onClickOutside={hide}
                           interactive={true}
                           appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className='input-row-container'>
                <span className='input-label pool'>Piscine <span className='required'>*</span></span>
                <CustomTag value={training.pool.name} />
            </div>
            <div className='input-row-container'>
                <span className='input-label calendar'>Date <span className='required'>*</span></span>
                <span className='margin-right'>Le</span>
                <div className='input-date-container'>
                    <span className='date-view'>{ constants.all.find(c => c.value === training.weekDay).label }</span>
                </div>
                <span className='margin-left margin-right'>de</span>
                <div className='input-date-container margin-right'>
                    <span className='date-view'>{ dayjs(dayjs().format('YYYY-MM-DD') + training.startHour).format('HH:mm') }</span>
                </div>
                <span className='margin-right'>à</span>
                <div className='input-date-container margin-right'>
                    <span className='date-view'>{ dayjs(dayjs().format('YYYY-MM-DD') + training.stopHour).format('HH:mm') }</span>
                </div>
            </div>
            <div className='input-textarea-container'>
                <span className='input-label pen'>Description</span>
                { training.description ? (
                    <div className='textarea-view'>{training.description}</div>
                ) : (
                    <div className='textarea-view'><span className='empty-text'>Aucune description pour cette séance</span></div>
                )}
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default TrainingView;