import {CircularProgress} from "@mui/material";
import button from "bootstrap/js/src/button";
import CustomModal from "./CustomModal";
import React, {useState} from "react";

const CustomWarningModal = ({ content, title = 'Attention !', confirm, buttonLoading, modalOpen, setModalOpen}) => {

    return (
        <div>
            <CustomModal show={modalOpen} setShow={setModalOpen} title={title}>
                <div className='form-info-container'>
                    <span>{content}</span>
                </div>
                <div className='modal-bottom-button'>
                    <button className='main-button submit-subcription' onClick={() => setModalOpen(false)} disabled={buttonLoading}>Annuler</button>
                    <button className='main-button red-button submit-subcription margin-left' onClick={confirm} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Confirmer'}</button>
                </div>
            </CustomModal>
        </div>
    );
}

export default CustomWarningModal;