import React, {useContext} from "react";
import CustomTag from "./CustomTag";
import AuthContext from "../../contexts/AuthContext";

const CustomOption = ({ innerProps, isDisabled, label, ...props }) => {

    const { club } = useContext(AuthContext);

    return (
        !isDisabled ? (
            <div {...innerProps} className='custom-tag-container' >
                <CustomTag value={label} className={props.data.isSeason ? (props.data.id === club.activeSeasonId ? 'active' : 'inactive') : null} />
            </div>
        ) : null
    );
}

export default CustomOption;