import React, {useContext} from "react";
import CustomTag from "./CustomTag";
import AuthContext from "../../contexts/AuthContext";

const CustomSingleValue = ({ innerProps, ...props }) => {

    const { club } = useContext(AuthContext);

    return (
        <div {...innerProps}>
            <CustomTag value={props.data.label} className={props.data.isSeason ? (props.data.id === club.activeSeasonId ? 'active' : 'inactive') : null} />
        </div>
    )
}

export default CustomSingleValue;