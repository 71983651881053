import {CircularProgress, Grid, IconButton, TextField} from "@mui/material";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/utils/constants";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React, {useContext} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import {Link, useNavigate, useParams} from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const UserEdit = () => {

    const { userId} = useParams();
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = React.useState();
    const [user, setUser] = React.useState(null);
    const { token} = useContext(AuthContext);

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'users/' + userId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                setUser(response.data);
                setIsLoaded(true);
            })
            .catch((error) => console.log(error));
    }, [token, userId]);

    const handleUpdate = () => {
        setIsLoaded(false);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'users/' + userId, user, {headers: {Authorization: 'Bearer ' + token}})
            .then(() => {
                setIsLoaded(true);
                navigate(-1);
                toast.success("Le profil a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error.response.data);
                setError(error.response.data);
                setIsLoaded(true);
            });
    }

    return isLoaded ? (
        <div className='user-view'>
            <div className='view-header user'>
                <div className='view-title'>
                    <h1>{`${user.firstName} ${user.lastName}`}</h1>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={handleUpdate}>
                        <DoneRoundedIcon fontSize="small" />
                    </IconButton>
                    <Link to={'/admin/users/' + userId}>
                        <IconButton aria-label="delete" size="small">
                            <ClearRoundedIcon fontSize="small" />
                        </IconButton>
                    </Link>
                </div>
            </div>
            <div className="view-container user scrollable">
                <div className='subscription-line status'>
                    <span className='subscription-line-title'>Rôle</span>
                </div>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CustomSearchInput
                                className='public'
                                placeholder='Civilité'
                                entity={constants.roles}
                                value={constants.roles.find(c => c.value === user.role)}
                                onChange={(role) => setUser({...user, role: role.value})}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className='subscription-line'>
                    <span className='subscription-line-title'>Informations générales</span>
                </div>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CustomSearchInput
                                className={`public ${error && error.missingFields.includes('civility') ? 'error' : ''}`}
                                placeholder='Civilité'
                                entity={constants.civilities}
                                value={constants.civilities.find(c => c.value === user.civility)}
                                onChange={(civility) => setUser({...user, civility: civility.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Identifiant fédéral"
                                variant="outlined"
                                error={error && error.missingFields.includes('federalId')}
                                value={user.federalId}
                                onChange={(e) => setUser({...user, federalId: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Prénom"
                                variant="outlined"
                                error={error && error.missingFields.includes('firstName')}
                                value={user.firstName}
                                onChange={(e) => setUser({...user, firstName: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nom"
                                variant="outlined"
                                error={error && error.missingFields.includes('lastName')}
                                value={user.lastName}
                                onChange={(e) => setUser({...user, lastName: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nationalité"
                                variant="outlined"
                                error={error && error.missingFields.includes('nationality')}
                                value={user.nationality}
                                onChange={(e) => setUser({...user, nationality: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Téléphone"
                                variant="outlined"
                                error={error && error.missingFields.includes('phone')}
                                value={user.phone}
                                onChange={(e) => setUser({...user, phone: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                format='DD/MM/YYYY'
                                label='Date de naissance'
                                slotProps={{ textField: { error: error && error.missingFields.includes('birthDate')} }}
                                value={user.birthDate ? dayjs(user.birthDate) : null}
                                disableFuture
                                onChange={(newDate) => setUser({
                                    ...user,
                                    birthDate: newDate.format('YYYY-MM-DD')
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Adresse"
                                variant="outlined"
                                error={error && error.missingFields.includes('address')}
                                value={user.address}
                                onChange={(e) => setUser({...user, address: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Code postal"
                                variant="outlined"
                                error={error && error.missingFields.includes('postalCode')}
                                value={user.postalCode}
                                onChange={(e) => setUser({...user, postalCode: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Ville"
                                variant="outlined"
                                error={error && error.missingFields.includes('city')}
                                value={user.city}
                                onChange={(e) => setUser({...user, city: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='training-selector-block no-margin-bottom flex-view'>
                                <label className="checkbox-container">
                                    <input type="checkbox" checked={user.disability}
                                           onClick={(e) => setUser({
                                               ...user,
                                               disability: e.target.checked
                                           })}/>
                                    <span className="checkmark"/>
                                </label>
                                <span className='small-note'>Athlète handisport</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default UserEdit;