import {CircularProgress, IconButton} from "@mui/material";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CustomRichText from "../../custom/CustomRichText";
import React, {useContext, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";

const NoteEdit = ({subscription, profile, appRefresh, setAppRefresh}) => {

    const activeNotes = subscription ? subscription.notes : profile.notes;
    const [notes, setNotes] = useState(activeNotes);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { token} = useContext(AuthContext);

    const handleUpdate = () => {
        setIsLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + (subscription ? 'subscriptions/' + subscription.id : 'profiles/' + profile.id), subscription ? { notes: notes } : {...profile, notes: notes}, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setIsLoading(false);
                setIsEditing(false);
                setAppRefresh(appRefresh + 1);
                toast.success("Les notes ont bien été modifiées", toastOptions);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsEditing(false);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    return (
        <div>
            <div className='subscription-line notes'>
                <span className='subscription-line-title'>Notes { subscription ? "à propos de l'inscription" : "à propos du profil"}</span>
                { isEditing ? (
                    <div>
                        { isLoading ? (
                            <CircularProgress size={10} />
                        ) : (
                            <>
                                <IconButton aria-label="delete" size="small" onClick={handleUpdate}>
                                    <DoneRoundedIcon fontSize="small" />
                                </IconButton>
                                <IconButton aria-label="delete" size="small" onClick={() => {
                                    setIsEditing(false);
                                    setNotes(activeNotes);
                                }}>
                                    <ClearRoundedIcon fontSize="small" />
                                </IconButton>
                            </>
                        )}
                    </div>
                ) : (
                    <IconButton aria-label="delete" size="small" onClick={() => setIsEditing(true)}>
                        <ModeEditOutlineRoundedIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
            { isEditing ? (
                <CustomRichText value={notes} setValue={(newValue) => setNotes(newValue)} />
            ) : (
                <div className='profile-subscription-line'>
                    { notes ? <CustomRichText readMode value={notes} /> :  <span className='very-small-note'>Aucune note pour le moment.</span> }
                </div>
            )}
        </div>
    )
}

export default NoteEdit;