import React, { useState } from "react";
import {Link} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import constants from "../../../assets/utils/constants";

const TrainingList = () => {

    
    const [selectedColumns, setSelectedColumns] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Groupe',
                accessor: 'group.name',
                icon: 'pen'
            },
            {
                Header: 'Jour',
                accessor: 'weekDay',
                icon: 'type',
                Cell: ({ cell: { value } }) => (<span>{constants.all.find(constant => constant.value === value ).label} </span>)
            },
            {
                Header: 'Début',
                accessor: 'startHour',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => (<span>{dayjs(dayjs().format('YYYY-MM-DD ' + value)).format('HH:mm')}</span>)
            },
            {
                Header: 'Fin',
                accessor: 'stopHour',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => (<span>{dayjs(dayjs().format('YYYY-MM-DD ' + value)).format('HH:mm')}</span>)
            },
            {
                Header: 'Piscine',
                accessor: 'pool.name',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            }
        ],
        []
    )

    return (
        <div className='page-content'>
            <div className='list-header'>
                <div className='header-title trainings'>
                    <h1>Séances</h1>
                </div>
                <div className='header-buttons'>
                    { selectedColumns === 0 ?
                        <div>
                            <Link to='new'>
                                <button className='main-button blue-button plus'>Ajouter une séance</button>
                            </Link>
                            <button className='main-button download'>Télécharger</button>
                        </div>
                        :
                        <div>
                            <button className='main-button archive'>Archiver</button>
                            <button className='main-button warning-button delete'>Supprimer</button>
                        </div>
                    }
                </div>
            </div>
            <CustomList entity='trainings' columns={columns} setSelectedColumns={setSelectedColumns} />
        </div>
    );
}

export default TrainingList;
