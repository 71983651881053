import React, {useState} from "react";
import LicenceSign from "../licence/LicenceSign";
import file_logo from "../../../assets/icons/pencil--blue.svg";
import filled_file_logo from "../../../assets/icons/check-circle--green.svg";

const LicenceForm = ({ group, customer, profileId, subscription, setSubscription }) => {

    const [signOpen, setSignOpen] = useState(false);

    React.useEffect(() => setSubscription({...subscription, licenceFile: null}), [profileId]);

    return (
        <div className='licence-form-container'>
            <div className='view-subtitle sign'>
                <h2>Signez votre licence</h2>
            </div>
            <span className='licence-sign-note margin-bottom-15'>Vous devez désormais signer votre licence en ligne !</span>
            <div className='custom-file-container' onClick={() => setSignOpen(true)}>
                <label className={`custom-file-label ${subscription.licenceFile ? 'selected' : ''}`}>
                    <img src={subscription.licenceFile ? filled_file_logo : file_logo} width={18} alt='file'/>
                    <span>{subscription.licenceFile ? 'Licence signée' : 'Signer ma licence en ligne'}</span>
                    { subscription.licenceFile ? (
                        <div className='file-max-info'>
                            <small>Cliquez pour signer une nouvelle licence</small>
                        </div>
                    ) : null}
                </label>
            </div>
            <LicenceSign
                key={profileId}
                group={group}
                customer={customer}
                profileId={profileId}
                subscription={subscription}
                setSubscription={setSubscription}
                open={signOpen}
                setOpen={setSignOpen}
            />
        </div>
    );
}

export default LicenceForm;