import file_logo from "../../assets/icons/folder--blue.svg";
import uploaded_logo from "../../assets/icons/folder--green.svg";
import React from "react";
import {Link} from "react-router-dom";

const CustomFile = ({fileInfo, changeFile, selectedFile, readOnly = false, placeholder, templateLink, isAdmin = false }) => {

    return (
        <div className='custom-file-container'>
            { !readOnly ? (
                <div>
                    { templateLink ? (
                        <a href={templateLink} target='_blank' className='view-template-button'>Fichier à remplir</a>
                    ) : null }
                    <input
                        type="file"
                        name={fileInfo.id}
                        id={fileInfo.id}
                        className="custom-file"
                        onChange={(e) => changeFile(fileInfo, e.target.files[0])}
                    />
                    <label htmlFor={fileInfo.id} className={'custom-file-label ' + (selectedFile ? 'selected' : '')}>
                        <img src={selectedFile ? uploaded_logo : file_logo} width={18} alt='file' />
                        <span>{ selectedFile ? 'Fichier déposé' : (placeholder ? placeholder : 'Choisir un fichier')}</span>
                        <div className='file-max-info'>
                            <small>Formats autorisés : PNG, JPEG, PDF - Taille maximale : 5Mo</small>
                        </div>
                    </label>
                </div>
            ) : selectedFile ? (
                <>
                    <Link to={process.env.REACT_APP_PUBLIC_API_URL + 'subscriptions/' + selectedFile.subscriptionId + '/files/' + selectedFile.fileId } target="_blank">
                        <label className='custom-file-label enabled'>
                            <img src={file_logo} width={18} alt='file' />
                            <span>{fileInfo.label} {fileInfo.required ? '*' : ''}</span>
                        </label>
                    </Link>
                    { isAdmin ? (
                        <>
                            <input
                                type="file"
                                name={fileInfo.id}
                                id={fileInfo.id}
                                className="custom-file"
                                onChange={(e) => changeFile(fileInfo, e.target.files[0])}
                            />
                            <label htmlFor={fileInfo.id} className='update-file' />
                        </>
                    ) : null}
                </>
            ) : (
                <>
                    <label htmlFor={fileInfo.id} className='custom-file-label disabled'>
                        <img src={file_logo} width={18} alt='file'/>
                        <span>{fileInfo.label} {fileInfo.required ? '*' : ''}</span>
                    </label>
                    { isAdmin ? (
                        <>
                            <input
                                type="file"
                                name={fileInfo.id}
                                id={fileInfo.id}
                                className="custom-file"
                                onChange={(e) => changeFile(fileInfo, e.target.files[0])}
                            />
                            <label htmlFor={fileInfo.id} className='update-file add' />
                        </>
                    ) : null}
                </>
            )}
        </div>
    )
}

export default CustomFile;