import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import CustomerInfo from "./CustomerInfo";
import CustomerProfiles from "./CustomerProfiles";
import CustomerSubscriptions from "./CustomerSubscriptions";
import React, {useContext} from "react";
import AuthContext from "../../../contexts/AuthContext";
import SubscriptionView from "../../public/subscriptions/SubscriptionView";

const CustomerPanel = ({appRefresh, setAppRefresh}) => {

    const location = useLocation();
    const { customer} = useContext(AuthContext);

    return (
        <div className='customer-panel-container'>
            <div className='customer-menu'>
                <div className='menu-main-items'>
                    <Link to='/my-account' className='menu-link'>
                        <div className={'menu-sub-item trainings' + (location.pathname === '/my-account' ? ' active' : '')}>
                            <span>Mon compte</span>
                        </div>
                    </Link>
                    <Link to='profiles' className='menu-link'>
                        <div className={'menu-sub-item admins' + (location.pathname.includes('profiles') ? ' active' : '')}>
                            <span>Mes profils</span>
                        </div>
                    </Link>
                    <Link to='subscriptions' className='menu-link'>
                        <div className={'menu-sub-item subscriptions' + (location.pathname.includes('subscriptions') ? ' active' : '')}>
                            <span>Mes adhésions</span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='customer-panel-content'>
                <Routes>
                    <Route path="subscriptions" element={<CustomerSubscriptions customer={customer} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                    <Route path="subscriptions/:subscriptionId" element={<SubscriptionView appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                    <Route path="profiles" element={<CustomerProfiles customer={customer} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                    <Route path="/" element={<CustomerInfo customer={customer} />} />
                    <Route path="*" element={<Navigate to='/my-account' />} />
                </Routes>
            </div>
        </div>
    );
}

export default CustomerPanel;