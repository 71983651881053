import React from "react";
import CustomList from "../../custom/CustomList";
import dayjs from "dayjs";

const CustomerList = () => {

    const columns = React.useMemo(
        () => [
            {
                Header: 'Prénom',
                accessor: 'firstName',
                icon: 'user'
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
                icon: 'user'
            },
            {
                Header: 'Email',
                accessor: 'email',
                icon: 'email'
            },
            {
                Header: 'Date de création',
                accessor: 'createdAt',
                icon: 'date',
                Cell: ({cell: {value}}) => <span>{dayjs(value).format('DD MMMM YYYY')}</span>
            }
        ],
        []
    )

    return (
        <div className='page-content customers-list'>
            <div className='view-header'>
                <div className='header-title users'>
                    <h1>Utilisateurs</h1>
                </div>
            </div>
            <div className='view-container scrollable'>
                <CustomList entity='customers' columns={columns} isSelectable={false} />
            </div>
        </div>
    );
}

export default CustomerList;
