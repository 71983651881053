import {Tooltip} from "@mui/material";
import button from "bootstrap/js/src/button";
import React, {useState} from "react";

const PlanningFilter = ({ activeGroups, events, setEvents }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [groups, setGroups] = useState(activeGroups.map(g => ({ id: g.id, name: g.name, active: true })));

    React.useEffect(() => {
        if(events.length > 0) {
            setEvents(events.map(e => ({...e, active: groups.find(g => g.id === e.training.group.id).active })));
        }
    }, [groups]);

    return (
        <div>
            <Tooltip leaveDelay={500} placement="bottom-end" id='options-tooltip' title={
                <React.Fragment>
                    <div className='planning-filter'>
                        <div className='planning-filter-select'>
                            <span onClick={() => setGroups(groups.map(g => ({...g, active: true })))}>Tout sélectionner</span>
                            <span onClick={() => setGroups(groups.map(g => ({...g, active: false })))}>Tout supprimer</span>
                        </div>
                        { groups.map((group, index) => (
                            <div className='planning-filter-row' key={index}>
                                <label className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        checked={group.active}
                                        onClick={() => setGroups(groups.map(g => g.id === group.id ? { ...g, active: !g.active } : g))}
                                    />
                                    <span className="checkmark"/>
                                </label>
                                <span>{group.name}</span>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            }>
                <button className='main-button planning-filter-button margin-right' onClick={() => setTooltipOpen(!tooltipOpen)}>{groups.filter(g => g.active).length} groupe(s) sélectionné(s)</button>
            </Tooltip>
        </div>
    );
}

export default PlanningFilter;