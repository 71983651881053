import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import {CircularProgress, Grid, IconButton, TextField} from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import toastOptions from "../../../assets/utils/toast";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CustomRichText from "../../custom/CustomRichText";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/utils/constants";

const ProfileEdit = () => {

    const { profileId } = useParams();
    const [profile, setProfile] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'profiles/' + profileId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setProfile(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Profil inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => console.log(error));
    }, [token, profileId]);

    const handleUpdate = () => {
        setIsLoaded(false);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'profiles/' + profileId, profile, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setIsLoaded(true);
                toast.success("Le profil a bien été modifié", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                console.log(error.response.data);
                setError(error.response.data);
                setIsLoaded(true);
            });
    }

    return isLoaded ? (
        <div className='account-view'>
            <div className='view-header edit'>
                <div className='view-title'>
                    <h1>Modifier le profil</h1>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                        <ArrowBackRoundedIcon fontSize="small" />
                    </IconButton>
                    <button className='blue-button main-button validate margin-left' onClick={handleUpdate}>Enregistrer</button>
                </div>
            </div>
            <div className='view-container scrollable'>
                { error ? (
                    <div className='error-message column'>
                        <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                        { error.details && error.details.map((detail, index) => (
                            <span key={index}>- {detail}</span>
                        ))}
                    </div>
                ) : null }
                <div className='form-info-container'>
                    <div className='view-subtitle group flex-start'>
                        <h2>Identifiant Unique Fédéral (IUF)</h2>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Identifiant fédéral"
                                variant="outlined"
                                error={error && error.missingFields.includes('federalId')}
                                value={profile.federalId}
                                onChange={(e) => setProfile({...profile, federalId: e.target.value === '' ? null : e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className='view-subtitle margin-bottom'>
                    <h2>Informations générales</h2>
                </div>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CustomSearchInput
                                className={`public ${error && error.missingFields.includes('civility') ? 'error' : ''}`}
                                placeholder='Civilité'
                                entity={constants.civilities}
                                value={constants.civilities.find(c => c.value === profile.civility)}
                                onChange={(civility) => setProfile({...profile, civility: civility.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Prénom"
                                variant="outlined"
                                error={error && error.missingFields.includes('firstName')}
                                value={profile.firstName}
                                onChange={(e) => setProfile({...profile, firstName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nom"
                                variant="outlined"
                                error={error && error.missingFields.includes('lastName')}
                                value={profile.lastName}
                                onChange={(e) => setProfile({...profile, lastName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nationalité"
                                variant="outlined"
                                error={error && error.missingFields.includes('nationality')}
                                value={profile.nationality}
                                onChange={(e) => setProfile({...profile, nationality: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Téléphone"
                                variant="outlined"
                                error={error && error.missingFields.includes('phone')}
                                value={profile.phone}
                                onChange={(e) => setProfile({...profile, phone: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                format='DD/MM/YYYY'
                                label='Date de naissance *'
                                slotProps={{
                                    textField: {
                                        error: error && error.missingFields.includes('birthDate')
                                    },
                                }}
                                value={dayjs(profile.birthDate)}
                                disableFuture
                                onChange={(newDate) => setProfile({...profile, birthDate: newDate.format('YYYY-MM-DD') })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Adresse"
                                variant="outlined"
                                error={error && error.missingFields.includes('address')}
                                value={profile.address}
                                onChange={(e) => setProfile({...profile, address: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Code postal"
                                variant="outlined"
                                error={error && error.missingFields.includes('postalCode')}
                                value={profile.postalCode}
                                onChange={(e) => setProfile({...profile, postalCode: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Ville"
                                variant="outlined"
                                error={error && error.missingFields.includes('city')}
                                value={profile.city}
                                onChange={(e) => setProfile({...profile, city: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='training-selector-block no-margin-bottom flex-view'>
                                <label className="checkbox-container">
                                    <input type="checkbox" checked={profile.disability} onClick={(e) => setProfile({...profile, disability: e.target.checked})}/>
                                    <span className="checkmark"/>
                                </label>
                                <span className='small-note'>Athlète handisport</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                { profile.birthDate && dayjs().diff(profile.birthDate, 'year') < 18 ? (
                    <div className='form-info-container'>
                        <div className='view-subtitle margin-bottom'>
                            <h2>Informations du représentant légal</h2>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Prénom du représentant"
                                    required
                                    variant="outlined"
                                    value={profile.parentFirstName}
                                    error={error && error.missingFields.includes('parentFirstName')}
                                    onChange={(e) => setProfile({...profile, parentFirstName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Nom du représentant"
                                    required
                                    variant="outlined"
                                    error={error && error.missingFields.includes('parentLastName')}
                                    value={profile.parentLastName}
                                    onChange={(e) => setProfile({...profile, parentLastName: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : null }
                <div className='view-subtitle'>
                    <h2>Notes</h2>
                </div>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomRichText
                                value={profile.notes}
                                setValue={(newValue) => setProfile({...profile, notes: newValue })}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default ProfileEdit;