import React from "react";
import {Route, Routes} from "react-router-dom";
import TrainingList from "./TrainingList";
import TrainingAdd from "./TrainingAdd";
import TrainingView from "./TrainingView";
import TrainingEdit from "./TrainingEdit";

const Trainings = ({ groups }) => {
    return (
        <Routes>
            <Route path='new' element={<TrainingAdd groups={groups} />} />
            <Route path=':trainingId/edit' element={<TrainingEdit />} />
            <Route path=':trainingId' element={<TrainingView />} />
            <Route path='*' element={<TrainingList />} />
        </Routes>
    )
}

export default Trainings;