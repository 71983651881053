import { createRoot } from 'react-dom/client';
import React from 'react';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {ToastContainer} from "react-toastify";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/fr';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastContainer />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DndProvider backend={HTML5Backend}>
                    <App />
                </DndProvider>
            </LocalizationProvider>
        </BrowserRouter>
    </React.StrictMode>
);
