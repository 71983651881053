import {CircularProgress, Grid, TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import React, {useContext, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import user_logo from "../../../assets/icons/round-user--blue.svg";
import user_logo_green from "../../../assets/icons/round-user--green.svg";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/utils/constants";

const ProfileEdit = ({ customerProfile, appRefresh, setAppRefresh, noRefresh, className, onClick, isSelected = false }) => {

    const [editedProfile, setEditedProfile] = useState(customerProfile);
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_PUBLIC_API_URL + 'customer/profile', editedProfile, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalOpen(false);
                if(!noRefresh) {
                    setAppRefresh(appRefresh + 1);
                }
                toast.success("Votre profil a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className={`profile-create-chooser ${className}`} onClick={onClick ? onClick : () => setModalOpen(true)}>
                <div className='profile-create-block'>
                    <img src={isSelected ? user_logo_green : user_logo} alt='me' width={20} />
                    <span>{editedProfile.firstName + ' ' + editedProfile.lastName}</span>
                </div>
                <div className='profile-edit-button' onClick={() => setModalOpen(true)} />
            </div>
            <CustomModal show={modalOpen} setShow={setModalOpen} title={`${editedProfile.firstName} ${editedProfile.lastName}`}>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CustomSearchInput
                                className={`public ${error && error.missingFields.includes('civility') ? 'error' : ''}`}
                                placeholder='Civilité'
                                entity={constants.civilities}
                                value={constants.civilities.find(c => c.value === editedProfile.civility)}
                                onChange={(civility) => setEditedProfile({...editedProfile, civility: civility.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Prénom"
                                variant="outlined"
                                error={error && error.missingFields.includes('firstName')}
                                value={editedProfile.firstName}
                                onChange={(e) => setEditedProfile({...editedProfile, firstName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nom"
                                variant="outlined"
                                error={error && error.missingFields.includes('lastName')}
                                value={editedProfile.lastName}
                                onChange={(e) => setEditedProfile({...editedProfile, lastName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nationalité"
                                variant="outlined"
                                error={error && error.missingFields.includes('nationality')}
                                value={editedProfile.nationality}
                                onChange={(e) => setEditedProfile({...editedProfile, nationality: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Téléphone"
                                variant="outlined"
                                error={error && error.missingFields.includes('phone')}
                                value={editedProfile.phone}
                                onChange={(e) => setEditedProfile({...editedProfile, phone: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                format='DD/MM/YYYY'
                                label='Date de naissance *'
                                slotProps={{
                                    textField: {
                                        error: error && error.missingFields.includes('birthDate')
                                    },
                                }}
                                value={dayjs(editedProfile.birthDate)}
                                disableFuture
                                onChange={(newDate) => setEditedProfile({...editedProfile, birthDate: newDate.format('YYYY-MM-DD') })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Adresse"
                                variant="outlined"
                                error={error && error.missingFields.includes('address')}
                                value={editedProfile.address}
                                onChange={(e) => setEditedProfile({...editedProfile, address: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Code postal"
                                variant="outlined"
                                error={error && error.missingFields.includes('postalCode')}
                                value={editedProfile.postalCode}
                                onChange={(e) => setEditedProfile({...editedProfile, postalCode: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Ville"
                                variant="outlined"
                                error={error && error.missingFields.includes('city')}
                                value={editedProfile.city}
                                onChange={(e) => setEditedProfile({...editedProfile, city: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='training-selector-block no-margin-bottom flex-view'>
                                <label className="checkbox-container">
                                    <input type="checkbox" checked={editedProfile.disability} onClick={(e) => setEditedProfile({...editedProfile, disability: e.target.checked})}/>
                                    <span className="checkmark"/>
                                </label>
                                <span className='small-note'>Je suis athlète handisport</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                { editedProfile.birthDate && dayjs().diff(editedProfile.birthDate, 'year') < 18 ? (
                    <div className='form-info-container'>
                        <div className='view-subtitle'>
                            <h2>Informations du représentant légal</h2>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Prénom du représentant"
                                    required
                                    variant="outlined"
                                    value={editedProfile.parentFirstName}
                                    error={error && error.missingFields.includes('parentFirstName')}
                                    onChange={(e) => setEditedProfile({...editedProfile, parentFirstName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Nom du représentant"
                                    required
                                    variant="outlined"
                                    error={error && error.missingFields.includes('parentLastName')}
                                    value={editedProfile.parentLastName}
                                    onChange={(e) => setEditedProfile({...editedProfile, parentLastName: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : null }
                { error ? (
                    <div className='error-message column'>
                        <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                        { error.details && error.details.map((detail, index) => (
                            <span key={index}>- {detail}</span>
                        ))}
                    </div>
                ) : null }
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button submit-subcription' onClick={handleUpdate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier'}</button>
                </div>
            </CustomModal>
        </div>
    );
}

export default ProfileEdit;