import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import toastOptions from '../../../assets/utils/toast';
import axios from "axios";
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import GroupSettings from "./GroupSettings";
import {CircularProgress} from "@mui/material";

const GroupAdd = ({appRefresh, setAppRefresh}) => {

    const { token } = useContext(AuthContext);
    const [error, setError] = useState();
    const [group, setGroup] = useState({
        trainingsType: 'optional',
        places: null
    });
    const [rates, setRates] = useState([{
        allowedNb: 1,
        price: 200
    }]);
    const [trainings, setTrainings] = useState([{
        weekDay: 'monday',
        startHour: '12:00:00',
        stopHour: '14:00:00',
        places: 10
    }]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();

    const handleCreate = () => {
        setButtonLoading(true);
        setError(null);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'groups' , {...group, trainings, rates}, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                setButtonLoading(false);
                setAppRefresh(appRefresh + 1);
                toast.success("Le groupe a bien été créé", toastOptions);
                navigate('/admin/groups/' + response.data.id);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    React.useEffect(() => console.log(group), [group]);
    React.useEffect(() => console.log(trainings), [trainings]);

    return (
        <div className='group-add'>
            <div className='view-fixed-title'>
                <h1>Ajouter un groupe</h1>
                <div className='view-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button margin-right'>Annuler</button>
                    <button className='main-button blue-button add' disabled={buttonLoading} onClick={handleCreate}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Ajouter' }</button>
                </div>
            </div>
            <div className='view-container scrollable'>
                { error ? (
                    <div className='error-message'>
                        <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                        { error.details.map((detail, index) => (
                            <span key={index}>- {detail}</span>
                        ))}
                    </div>
                ) : null }
                <GroupSettings
                    group={group}
                    setGroup={setGroup}
                    trainings={trainings}
                    setTrainings={setTrainings}
                    rates={rates}
                    setRates={setRates}
                />
            </div>
        </div>
    );
}

export default GroupAdd;
