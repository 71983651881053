import {CircularProgress, Grid, TextField} from "@mui/material";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import React, {useContext, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/utils/constants";

const ProfileAdd = ({ modalOpen, setModalOpen, appRefresh, setAppRefresh }) => {

    const { token } = useContext(AuthContext);
    const [newProfile, setNewProfile] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [error, setError] = useState();
    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_PUBLIC_API_URL + 'customer/profile', newProfile, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalOpen(false);
                setAppRefresh(appRefresh + 1);
                toast.success("Le profil a bien été ajouté", toastOptions);
            })
            .catch((error) => {
                console.log(error.response.data);
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    return (
        <CustomModal show={modalOpen} setShow={setModalOpen} title='Ajouter un profil'>
            <div className='form-info-container'>
                <div className='view-subtitle'>
                    <h2>Informations générales</h2>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CustomSearchInput
                            className={`public ${error && error.missingFields.includes('civility') ? 'error' : ''}`}
                            placeholder='Civilité'
                            entity={constants.civilities}
                            value={constants.civilities.find(c => c.value === newProfile.civility)}
                            onChange={(civility) => setNewProfile({...newProfile, civility: civility.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Prénom"
                            variant="outlined"
                            error={error && error.missingFields.includes('firstName')}
                            value={newProfile.firstName}
                            onChange={(e) => setNewProfile({...newProfile, firstName: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nom"
                            variant="outlined"
                            error={error && error.missingFields.includes('lastName')}
                            value={newProfile.lastName}
                            onChange={(e) => setNewProfile({...newProfile, lastName: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nationalité"
                            variant="outlined"
                            error={error && error.missingFields.includes('nationality')}
                            value={newProfile.nationality}
                            onChange={(e) => setNewProfile({...newProfile, nationality: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Téléphone"
                            variant="outlined"
                            error={error && error.missingFields.includes('phone')}
                            value={newProfile.phone}
                            onChange={(e) => setNewProfile({...newProfile, phone: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            format='DD/MM/YYYY'
                            label='Date de naissance *'
                            slotProps={{
                                textField: {
                                    error: error && error.missingFields.includes('birthDate')
                                },
                            }}
                            disableFuture
                            onChange={(newDate) => setNewProfile({...newProfile, birthDate: newDate.format('YYYY-MM-DD') })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Adresse"
                            variant="outlined"
                            error={error && error.missingFields.includes('address')}
                            value={newProfile.address}
                            onChange={(e) => setNewProfile({...newProfile, address: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Code postal"
                            variant="outlined"
                            error={error && error.missingFields.includes('postalCode')}
                            value={newProfile.postalCode}
                            onChange={(e) => setNewProfile({...newProfile, postalCode: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Ville"
                            variant="outlined"
                            error={error && error.missingFields.includes('city')}
                            value={newProfile.city}
                            onChange={(e) => setNewProfile({...newProfile, city: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='training-selector-block no-margin-bottom flex-view'>
                            <label className="checkbox-container">
                                <input type="checkbox" checked={newProfile.disability} onClick={(e) => setNewProfile({...newProfile, disability: e.target.checked})}/>
                                <span className="checkmark"/>
                            </label>
                            <span className='small-note'>Je suis athlète handisport</span>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {newProfile.birthDate && dayjs().diff(newProfile.birthDate, 'year') < 18 ? (
                <div className='form-info-container'>
                    <div className='view-subtitle'>
                        <h2>Informations du représentant légal</h2>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Prénom du représentant"
                                required
                                variant="outlined"
                                value={newProfile.parentFirstName}
                                error={error && error.missingFields.includes('parentFirstName')}
                                onChange={(e) => setNewProfile({...newProfile, parentFirstName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nom du représentant"
                                required
                                variant="outlined"
                                error={error && error.missingFields.includes('parentLastName')}
                                value={newProfile.parentLastName}
                                onChange={(e) => setNewProfile({...newProfile, parentLastName: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </div>
            ) : null }
            { error ? (
                <div className='error-message'>
                    <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                    { error.details && error.details.map((detail, index) => (
                        <span key={index}>- {detail}</span>
                    ))}
                </div>
            ) : null }
            <div className='modal-bottom-button'>
                <button className='main-button blue-button submit-subcription' onClick={handleCreate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Ajouter'}</button>
            </div>
        </CustomModal>
    );
}

export default ProfileAdd;