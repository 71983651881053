import {CircularProgress, Grid, IconButton} from "@mui/material";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React, {useContext, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import CustomField from "../../custom/CustomField";

const FieldEdit = ({activeSubscription, fields, appRefresh, setAppRefresh}) => {

    const [subscription, setSubscription] = useState(activeSubscription);
    const [error, setError] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { token} = useContext(AuthContext);

    const handleUpdate = () => {
        setIsLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id, { fields: subscription.fields }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setIsLoading(false);
                toast.success("Les informations complémentaires ont bien été modifiées", toastOptions);
                setIsEditing(false);
                setAppRefresh(appRefresh + 1);
            })
            .catch(() => {
                toast.error("Une erreur est survenue", toastOptions);
                setIsLoading(false);
                setIsEditing(false);
            });
    }

    const handleChangeFieldValue = (field, newValue) => {
        const newFields = [...subscription.fields].filter(f => f.fieldId !== field.id);
        setSubscription({...subscription, fields: [...newFields, {fieldId: field.id, value: newValue }]})
    }

    return (
        <div>
            <div className='subscription-line field margin-bottom'>
                <span className='subscription-line-title'>Informations complémentaires</span>
                { isEditing ? (
                    <div>
                        { isLoading ? (
                            <CircularProgress size={10} />
                        ) : (
                            <>
                                <IconButton aria-label="delete" size="small" onClick={handleUpdate}>
                                    <DoneRoundedIcon fontSize="small" />
                                </IconButton>
                                <IconButton aria-label="delete" size="small" onClick={() => {
                                    setIsEditing(false);
                                    setSubscription(activeSubscription);
                                }}>
                                    <ClearRoundedIcon fontSize="small" />
                                </IconButton>
                            </>
                        )}
                    </div>
                ) : (
                    <IconButton aria-label="delete" size="small" onClick={() => setIsEditing(true)}>
                        <ModeEditOutlineRoundedIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
            { isEditing ? (
                <Grid container spacing={2}>
                    { fields.map((field, index) => (
                        <Grid item xs={12} md={6}>
                            <CustomField
                                field={field}
                                key={index}
                                value={subscription.fields.some(f => f.fieldId === field.id) ? subscription.fields.find(f => f.fieldId === field.id).value : null}
                                error={error && error.missingFields.some(f => f.id === field.id)}
                                setField={handleChangeFieldValue}
                            />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    { fields.map((field, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <CustomField
                                size='small'
                                field={field}
                                value={subscription.fields.some(f => f.fieldId === field.id) ? subscription.fields.find(f => f.fieldId === field.id).value : null}
                                readOnly
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    )
}

export default FieldEdit;