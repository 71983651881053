import {
    CircularProgress,
    IconButton,
    Tab,
    Tabs,
    Tooltip
} from "@mui/material";
import React, {useContext, useState} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import CustomTag from "../../custom/CustomTag";
import training_logo from "../../../assets/icons/calendar-day--blue.svg";
import subscription_logo from "../../../assets/icons/shopping-bag--blue.svg";
import home_logo from "../../../assets/icons/home--blue.svg";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import columns from "../../../assets/utils/columns";
import CustomList from "../../custom/CustomList";
import {CirclePicker} from "react-color";
import toastOptions from "../../../assets/utils/toast";
import GroupIcon from "./GroupIcon";
import PlanningView from "../planning/PlanningView";
import GroupHome from "./GroupHome";

const GroupView = ({ groups, appRefresh, setAppRefresh }) => {

    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0);
    const [isLoaded, setIsLoaded] = useState(false);
    const { user, token } = useContext(AuthContext);
    const navigate = useNavigate();
    const groupWaitingSubscriptions = React.useMemo(() => columns.groupWaitingSubscriptions, []);


    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'groups/' + groupId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data && response.data.seasonId === user.activeSeason) {
                    setGroup(response.data);
                    setIsLoaded(true);
                } else {
                    navigate(groups.length > 0 ? '/admin/groups/' + groups[0].id : '/admin');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, groupId]);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce groupe seront perdues.")) {
            toast.promise(
                axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'groups/' + groupId, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Groupe supprimé',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setAppRefresh(appRefresh + 1);
            });
        }
    }

    const handleChangeColor = (e) => {
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'groups/' + group.id + '/color', { color: e.hex }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setAppRefresh(appRefresh + 1);
                toast.success("La couleur a bien été modifiée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    return isLoaded ? (
        <div className='admin-group-view'>
            <div className='view-header'>
                <div className='view-title group'>
                    <GroupIcon width={22} color={group.color}/>
                    <h1>{group.name}</h1>
                    <Tooltip leaveDelay={800} placement="bottom" id='options-tooltip' title={
                        <React.Fragment>
                            <>
                                <div className='color-container'>
                                    <CirclePicker circleSize={20} width={180} circleSpacing={10}
                                                  onChange={handleChangeColor}/>
                                </div>
                                <div className='tooltip-container'>
                                    <Link to={`/groups/${group.id}`} className='view'>Voir la page du groupe</Link>
                                    <Link to='edit' className='edit'>Modifier le groupe</Link>
                                    <span className='delete' onClick={handleDelete}>Supprimer le groupe</span>
                                </div>
                            </>
                        </React.Fragment>
                    }>
                        <IconButton aria-label="delete" size="small">
                            <MoreVertRoundedIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='view-header-tabs'>
                    <Tabs value={tab} onChange={(e, newValue) => {
                        setTab(newValue);
                        setSearchParams({tab: newValue})
                    }}>
                        <Tab
                            value={0}
                            icon={<img src={home_logo} alt='logo' width={16}/>}
                            iconPosition="start"
                            label="Accueil"
                        />
                        <Tab
                            value={1}
                            icon={<img src={training_logo} alt='logo' width={16}/>}
                            iconPosition="start"
                            label="Planning"
                        />
                        <Tab
                            value={2}
                            icon={
                                <div>
                                    <img src={subscription_logo} alt='logo' width={16}/>
                                    <span
                                        className={'tab-badge ' + (group.subscriptions.filter(sub => sub.status !== 'refused' && sub.status !== 'accepted').length > 0 ? 'error' : 'success')}>{group.subscriptions.filter(sub => sub.status !== 'refused' && sub.status !== 'accepted').length}</span>
                                </div>
                            }
                            iconPosition="start"
                            label="Inscriptions"
                            className='notification-tab'
                        />
                    </Tabs>
                </div>
            </div>
            <div role="tabpanel" hidden={tab !== 0}>
                <GroupHome group={group} />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <PlanningView groups={groups} groupId={groupId} />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <div className="view-container scrollable subscriptions">
                    <div className='view-subtitle'>
                        <div className='flex-view'>
                            <h2>Inscriptions en attente de validation</h2>
                            <CustomTag
                                value={group.subscriptions.filter(sub => sub.status === "waiting-validation").length }
                                className="margin-left"/>
                        </div>
                    </div>
                    { group.subscriptions.filter(sub => sub.status === "waiting-validation").length > 0 ? (
                        <CustomList
                            columns={groupWaitingSubscriptions}
                            intermediatePath={'/admin/subscriptions'}
                            entity={group.subscriptions.filter(sub => sub.status === "waiting-validation")}
                            isSelectable={false}
                            isPaginable={group.subscriptions.filter(sub => sub.status === "waiting-validation").length > 20}
                        />
                    ) : (
                        <span className='empty-info'>Vous n'avez aucune inscription en attente de validation pour le moment.</span>
                    )}
                    <div className='view-subtitle'>
                        <div className='flex-view'>
                            <h2>Inscriptions en attente de test</h2>
                            <CustomTag
                                value={group.subscriptions.filter(sub => sub.status === "waiting-test").length}
                                className="margin-left"/>
                        </div>
                    </div>
                    { group.subscriptions.filter(sub => sub.status === "waiting-test").length > 0 ? (
                        <CustomList
                            columns={groupWaitingSubscriptions}
                            intermediatePath={'/admin/subscriptions'}
                            entity={group.subscriptions.filter(sub => sub.status === "waiting-test")}
                            isSelectable={false}
                            isPaginable={group.subscriptions.filter(sub => sub.status === "waiting-test").length > 20}
                        />
                    ) : (
                        <span className='empty-info'>Vous n'avez aucune inscription en attente de validation pour le moment.</span>
                    )}
                    <div className='view-subtitle'>
                        <div className='flex-view'>
                            <h2>Inscriptions en attente de paiement</h2>
                            <CustomTag
                                value={group.subscriptions.filter(sub => sub.status === "waiting-payment").length}
                                className="margin-left"/>
                        </div>
                    </div>
                    {group.subscriptions.filter(sub => sub.status === "waiting-payment").length > 0 ? (
                        <CustomList
                            columns={groupWaitingSubscriptions}
                            intermediatePath={'/admin/subscriptions'}
                            entity={group.subscriptions.filter(sub => sub.status === "waiting-payment")}
                            isSelectable={false}
                            isPaginable={group.subscriptions.filter(sub => sub.status === "waiting-payment").length > 20}
                        />
                    ) : (
                        <span className='empty-info'>Vous n'avez aucune inscription en attente de paiement pour le moment.</span>
                    )}
                    <div className='view-subtitle'>
                        <div className='flex-view'>
                            <h2>Inscriptions en attente de changement</h2>
                            <CustomTag value={group.subscriptions.filter(sub => sub.status === "change-needed").length}
                                       className="margin-left"/>
                        </div>
                    </div>
                    {group.subscriptions.filter(sub => sub.status === "change-needed").length > 0 ? (
                        <CustomList
                            columns={groupWaitingSubscriptions}
                            intermediatePath={'/admin/subscriptions'}
                            entity={group.subscriptions.filter(sub => sub.status === "change-needed")}
                            isSelectable={false}
                            isPaginable={group.subscriptions.filter(sub => sub.status === "change-needed").length > 20}
                        />
                    ) : (
                        <span className='empty-info'>Vous n'avez aucune inscription en attente de changement pour le moment.</span>
                    )}
                    <div className='view-subtitle'>
                        <div className='flex-view'>
                            <h2>Inscriptions refusées</h2>
                            <CustomTag value={group.subscriptions.filter(sub => sub.status === "refused").length}
                                       className="margin-left"/>
                        </div>
                    </div>
                    {group.subscriptions.filter(sub => sub.status === "refused").length > 0 ? (
                        <CustomList
                            columns={groupWaitingSubscriptions}
                            intermediatePath={'/admin/subscriptions'}
                            entity={group.subscriptions.filter(sub => sub.status === "refused")}
                            isSelectable={false}
                            isPaginable={group.subscriptions.filter(sub => sub.status === "refused").length > 20}
                        />
                    ) : (
                        <span className='empty-info'>Vous n'avez aucune inscription refusée pour le moment.</span>
                    )}
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30}/></div>)
}

export default GroupView;