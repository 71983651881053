import axios from "axios";
import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CustomRichText from "../../custom/CustomRichText";
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";
import {Link} from "react-router-dom";

const GroupDetails = ({ groupId, open, setOpen }) => {

    const [group, setGroup] = useState(null);

    useEffect(() => {
        if(groupId) {
            axios.get(process.env.REACT_APP_PUBLIC_API_URL + `planning/groups/${groupId}`)
                .then((response) => {
                    setGroup(response.data);
                })
                .catch((error) => console.log(error));
        }
    }, [groupId]);

    return (
        <div className={`planning-public-details ${open ? null : 'closed'}`}>
            { group ? (
                <div>
                    <div className='custom-modal-header'>
                        <h1>{group.name}</h1>
                        <button className='modal-close' onClick={() => setOpen(false)}>+</button>
                    </div>
                    <div className='planning-group-content'>
                        <div className='group-details'>
                            {group.description ? (
                                <CustomRichText
                                    value={group.description}
                                    setValue={() => {
                                    }}
                                    readMode
                                />
                            ) : null}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className='view-subtitle calendar'>
                                        <h2>Séances</h2>
                                    </div>
                                    <div className='group-trainings'>
                                        <TableContainer>
                                            <Table size="small" aria-label="Entrainements">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Jour</TableCell>
                                                        <TableCell>Horaires</TableCell>
                                                        <TableCell className='hide-mobile'>Piscine</TableCell>
                                                        <TableCell>Places</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    { group.trainings.map((training, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th"
                                                                       scope="row">{constants.weekDays.find(constant => constant.value === training.weekDay).label}</TableCell>
                                                            <TableCell>{dayjs(dayjs().format('YYYY-MM-DD ' + training.startHour)).format('HH:mm')} à {dayjs(dayjs().format('YYYY-MM-DD ' + training.stopHour)).format('HH:mm')}</TableCell>
                                                            <TableCell
                                                                className="hide-mobile">{training.pool.name}</TableCell>
                                                            {group.trainingsType === 'mandatory' ? index === 0 ? (
                                                                <TableCell rowSpan={group.trainings.length}
                                                                           className='border-left'>
                                                                    <div className='flex-view'>{group.places}</div>
                                                                </TableCell>
                                                            ) : null : <TableCell>{training.places}</TableCell>}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='view-subtitle price'>
                                        <h2>Tarifs</h2>
                                    </div>
                                    <TableContainer>
                                        <Table size="small" aria-label="Tarifs">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Séance(s)</TableCell>
                                                    <TableCell>Tarif</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {group.trainingsType === 'optional' ? (
                                                <TableBody>
                                                    {group.rates.map((rate) => (
                                                        <TableRow key={rate.id}>
                                                            <TableCell component="th"
                                                                       scope="row">{rate.allowedNb} séance(s)</TableCell>
                                                            <TableCell>{rate.price}€</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {group.rates.map((rate) => (
                                                        <TableRow key={rate.id}>
                                                            <TableCell component="th"
                                                                       scope="row">{group.trainings.length} séance(s)</TableCell>
                                                            <TableCell>{rate.price}€</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Link to={`/groups/${group.id}`}>
                                <button className='main-button blue-button'>M'inscrire à ce groupe</button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <CircularProgress size={30}/>
            )}
        </div>
    );
}

export default GroupDetails;