import React, {useContext} from "react";
import {IconButton} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CustomTag from "../../custom/CustomTag";
import {Link} from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";

const Option = ({ innerProps, label, ...props }) => {

    const { user } = useContext(AuthContext);

    switch (props.data.resultType) {
        case 'customer':
            const customer = props.data;
            return (
                <div {...innerProps} className='searchbar-option'>
                    <Link to={`/admin/customers/${customer.id}`} className='searchbar-option-content'>
                        <span className='searchbar-data name'>{customer.firstName} {customer.lastName}</span>
                        <span className='searchbar-data email'>{customer.email}</span>
                        <div className='searchbar-searchtype'>
                            <span className='customer'>Compte</span>
                        </div>
                    </Link>
                </div>
            );
        case 'profile':
            const profile = props.data;
            return (
                <div {...innerProps} className='searchbar-option'>
                    <Link to={`/admin/profiles/${profile.id}`} className='searchbar-option-content'>
                        <span className='searchbar-data name'>{profile.firstName} {profile.lastName}</span>
                        <span className='searchbar-data email'>{profile.customer.email}</span>
                        { profile.parentFirstName ? (
                            <span className='searchbar-data parent'>Resp. légal : {profile.parentFirstName} {profile.parentLastName}</span>
                        ) : null }
                        { profile.subscriptions.filter(s => s.group.seasonId === user.activeSeason).length > 0 ? (
                            <div className='searchbar-option-subscriptions'>
                                { profile.subscriptions.filter(s => s.group.seasonId === user.activeSeason).map((subscription, index) => (
                                    <div className="searchbar-option-subscription" key={index}>
                                        <div className='searchbar-option-subscription-title'>
                                            <span className='searchbar-data subscription'>{subscription.group.name}</span>
                                            <Link to={`/admin/subscriptions/${subscription.id}`}>
                                                <IconButton aria-label="delete" size="small">
                                                    <VisibilityRoundedIcon fontSize="small" />
                                                </IconButton>
                                            </Link>
                                        </div>
                                        <CustomTag value={subscription.status} />
                                    </div>
                                ))}
                            </div>
                        ) : null }
                        <div className='searchbar-searchtype'>
                            { profile.federalId ? (
                                <span className='federal-id'>IUF {profile.federalId}</span>
                            ) : null }
                            <span className='customer'>Profil</span>
                        </div>
                    </Link>
                </div>
            );
    }
}

export default Option;