import React from "react";
import CustomTag from "../../custom/CustomTag";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import dayjs from "dayjs";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CustomRichText from "../../custom/CustomRichText";
import HelpIcon from "@mui/icons-material/Help";
import CustomSearchInput from "../../custom/CustomSearchInput";
import CustomWarningModal from "../../custom/CustomWarningModal";

const SeasonSettings = ({ appRefresh, setAppRefresh }) => {

    const [seasons, setSeasons] = React.useState();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [modalAddOpen, setModalAddOpen] = React.useState(false);
    const [modalEditOpen, setModalEditOpen] = React.useState(false);
    const [newSeason, setNewSeason] = React.useState({
        startDate: dayjs().format('YYYY-MM-DD'),
        stopDate: dayjs().add(1, 'year').format('YYYY-MM-DD'),
        subscriptionStart: dayjs().format('YYYY-MM-DD'),
        subscriptionStop: dayjs().add(1, 'year').format('YYYY-MM-DD')
    });
    const [selectedSeason, setSelectedSeason] = React.useState();
    const [editedSeason, setEditedSeason] = React.useState();
    const [modalWarningOpen, setModalWarningOpen] = React.useState(false);
    const [modalButtonLoading, setModalButtonLoading] = React.useState(false);
    const [error, setError] = React.useState();
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const { token, club } = React.useContext(AuthContext);

    React.useEffect(() => {
        setIsLoaded(false);
        setSeasons(null);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'seasons', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setSeasons(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => console.log(error));
    }, [token]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'seasons', newSeason, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalAddOpen(false);
                setAppRefresh(appRefresh + 1);
                setError(null);
                toast.success("La saison a bien été créée", toastOptions);
            })
            .catch((error) => {
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    const handleDelete = (season) => {
        setSelectedSeason(season);
        setModalWarningOpen(true);
    }

    const handleActivate = (season) => {
        axios.put(process.env.REACT_APP_ADMIN_API_URL + `seasons/${season.id}/activate`, {}, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setAppRefresh(appRefresh + 1);
                toast.success("La saison a bien été activée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    const handleConfirmAction = () => {
        setModalButtonLoading(true);
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + `seasons/${selectedSeason.id}`, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                setAppRefresh(appRefresh + 1);
                toast.success("La saison a bien été supprimée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + `seasons/${editedSeason.id}`, editedSeason, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalEditOpen(false);
                setAppRefresh(appRefresh + 1);
                setError(null);
                setEditedSeason(null);
                toast.success("La saison a bien été modifiée", toastOptions);
            })
            .catch((error) => {
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div>
            <div className='view-subtitle season'>
                <h2>Configuration des saisons</h2>
                <button className='main-button blue-button' onClick={() => setModalAddOpen(true)}>Créer une saison</button>
            </div>
            { seasons.length > 0 ? (
                <>
                    { seasons.map((season, index) => (
                        <div className='entity-add-container'>
                            <div className='training-edit-block without-separation' key={index}>
                                <div className='entity-with-description'>
                                    <div className='flex-view'>
                                        <div className='view-subtitle season margin-right'>
                                            <h2>{season.name}</h2>
                                            <CustomTag
                                                className={`margin-left ${club.activeSeasonId === season.id ? "active" : "inactive"}`}
                                                value={club.activeSeasonId === season.id ? "Active" : "Inactive"}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        setEditedSeason(season);
                                        setModalEditOpen(true);
                                    }}>
                                        <ModeEditOutlineRoundedIcon fontSize="small"/>
                                    </IconButton>
                                    {club.activeSeasonId !== season.id ? (
                                        <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                            <React.Fragment>
                                                <div className='tooltip-container'>
                                                    <span className='check' onClick={() => handleActivate(season)}>Activer la saison</span>
                                                    <span className='delete' onClick={() => handleDelete(season)}>Supprimer la saison</span>
                                                </div>
                                            </React.Fragment>
                                        }>
                                            <IconButton aria-label="delete" size="small">
                                                <MoreVertRoundedIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                </div>
                            </div>
                            <div className='view-span calendar'>
                                <h3>Début et fin :</h3>
                                <CustomTag value={dayjs(season.startDate).format('DD MMMM YYYY')}/>
                                <span>au</span>
                                <CustomTag value={dayjs(season.stopDate).format('DD MMMM YYYY')}/>
                            </div>
                            <div className='view-span calendar'>
                                <h3>Ouverture des inscriptions :</h3>
                                <CustomTag value={dayjs(season.subscriptionStart).format('DD MMMM YYYY')}/>
                                <span>au</span>
                                <CustomTag value={dayjs(season.subscriptionStop).format('DD MMMM YYYY')}/>
                            </div>
                        </div>
                    ))}
                    <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Créer une nouvelle saison'
                                 className="no-padding">
                        <div className='view-subtitle flex-start refresh'>
                            <h2 className='margin-right'>Importer une saison</h2>
                            <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                <React.Fragment>
                                    <CustomRichText
                                        value="Vous pouvez importer l'intégralité des éléments d'une saison dans votre nouvelle saison, sauf les inscriptions."
                                        readMode className="padding"/>
                                </React.Fragment>
                            }>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='input-row-container'>
                            <CustomSearchInput
                                placeholder="Rechercher une saison"
                                labelType="season"
                                entity={seasons}
                                value={newSeason.importedSeason}
                                valueIsId
                                isClearable
                                onChange={(value) => setNewSeason({...newSeason, importedSeason: value ? value.id : null })}
                            />
                        </div>
                        <div className='view-subtitle flex-start calendar'>
                            <h2 className='margin-right'>Dates de début et de fin</h2>
                            <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                <React.Fragment>
                                    <CustomRichText
                                        value={`Choisissez les dates de validité de la saison. La saison sera nommée en fonction des dates choisies (ici, <strong>Saison ${dayjs(newSeason.startDate).format('YYYY')}/${dayjs(newSeason.stopDate).format('YYYY')}</strong>)`}
                                        readMode className="padding"/>
                                </React.Fragment>
                            }>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='input-row-container'>
                            <DatePicker
                                format='DD/MM/YYYY'
                                value={dayjs(newSeason.startDate)}
                                maxDate={dayjs(newSeason.stopDate).subtract(1, 'day')}
                                onChange={(date) => setNewSeason({
                                    ...newSeason,
                                    startDate: dayjs(date).format('YYYY-MM-DD')
                                })}
                            />
                            <span className='margin-left margin-right'>au</span>
                            <DatePicker
                                format='DD/MM/YYYY'
                                value={dayjs(newSeason.stopDate)}
                                minDate={dayjs(newSeason.startDate).add(1, 'day')}
                                onChange={(date) => setNewSeason({
                                    ...newSeason,
                                    stopDate: dayjs(date).format('YYYY-MM-DD')
                                })}
                            />
                        </div>
                        <div className='view-subtitle flex-start calendar'>
                            <h2 className='margin-right'>Dates d'ouverture des inscriptions</h2>
                            <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                <React.Fragment>
                                    <CustomRichText
                                        value="Plage de dates pendant laquelle les inscriptions seront disponibles. En dehors de ces dates, les utilisateurs ne pourront pas s'inscrire aux différents groupes."
                                        readMode className="padding"/>
                                </React.Fragment>
                            }>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='input-row-container'>
                            <DatePicker
                                format='DD/MM/YYYY'
                                value={dayjs(newSeason.subscriptionStart)}
                                maxDate={dayjs(newSeason.subscriptionStop).subtract(1, 'day')}
                                onChange={(date) => setNewSeason({
                                    ...newSeason,
                                    subscriptionStart: dayjs(date).format('YYYY-MM-DD')
                                })}
                            />
                            <span className='margin-left margin-right'>au</span>
                            <DatePicker
                                format='DD/MM/YYYY'
                                value={dayjs(newSeason.subscriptionStop)}
                                minDate={dayjs(newSeason.subscriptionStart).add(1, 'day')}
                                onChange={(date) => setNewSeason({
                                    ...newSeason,
                                    subscriptionStop: dayjs(date).format('YYYY-MM-DD')
                                })}
                            />
                        </div>
                        { error ? (
                            <div className='error-message'>
                                <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                            </div>
                        ) : null }
                        <div className='modal-bottom-button'>
                            <button className='main-button without-icon margin-right'
                                    onClick={() => setModalAddOpen(false)}>Annuler
                            </button>
                            <button className='main-button blue-button launch' onClick={handleCreate}
                                    disabled={buttonLoading}>{buttonLoading ?
                                <CircularProgress className='white-loader' size={20}/> : 'Créer la saison'}</button>
                        </div>
                    </CustomModal>
                    <CustomModal show={modalEditOpen} setShow={setModalEditOpen} title={editedSeason?.name} className="no-padding">
                        { editedSeason ? (
                            <>
                                <div className='view-subtitle flex-start calendar'>
                                    <h2 className='margin-right'>Dates de début et de fin</h2>
                                    <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                        <React.Fragment>
                                            <CustomRichText
                                                value={`Choisissez les dates de validité de la saison. La saison sera nommée en fonction des dates choisies (ici, <strong>Saison ${dayjs(newSeason.startDate).format('YYYY')}/${dayjs(newSeason.stopDate).format('YYYY')}</strong>)`}
                                                readMode className="padding"/>
                                        </React.Fragment>
                                    }>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className='input-row-container'>
                                    <DatePicker
                                        format='DD/MM/YYYY'
                                        value={dayjs(editedSeason.startDate)}
                                        maxDate={dayjs(editedSeason.stopDate).subtract(1, 'day')}
                                        onChange={(date) => setEditedSeason({
                                            ...editedSeason,
                                            startDate: dayjs(date).format('YYYY-MM-DD')
                                        })}
                                    />
                                    <span className='margin-left margin-right'>au</span>
                                    <DatePicker
                                        format='DD/MM/YYYY'
                                        value={dayjs(editedSeason.stopDate)}
                                        minDate={dayjs(editedSeason.startDate).add(1, 'day')}
                                        onChange={(date) => setEditedSeason({
                                            ...editedSeason,
                                            stopDate: dayjs(date).format('YYYY-MM-DD')
                                        })}
                                    />
                                </div>
                                <div className='training-selector-block'>
                                    <label className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            checked={editedSeason?.generatePlanning}
                                            onClick={(event) => setEditedSeason({
                                                ...editedSeason,
                                                generatePlanning: event.target.checked
                                            })}
                                        />
                                        <span className="checkmark"/>
                                    </label>
                                    <small>Générer de nouveau le planning de la saison</small>
                                    <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                        <React.Fragment>
                                            <CustomRichText
                                                value="Si cette option est cochée, un nouveau planning sera généré pour cette saison avec les nouvelles dates renseignées."
                                                readMode className="padding"/>
                                        </React.Fragment>
                                    }>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className='view-subtitle flex-start calendar'>
                                    <h2 className='margin-right'>Dates d'ouverture des inscriptions</h2>
                                    <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                        <React.Fragment>
                                            <CustomRichText
                                                value="Plage de dates pendant laquelle les inscriptions seront disponibles. En dehors de ces dates, les utilisateurs ne pourront pas s'inscrire aux différents groupes."
                                                readMode className="padding"/>
                                        </React.Fragment>
                                    }>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className='input-row-container'>
                                    <DatePicker
                                        format='DD/MM/YYYY'
                                        value={dayjs(editedSeason.subscriptionStart)}
                                        maxDate={dayjs(editedSeason.subscriptionStop).subtract(1, 'day')}
                                        onChange={(date) => setEditedSeason({
                                            ...editedSeason,
                                            subscriptionStart: dayjs(date).format('YYYY-MM-DD')
                                        })}
                                    />
                                    <span className='margin-left margin-right'>au</span>
                                    <DatePicker
                                        format='DD/MM/YYYY'
                                        value={dayjs(editedSeason.subscriptionStop)}
                                        minDate={dayjs(editedSeason.subscriptionStart).add(1, 'day')}
                                        onChange={(date) => setEditedSeason({
                                            ...editedSeason,
                                            subscriptionStop: dayjs(date).format('YYYY-MM-DD')
                                        })}
                                    />
                                </div>
                                {error ? (
                                    <div className='error-message'>
                                        <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                                    </div>
                                ) : null}
                                <div className='modal-bottom-button'>
                                    <button className='main-button without-icon margin-right'
                                            onClick={() => setModalEditOpen(false)}>Annuler
                                    </button>
                                    <button className='main-button blue-button launch' onClick={handleUpdate}
                                            disabled={buttonLoading}>{buttonLoading ?
                                        <CircularProgress className='white-loader'
                                                          size={20}/> : 'Modifier la saison'}</button>
                                </div>
                            </>
                        ) : null}
                    </CustomModal>
                    <CustomWarningModal
                        content={`Voulez vous vraiment supprimer la saison ${selectedSeason ? selectedSeason.name : null} ? Cela entrainera la perte de toutes ses inscriptions et de ses groupes.`}
                        modalOpen={modalWarningOpen}
                        setModalOpen={setModalWarningOpen}
                        buttonLoading={modalButtonLoading}
                        confirm={handleConfirmAction}
                    />
                </>
            ) : (
                <CustomTag value="Ajoutez un groupe pour gérer son planning !"/>
            )}
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default SeasonSettings;