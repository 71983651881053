import {useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress} from "@mui/material";
import CustomTag from "../../custom/CustomTag";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";

const SubscriptionGroupUpdate = ({ groups, appRefresh, setAppRefresh }) => {

    const { subscriptionId } = useParams();
    const { token } = useContext(AuthContext);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [newGroup, setNewGroup] = useState();
    const [newTrainings, setNewTrainings] = useState([]);
    const [newGroupLoaded, setNewGroupLoaded] = useState(true);

    const handleSubmit = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscriptionId + '/group-update',{
            groupId: newGroup.id,
            subscriptionId: subscriptionId,
            trainings: newTrainings
        }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                toast.success("Modifications effectuées", toastOptions);
                setAppRefresh(appRefresh + 1);
            })
            .catch((error) => console.log(error));
    }

    const changeNewGroup = group => {
        if(group) {
            setNewGroupLoaded(false);
            setNewTrainings([]);
            setNewGroup(null);
            axios.get(process.env.REACT_APP_PUBLIC_API_URL + 'groups/' + group.id)
                .then(response => {
                    if(response.data) {
                        console.log(response.data);
                        setNewGroup(response.data);
                        setNewGroupLoaded(true);
                    }
                })
                .catch(err => console.log(err));
        }
    }

    const getMaximumAllowedNb = (rates) => {
        return rates.reduce((maxAllowedNb, rate) => {
            return Math.max(maxAllowedNb, rate.allowedNb);
        }, 0);
    };

    return (
        <div>
            <div className='group-update-container new'>
                <div className='view-subtitle group'>
                    <h2>Changer de groupe ou de séance</h2>
                </div>
                <CustomSearchInput
                    placeholder='Choisir un groupe'
                    entity={groups}
                    labelType='group'
                    onChange={(group) => changeNewGroup(group)}
                />
                { newGroupLoaded ? (
                    <>
                        { newGroup ? (newGroup.trainingsType === 'optional' ? (
                            <div>
                                <div className='view-subtitle calendar'>
                                    <h2>Choisissez vos séances</h2>
                                </div>
                                <div className='training-selector-container'>
                                    { newGroup.trainings.map((training, index) => (
                                        <div key={index} className={'training-selector-block ' + (!newTrainings.some(t => t.id === training.id) && newTrainings.length >= getMaximumAllowedNb(newGroup.rates) ? 'disabled ' : ' ') + (training.places - training.subscriptionsCount <= 0 ? ' disabled' : ' ') }>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    disabled={(!newTrainings.some(t => t.id === training.id) && newTrainings.length >= getMaximumAllowedNb(newGroup.rates)) || (training.places - training.subscriptionsCount <= 0)}
                                                    onClick={(event) => {
                                                        if(event.target.checked) {
                                                            setNewTrainings([...newTrainings, training]);
                                                        } else {
                                                            setNewTrainings([...newTrainings].filter(selectedTraining => selectedTraining.id !== training.id));
                                                        }
                                                    }}
                                                />
                                                <span className="checkmark"/>
                                            </label>
                                            <CustomTag value={constants.all.find(constant => constant.value === training.weekDay).label} />
                                            <span>de</span>
                                            <CustomTag value={dayjs(dayjs().format('YYYY-MM-DD ' + training.startHour)).format('HH:mm')} />
                                            <span>à</span>
                                            <CustomTag value={dayjs(dayjs().format('YYYY-MM-DD ' + training.stopHour)).format('HH:mm')} />
                                            <CustomTag value={training.pool.name} className='pool-info' />
                                            <CustomTag
                                                value={(training.places - training.subscriptionsCount) + ' places disponibles'}
                                                className={ 'pool-info ' + (training.places - training.subscriptionsCount <= 0 ? 'refused' : 'accepted') }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <span>{newGroup.places - newGroup.subscriptionsCount} places disponibles</span>
                        )) : null }
                    </>
                ) : <div className='loader-container'><CircularProgress size={30} /></div> }
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button validate' onClick={handleSubmit} disabled={buttonLoading}>
                        {buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionGroupUpdate;