import React, {useContext, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import {CircularProgress, Grid, IconButton, TextField} from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import toastOptions from "../../../assets/utils/toast";
import dayjs from "dayjs";
import CustomTag from "../../custom/CustomTag";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import NoteEdit from "../subscriptions/NoteEdit";
import constants from "../../../assets/utils/constants";

const ProfileView = ({appRefresh, setAppRefresh}) => {

    const { profileId } = useParams();
    const [profile, setProfile] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'profiles/' + profileId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setProfile(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Profil inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => console.log(error));
    }, [token, profileId]);

    return isLoaded ? (
        <div className='account-view'>
            <div className='view-header customer'>
                <div className='view-title'>
                    <h1>{profile.firstName} {profile.lastName}</h1>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                        <ArrowBackRoundedIcon fontSize="small" />
                    </IconButton>
                    <Link to={`/admin/profiles/${profile.id}/edit`}>
                        <IconButton aria-label="delete" size="small">
                            <ModeEditOutlineRoundedIcon fontSize="small" />
                        </IconButton>
                    </Link>
                </div>
            </div>
            <div className='view-container scrollable'>
                <div className='subscription-line user'>
                    <span className='subscription-line-title'>Compte </span>
                    <Link to={`/admin/customers/${profile.customer.id}`}>
                        <CustomTag value={`${profile.customer.firstName} ${profile.customer.lastName}`} />
                    </Link>
                </div>
                <div className='subscription-line id'>
                    <span className='subscription-line-title'>Identifiant fédéral (IUF) </span>
                    <CustomTag value={profile.federalId} />
                </div>
                <div className='view-subtitle margin-bottom'>
                    <h2>Informations générales</h2>
                </div>
                <div className='form-info-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Civilité" variant="outlined" value={constants.civilities.find(c => c.value === profile.civility) ? constants.civilities.find(c => c.value === profile.civility).label : ''} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Email" variant="outlined" value={profile.customer.email} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Nationalité" variant="outlined" value={profile.nationality} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Téléphone" variant="outlined" value={profile.phone} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Date de naissance" variant="outlined" value={`${dayjs(profile.birthDate).format('DD MMMM YYYY')} (${dayjs().diff(profile.birthDate, 'year')} ans)`} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Adresse" variant="outlined" value={profile.address} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Code postal" variant="outlined" value={profile.postalCode} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Ville" variant="outlined" value={profile.city} />
                        </Grid>
                        { profile.birthDate && dayjs().diff(profile.birthDate, 'year') < 18 ? (
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" InputProps={{readOnly: true}} label="Prénom du représentant" variant="outlined" value={profile.parentFirstName} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField size="small" InputProps={{readOnly: true}} label="Nom du représentant" variant="outlined" value={profile.parentLastName} />
                                </Grid>
                            </>
                        ) : null }
                        <Grid item xs={12} md={6}>
                            <TextField size="small" InputProps={{readOnly: true}} label="Athlète handisport" variant="outlined" value={profile.disability ? "Oui" : "Non"} />
                        </Grid>
                    </Grid>
                </div>
                <div className='form-info-container'>
                    <NoteEdit profile={profile} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />
                </div>
                <div className='view-subtitle file'>
                    <h2>Inscription(s)</h2>
                </div>
                { profile.subscriptions.length > 0 ? profile.subscriptions.map(subscription => (
                    <div className='subscription-profile-recap'>
                        <div className='subscription-profile-recap-lines subscription'>
                            <span className='status'><CustomTag value={subscription.status} /></span>
                            <Link to={`/admin/groups/${subscription.group.id}`} className='group'><CustomTag value={subscription.group.name} /></Link>
                            <span className='date'>{dayjs(subscription.createdAt).format('DD MMMM YYYY à HH:mm')}</span>
                        </div>
                        <div className='right-top-buttons'>
                            <Link to={`/admin/subscriptions/${subscription.id}`}>
                                <IconButton aria-label="delete" size="small">
                                    <VisibilityRoundedIcon fontSize="small" />
                                </IconButton>
                            </Link>
                        </div>
                    </div>
                )) : (
                    <div className='profile-subscription-line'>
                        <div className='profile-subscription-title'>
                            <span>Aucune adhésion enregistrée pour cette saison.</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default ProfileView;