import React, {useContext, useState} from 'react';
import "../../../assets/styles/Login.css";
import {Link, Navigate} from "react-router-dom";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import logo from "../../../assets/images/rounder-logo-white.svg";
import dayjs from "dayjs";
import AuthContext from "../../../contexts/AuthContext";


const PasswordForgot = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const {user, customer, club} = useContext(AuthContext);

    const handleSubmit = event => {

        if(event.type === 'click' || event.key === 'Enter') {

            setLoader(true);

            axios.post(process.env.REACT_APP_PUBLIC_API_URL + "/login/forgot-password", {
                email: email,
                clubId: club.id
            })
                .then(() => {
                    setError("");
                    setEmail("");
                    setSuccess(true);
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false)
                    setError(error.response.data.message);
                });
        }
    }

    if (user || customer) {
        if(customer) {
            return <Navigate to={'/my-account'} />
        } else {
            return <Navigate to={'/admin'} />
        }
    }

    return (
        <div className="main-login forgot-password" onKeyDown={handleSubmit}>
            <div className='login-container'>
                <div className='login-slider-container'>
                    <div className='login-background-container'>
                        <div className='login-logo-container'>
                            <img src={logo} alt='logo' width={200} />
                        </div>
                        <h1>Libérez le potentiel<br/> de votre club de natation.</h1>
                    </div>
                </div>
                <div className='login-right-form'>
                    <div className='form-container'>
                        <h1>Un petit trou de mémoire ?</h1>
                        <h2>Saissez votre email ci-dessous</h2>
                        <span className={'login-message ' + (error ? 'error' : 'hidden')}>{error}</span>
                        { success ? (
                            <span className='login-message'>Votre demande a bien été prise en compte. Vous recevrez votre nouveau mot de passe par email d'ici peu.</span>
                        ) : (
                         <>
                             <input className='input' type='email' id='email' value={email} placeholder='Votre email' onChange={e => setEmail(e.target.value)} />
                             <button className='main-button blue-button' disabled={loader} onClick={handleSubmit}>{!loader ? 'Envoyer' : <CircularProgress size={20} />}</button>
                         </>
                        )}
                        <Link to='/login' className='main-button'>
                            <span>Retour à la connexion</span>
                        </Link>
                        <div className='login-divider' />
                        <div className='login-sign-up'>
                            <span>Pas encore de compte ?</span>
                            <Link to='/sign-up'>S'inscrire</Link>
                        </div>
                    </div>
                    <div className='rights-container'>
                        <span>{dayjs().format('YYYY')} Swimcoach - Tous droits réservés</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordForgot;