import React, {useContext, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import CustomSearchInput from "../../custom/CustomSearchInput";
import toastOptions from '../../../assets/utils/toast';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import axios from "axios";
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress} from "@mui/material";

const TrainingAdd = ({ groups }) => {

    const [queryParameters] = useSearchParams();
    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [training, setTraining] = useState({
        weekDay: 'monday',
        startHour: dayjs(dayjs().format('YYYY-MM-DD 12:00:00')).format('YYYY-MM-DD HH:mm:ss'),
        stopHour: dayjs(dayjs().format('YYYY-MM-DD 13:00:00')).format('YYYY-MM-DD HH:mm:ss'),
        groupId: queryParameters.get('groupId') && groups.some(group => group.id === queryParameters.get('groupId')) ? queryParameters.get('groupId') : null
    });

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'trainings' , training, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("La séance a bien été ajoutée", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.message, toastOptions);
                setButtonLoading(false);
            });
    }

    React.useEffect(() => console.log(training), [training]);

    return (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>Ajouter une séance</h1>
                </div>
                <div className='header-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleCreate} disabled={buttonLoading}>{ buttonLoading ? <CircularProgress size={20} className="white-loader" /> : 'Ajouter la séance' }</button>
                </div>
            </div>
            <div className='input-training without-top'>
                <div className='input-row-container'>
                    <span className='input-label user'>Groupe <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un groupe'
                                           entity={groups}
                                           labelType='group'
                                           value={groups.find(group => group.id === training.groupId)}
                                           isMultiSearch={false}
                                           onChange={(newGroup => setTraining({...training, groupId: newGroup.id }))}
                        />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label pool'>Piscine <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher une piscine'
                                           entity='pools'
                                           labelType='pool'
                                           isMultiSearch={false}
                                           onChange={(newPool => setTraining({...training, poolId: newPool.id }))}
                        />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label calendar'>Date <span className='required'>*</span></span>
                    <span className='margin-right'>Le</span>
                    <select className='classic-select' value={training.weekDay} onChange={(event => setTraining({...training, weekDay: event.target.value}))}>
                        <option value='monday'>Lundi</option>
                        <option value='tuesday'>Mardi</option>
                        <option value='wednesday'>Mercredi</option>
                        <option value='thursday'>Jeudi</option>
                        <option value='friday'>Vendredi</option>
                        <option value='saturday'>Samedi</option>
                        <option value='sunday'>Dimanche</option>
                    </select>
                    <span className='margin-left margin-right'>de</span>
                    <div className='input-date-container margin-right'>
                        <DatePicker dateFormat='HH:mm'
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Début"
                                    timeFormat="HH:mm"
                                    onKeyDown={(e) => e.preventDefault()}
                                    className='classic-input hour-input'
                                    selected={dayjs(training.startHour).toDate()}
                                    onChange={(date) => setTraining({...training, startHour: dayjs(date).format('YYYY-MM-DD HH:mm:ss') })}
                        />
                    </div>
                    <span className='margin-right'>à</span>
                    <div className='input-date-container'>
                        <DatePicker dateFormat='HH:mm'
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Fin"
                                    timeFormat="HH:mm"
                                    onKeyDown={(e) => e.preventDefault()}
                                    className='classic-input hour-input'
                                    selected={dayjs(training.stopHour).toDate()}
                                    onChange={(date) => setTraining({...training, stopHour: dayjs(date).format('YYYY-MM-DD HH:mm:ss')})}
                        />
                    </div>
                </div>
                <div className='input-textarea-container'>
                    <span className='input-label pen'>Description</span>
                    <div className='input-textarea'>
                        <textarea placeholder='Ajoutez une description...' rows={6} value={training.description} onChange={(e) => setTraining({...training, description: e.target.value })}/>
                    </div>
                    <span className='required'>* Champs obligatoires</span>
                </div>
            </div>
        </div>
    );
}

export default TrainingAdd;
