import React, {useContext, useState} from "react";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import defaultProfilePicture from '../../assets/icons/round-user--gradient.svg';
import AuthContext from "../../contexts/AuthContext";
import '../../assets/styles/AdminPanel.css';
import Customers from "./customers/Customers";
import Trainings from "./trainings/Trainings";
import Settings from "./settings/Settings";
import CustomSearchInput from "../custom/CustomSearchInput";
import axios from "axios";
import Groups from "./groups/Groups";
import {CircularProgress, Tooltip} from "@mui/material";
import {toast} from "react-toastify";
import toastOptions from "../../assets/utils/toast";
import {SortingContainer} from "../custom/SortingContainer";
import PoolSettings from "./pools/PoolSettings";
import CategorySettings from "./categories/CategorySettings";
import Subscriptions from "./subscriptions/Subscriptions";
import AsyncSelect from 'react-select/async';
import SearchBarOption from "../admin/searchbar/Option";
import Profiles from "./profiles/Profiles";
import PlanningView from "./planning/PlanningView";
import GroupIcon from "./groups/GroupIcon";
import NoOptionMessage from "./searchbar/NoOptionMessage";
import LoadingMessage from "./searchbar/LoadingMessage";
import Users from "./users/Users";

const AdminPanel = ({ counts, groups, appRefresh, setAppRefresh, setGroups}) => {

    let location = useLocation();
    const adminLocation = location.pathname.replace(process.env.REACT_APP_FRONT_URL + 'admin/', '');
    const { user, club, token, setToken, setUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [groupsSort, setGroupsSort] = useState(false);

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
    }

    const changeActiveSeason = (newSeason) => {
        setUser({...user, activeSeason: newSeason.id });
        setIsLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'users/' + user.id, {activeSeason: newSeason.id },
            { headers: { Authorization: 'Bearer ' + token }}
        ).then(() => {
            setAppRefresh(appRefresh + 1);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const changeGroupPosition = (groups) => {
        setGroups(groups);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'groups/positions', groups.map(group => ({ id: group.id, position: group.position })),
            { headers: { Authorization: 'Bearer ' + token }}
        ).catch(() => toast.error('Une erreur est survenue lors du changement de position', toastOptions));
    };

    const handleSearch = input => new Promise(resolve => {
        if(input.length >= 3) {
            axios.post(process.env.REACT_APP_DATA_API_URL + 'searchbar', { input },
                { headers: { Authorization: 'Bearer ' + token }}
            ).then((response) => {
                resolve(response.data);
            });
        } else {
            resolve(null);
        }
    });

    return user ? (
        <div className='main-app'>
            <div className='main-menu-container'>
                <div className='main-menu'>
                    <div className='app-logo'>
                        <Link to='/admin'>
                            <img src={club.logoUrl} alt='main-logo'/>
                        </Link>
                    </div>
                    <div className='menu-main-items'>
                        <Link to='planning' className='menu-link'>
                            <div className={'menu-sub-item seasons' + (adminLocation.includes('planning') ? ' active' : '')}>
                                <span>Planning</span>
                            </div>
                        </Link>
                        <Link to='subscriptions' className='menu-link'>
                            <div className={'menu-sub-item subscriptions' + (adminLocation.includes('subscriptions') ? ' active' : '')}>
                                <span>Inscriptions</span>
                                { counts.subscriptions > 0 ? (
                                    <small className='menu-sub-item-total error'>{counts.subscriptions}</small>
                                ) : null }
                            </div>
                        </Link>
                        <Link to='customers' className='menu-link'>
                            <div className={'menu-sub-item admins' + (adminLocation.includes('customers') ? ' active' : '')}>
                                <span>Utilisateurs</span>
                                { counts.customers > 0 ? (
                                    <small className='menu-sub-item-total'>{counts.customers}</small>
                                ) : null }
                            </div>
                        </Link>
                        <Link to='categories' className='menu-link'>
                            <div className={'menu-sub-item categories' + (adminLocation.includes('categories') ? ' active' : '')}>
                                <span>Catégories</span>
                            </div>
                        </Link>
                        <Link to='pools' className='menu-link'>
                            <div className={'menu-sub-item pools' + (adminLocation.includes('pools') ? ' active' : '')}>
                                <span>Piscines</span>
                            </div>
                        </Link>
                        <Link to='settings' className='menu-link'>
                            <div className={'menu-sub-item settings' + (adminLocation.includes('settings') ? ' active' : '')}>
                                <span>Paramètres</span>
                            </div>
                        </Link>
                    </div>
                    <div className='menu-divider' />
                    <div className='menu-opening-container'>
                        <div className='menu-opening-title'>
                            <span>Groupes ({groups.length})</span>
                            <div className='flex-view'>
                                <Tooltip placement='top' title="Modifier l'ordre des groupes">
                                    <div className='sort-entity' onClick={() => setGroupsSort(!groupsSort)} />
                                </Tooltip>
                                <Tooltip placement='top' title="Ajouter un groupe">
                                    <Link to='/admin/groups/new'>
                                        <span className='add-entity'>+</span>
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className='menu-groups-container'>
                        { groups.length > 0 ? groupsSort ? (
                            <SortingContainer onCardDrop={changeGroupPosition} cards={
                                groups.map(group => ({...group, content: (
                                        <div className='menu-opening-item sorting'>
                                            <span>{group.name}</span>
                                            { group.subscriptions.length > 0 ? (
                                                <small className='menu-sub-item-total error'>{group.subscriptions.length}</small>
                                            ) : null }
                                        </div>
                                    )})
                                )
                            } />
                        ) : groups.map((group, index) => (
                            <Link to={'groups/' + group.id} key={index}>
                                <div className={'menu-opening-item' + (adminLocation.includes(group.id) ? ' active' : '')}>
                                    <div>
                                        <GroupIcon color={group.color} />
                                        <span>{group.name}</span>
                                    </div>
                                    { group.subscriptionsCount > 0 ? (
                                        <small className='menu-sub-item-total error'>{group.subscriptionsCount}</small>
                                    ) : null }
                                </div>
                            </Link>
                        )) : (
                            <Link to='groups/new'>
                                <span className='menu-add-first'>Ajoutez votre premier groupe !</span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className='main-app-right-part'>
                <div className='main-navbar-container'>
                    <div className='main-navbar'>
                        <div className='searchbar-container'>
                            <AsyncSelect
                                name="colors"
                                className="basic-single single-search-input main-search-bar"
                                classNamePrefix="select"
                                placeholder="Rechercher..."
                                value={null}
                                loadOptions={handleSearch}
                                components={{
                                    Option: SearchBarOption,
                                    NoOptionsMessage: NoOptionMessage,
                                    LoadingMessage: LoadingMessage
                                }}
                            />
                        </div>
                        <div className='season-chooser-container'>
                            <CustomSearchInput
                                placeholder='Saison active'
                                labelType='season'
                                isSearchable={false}
                                value={user.club.seasons.find(season => season.id === user.activeSeason)}
                                onChange={changeActiveSeason}
                                entity={user.club.seasons}
                            />
                        </div>
                        <div className='user-info-container'>
                            <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                <React.Fragment>
                                    <div className='tooltip-container'>
                                        <Link to='/groups' className='view' target='_blank'>Voir la page du club</Link>
                                        <Link to={'/admin/users/' + user.id} className='user'>Mon profil</Link>
                                        <span className='logout' onClick={handleDisconnect}>Déconnexion</span>
                                    </div>
                                </React.Fragment>
                            }>
                                <div className='user-info-block'>
                                    <div className='user-img-container'>
                                        <img alt='profile-picture' src={defaultProfilePicture} />
                                    </div>
                                    <div className='user-data-container'>
                                        <span className='user-title'>{user.firstName + ' ' + user.lastName}</span>
                                        <span className='user-club'>{'@' + user.club.slug}</span>
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                { !isLoading ? (
                    <div className='main-router-content'>
                        <Routes>
                            <Route path='groups/*' element={<Groups groups={groups} appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                            <Route path='customers/*' element={<Customers />} />
                            <Route path='users/*' element={<Users appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                            <Route path='profiles/*' element={<Profiles appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                            <Route path='trainings/*' element={<Trainings groups={groups} />} />
                            <Route path='subscriptions/*' element={<Subscriptions appRefresh={appRefresh} setAppRefresh={setAppRefresh} groups={groups} />} />
                            <Route path='pools/*' element={<PoolSettings />} />
                            <Route path='categories/*' element={ <CategorySettings groups={groups} />} />
                            <Route path='settings' element={<Settings groups={groups} refresh={appRefresh} setRefresh={setAppRefresh} />} />
                            <Route path='planning' element={<PlanningView groups={groups} />} />
                            <Route path='*' element={<Navigate to='/admin/planning' />} />
                        </Routes>
                    </div>
                ) : <div className='loader-container'><CircularProgress size={30} /></div>
                }
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default AdminPanel;