import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CircularProgress, IconButton, Tab, Tabs, Tooltip} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import CustomWarningModal from "../../custom/CustomWarningModal";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import LicenceSign from "../../public/licence/LicenceSign";
import file_logo from "../../../assets/icons/pencil--blue.svg";
import SubscriptionLicence from "../subscriptions/SubscriptionLicence";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const UserView = ({ appRefresh, setAppRefresh }) => {

    const { userId } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = React.useState();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [modalWarningOpen, setModalWarningOpen] = React.useState(false);
    const [modalButtonLoading, setModalButtonLoading] = React.useState(false);
    const [licenceSignOpen, setLicenceSignOpen] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0);
    const [newLicenceSign, setNewLicenceSign] = React.useState(false);
    const { user: loggedUser, token } = useContext(AuthContext);
    const location = useLocation();

    React.useEffect(() => setTab(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0), [location]);

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'users/' + userId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                setUser(response.data);
                setIsLoaded(true);
            })
            .catch((error) => console.log(error));
    }, [token, userId]);

    const handleDelete = () => {
        setIsLoaded(false);
        setModalButtonLoading(true);
        setModalWarningOpen(false);
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'users/' + userId, {headers: {Authorization: 'Bearer ' + token}})
            .then(() => {
                setModalButtonLoading(false);
                setIsLoaded(true);
                navigate(-1);
                toast.success("Le profil a bien été supprimé", toastOptions);
            })
            .catch((error) => {
                setModalButtonLoading(false);
                console.log(error.response.data);
                toast.error("Une erreur est survenue", toastOptions);
                setIsLoaded(true);
            });
    }

    const isProfileComplete = (profile) => {
        return profile.civility &&
            profile.nationality &&
            profile.firstName &&
            profile.lastName &&
            profile.phone &&
            profile.birthDate &&
            profile.address &&
            profile.postalCode &&
            profile.city;
    }

    return isLoaded ? (
        <div className='user-view'>
            <div className='view-header user'>
                <div className='view-title'>
                    <h1>{`${user.firstName} ${user.lastName}`}</h1>
                    <Tooltip leaveDelay={800} placement="bottom" id='options-tooltip' title={
                        <React.Fragment>
                            <div className='tooltip-container'>
                                <Link to={'/admin/users/' + userId + '/edit'} className='edit'>Modifier</Link>
                                { loggedUser.id !== userId && (
                                    <span className='delete' onClick={() => setModalWarningOpen(true)}>Supprimer</span>
                                )}
                            </div>
                        </React.Fragment>
                    }>
                        <IconButton aria-label="delete" size="small">
                            <MoreVertRoundedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                        <ArrowBackRoundedIcon fontSize="small"/>
                    </IconButton>
                </div>
            </div>
            <div>
                <Tabs value={tab} onChange={(e, newValue) => {
                    setTab(newValue);
                    setSearchParams({tab: newValue})
                }}>
                    <Tab value={0} label="Informations"/>
                    <Tab value={1} label="Licence"/>
                </Tabs>
            </div>
            <div role="tabpanel" hidden={tab !== 0}>
                <div className="view-container user scrollable">
                    <div className='subscription-line status'>
                        <span className='subscription-line-title'>Statut </span>
                        <CustomTag value={user.status}/>
                    </div>
                    <div className='subscription-line status'>
                        <span className='subscription-line-title'>Rôle </span>
                        <CustomTag value={user.role}/>
                    </div>
                    <div className='subscription-line id'>
                        <span className='subscription-line-title'>Identifiant fédéral </span>
                        <CustomTag value={user.federalId}/>
                    </div>
                    <div className='subscription-line user'>
                        <span className='subscription-line-title'>Profil </span>
                    </div>
                    <div className='subscription-profile-recap'>
                        <div className='subscription-profile-recap-lines'>
                                <span
                                    className='birth'>{user.birthDate ? `${dayjs(user.birthDate).format('DD MMMM YYYY')} (${dayjs().diff(user.birthDate, 'year')} ans)` : 'Non renseigné'}</span>
                            <span
                                className='address'>{user.address && user.address && user.city ? `${user.address} ${user.postalCode} ${user.city}` : 'Non renseigné'}</span>
                            <a className='email' href={`mailto:${user.email}`}>{user.email}</a>
                            <a className='phone'
                               href={user.phone ? `tel:${user.phone}` : null}>{user.phone ? user.phone : 'Non renseigné '}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <>
                    { user.licences.find(licence => licence.seasonId === user.activeSeason) && !newLicenceSign ? (
                        <SubscriptionLicence
                            subscription={user.licences.find(licence => licence.seasonId === user.activeSeason)}
                            tab={tab}
                            user={user}
                            setNewLicenceSign={setNewLicenceSign}
                        />
                    ) : (
                        <div className='view-container'>
                            { user.id === loggedUser.id ? (
                                <>
                                    <div className='view-subtitle sign'>
                                        <h2>Signez votre licence</h2>
                                        <IconButton aria-label="delete" size="small" onClick={() => setNewLicenceSign(false)}>
                                            <ClearRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    { isProfileComplete(user) ? (
                                        <>
                                            <span className='licence-sign-note margin-bottom-15'>Vous pouvez signer votre licence en ligne !</span>
                                            <div className='custom-file-container' onClick={() => setLicenceSignOpen(true)}>
                                                <label className='custom-file-label'>
                                                    <img src={file_logo} width={18} alt='file'/>
                                                    <span>Signer ma licence en ligne</span>
                                                </label>
                                            </div>
                                            <LicenceSign
                                                isUser
                                                open={licenceSignOpen}
                                                setOpen={setLicenceSignOpen}
                                                customer={{
                                                    email: user.email,
                                                    role: user.role,
                                                    profiles: [{
                                                        ...user,
                                                        subscriptions: user.licences.filter(licence => licence.seasonId === user.activeSeason)
                                                    }]
                                                }}
                                                profileId={user.id}
                                                appRefresh={appRefresh}
                                                setAppRefresh={setAppRefresh}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <span className='licence-sign-note warning margin-bottom-15'>Votre profil n'est pas encore complet</span>
                                            <span className='licence-sign-note margin-bottom-15'>Merci de compléter votre profil avant de signer votre licence.</span>
                                            <Link to={'/admin/users/' + userId + '/edit'} className='edit'>
                                                <button className='main-button blue-button edit'>Modifier mon profil</button>
                                            </Link>
                                        </>
                                    )}
                                </>
                            ) : (
                                <CustomTag value="Cet utilisateur n'a pas rempli sa licence pour cette saison."/>
                            )}
                        </div>
                    )}
                </>
            </div>
            <CustomWarningModal
                content={`Souhaitez-vous vraiment supprimer cet utilisateur ?`}
                modalOpen={modalWarningOpen}
                setModalOpen={setModalWarningOpen}
                buttonLoading={modalButtonLoading}
                confirm={handleDelete}
            />
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default UserView;