import CustomTag from "../../custom/CustomTag";
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";

const TrainingName = ({ group, training, isPublic = false }) => {

    return (
        <div className='accordion-training-container'>
            <div className='accordion-training-hours'>
                <CustomTag value={constants.all.find(constant => constant.value === training.weekDay).label} />
                de
                <CustomTag value={dayjs(dayjs().format('YYYY-MM-DD ' + training.startHour)).format('HH:mm')} />
                à
                <CustomTag value={dayjs(dayjs().format('YYYY-MM-DD ' + training.stopHour)).format('HH:mm')} />
            </div>
            <CustomTag value={training.pool.name} className='pool-name' />
            { group && !isPublic && group.trainingsType === 'optional' ? (
                <CustomTag value={`${group.subscriptions.filter(sub => sub.status === "accepted" && sub.trainings.some(t => t.trainingId === training.id)).length} / ${training.places}`} className='active' />
            ) : null }
        </div>
    );
}

export default TrainingName;