import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";

const GroupList = () => {

    
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshData, setRefreshData] = useState(0);
    const { token } = useContext(AuthContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                icon: 'pen'
            },
            {
                Header: 'Coach(s)',
                accessor: 'coaches',
                icon: 'user',
                Cell: ({ cell: { value } }) => (
                    <div className='multiple-tag-list'>
                        { value.map((coach, index) => (
                            <CustomTag key={index} value={coach.firstName + ' ' + coach.lastName} />
                        ))}
                    </div>
                )
            }
        ],
        []
    );

    const handleBulkArchive = () => {
        toast.promise(
            axios.put(process.env.REACT_APP_ADMIN_API_URL + 'groups/bulk-archive', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }}),
            {
                pending: 'Archivage en cours',
                success: 'Projets archivés',
                error: 'Une erreur est survenue'
            }, {
                position: "bottom-right",
                autoClose: 5000,
            }
        ).then(() => {
            setRefreshData(refreshData + 1);
        });
    }

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs fournisseurs")) {
            toast.promise(
                axios.post(process.env.REACT_APP_ADMIN_API_URL + 'groups/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Projets supprimés',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setRefreshData(refreshData + 1);
            });
        }
    }

    return (
        <div>
            <div className='list-header'>
                <div className='header-title users'>
                    <h1>Groupes</h1>
                </div>
                <div className='header-buttons'>
                    { selectedColumns === 0 ?
                        <div>
                            <Link to='new'>
                                <button className='main-button blue-button plus'>Ajouter un groupe</button>
                            </Link>
                            <button className='main-button download'>Télécharger</button>
                        </div>
                        :
                        <div>
                            <button className='main-button archive' onClick={handleBulkArchive}>Archiver</button>
                            <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                        </div>
                    }
                </div>
            </div>
            <CustomList entity='groups' columns={columns} setSelectedColumns={setSelectedColumns} setSelectedIds={setSelectedIds} />
        </div>
    );
}

export default GroupList;
