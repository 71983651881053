import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export const SortedCard = ({ id, index, moveCard, onDrop, children }) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return { handlerId: monitor.getHandlerId() };
        },
        hover(item, monitor) {

            if (!ref.current) { return; }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) { return; }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) { return; }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) { return; }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className='sorted-card' onDrop={onDrop}>
            {children}
        </div>
    );
};
