import React, {useContext} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import CustomWarningModal from "../../custom/CustomWarningModal";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import Switch from "@mui/material/Switch";

const PlanningSettings = () => {

    const [groups, setGroups] = React.useState();
    const [modalWarningOpen, setModalWarningOpen] = React.useState(false);
    const [modalButtonLoading, setModalButtonLoading] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const [action, setAction] = React.useState({
        group: null,
        type: null
    });
    const { token, user, setUser } = useContext(AuthContext);

    React.useEffect(() => {
        setGroups(null);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'planning/groups', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setGroups(response.data);
                }
            })
            .catch((error) => console.log(error));
    }, [token, refresh]);

    React.useEffect(() => {
        if (action.group && action.type) {
            switch (action.type) {
                case 'generate-all':
                    setModalContent(`Toutes les séances existantes de tous les groupes seront remplacées. Êtes-vous sûr de vouloir continuer ?`);
                    break;
                case 'generate':
                    setModalContent(`Toutes les séances existantes pour le groupe "${action.group.name}" seront remplacées. Êtes-vous sûr de vouloir continuer ?`);
                    break;
                case 'delete':
                    setModalContent(`Toutes les séances existantes pour le groupe "${action.group.name}" seront supprimées. Êtes-vous sûr de vouloir continuer ?`);
                    break;
            }
            setModalWarningOpen(true);
        } else {
            setModalWarningOpen(false);
        }
    }, [action]);

    const handleGenerateFullPlanning = () => {
        axios.get(process.env.REACT_APP_ADMIN_API_URL + `planning/generate`, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                setRefresh(refresh + 1);
                toast.success("Tout le planning a bien été généré", toastOptions);
            })
            .catch((error) => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
            });
    }

    const handleGeneratePlanning = groupId => {
        axios.get(process.env.REACT_APP_ADMIN_API_URL + `planning/groups/${groupId}/generate`, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                setRefresh(refresh + 1);
                toast.success("Le planning a bien été généré pour ce groupe", toastOptions);
            })
            .catch((error) => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
            });
    }

    const handleDeletePlanning = groupId => {
        axios.get(process.env.REACT_APP_ADMIN_API_URL + `planning/groups/${groupId}/delete`, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                setRefresh(refresh + 1);
                toast.success("Le planning a bien été supprimé pour ce groupe", toastOptions);
            })
            .catch((error) => {
                setModalButtonLoading(false);
                setModalWarningOpen(false);
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
            });
    }

    const handleConfirmAction = () => {
        setModalButtonLoading(true);
        switch (action.type) {
            case 'generate-all':
                handleGenerateFullPlanning();
                break;
            case 'generate':
                handleGeneratePlanning(action.group.id);
                break;
            case 'delete':
                handleDeletePlanning(action.group.id);
                break;
        }
    }

    const handleShowPlanning = (event) => {
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'seasons/' + user.activeSeason , { showPlanning: event.target.checked }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setUser({...user, season: {...user.season, showPlanning: !event.target.checked }});
                toast.success("Le planning est maintenant " + (!event.target.checked ? 'public' : 'privé'), toastOptions);
            })
            .catch(() => {
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    return groups ? (
        <div>
            <div className='view-subtitle calendar'>
                <h2>Configuration du planning</h2>
                <button className='main-button calendar'
                        onClick={() => setAction({type: 'generate-all', group: {}})}>Générer tout le planning
                </button>
            </div>
            <div className='flex-start margin-bottom-15'>
                <span className='small-note margin-right'>Afficher le planning aux adhérents</span>
                <Switch color="primary" checked={user.season.showPlanning} onChange={handleShowPlanning}/>
            </div>
            <div className='view-subtitle calendar'>
                <h2>Détail des séances</h2>
            </div>
            { groups.length > 0 ? (
                <>
                    <div className='entity-add-container'>
                        {groups.map((group, index) => (
                            <div className='training-edit-block' key={index}>
                                <div className='entity-with-description'>
                                    <div className='flex-view'>
                                        <div className='view-subtitle calendar margin-right'>
                                            <h2>{group.name}</h2>
                                        </div>
                                        <CustomTag className='accepted'
                                                   value={`${group.trainings.reduce((sum, t) => sum + t.events.length, 0)} séances`}/>
                                    </div>
                                </div>
                                <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                    <React.Fragment>
                                        <div className='tooltip-container'>
                                            <span className='calendar'
                                                  onClick={() => setAction({type: 'generate', group: group})}>Générer les séances</span>
                                            <span className='delete'
                                                  onClick={() => setAction({type: 'delete', group: group})}>Supprimer les séances</span>
                                        </div>
                                    </React.Fragment>
                                }>
                                    <IconButton aria-label="delete" size="small">
                                        <MoreVertRoundedIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                    <CustomWarningModal
                        content={modalContent}
                        modalOpen={modalWarningOpen}
                        setModalOpen={setModalWarningOpen}
                        buttonLoading={modalButtonLoading}
                        confirm={handleConfirmAction}
                    />
                </>
            ) : (
                <CustomTag value="Ajoutez un groupe pour gérer son planning !"/>
            )}
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default PlanningSettings;